<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header fw-bold">{{ $t("views.objective.keyresult.heading") }}</div>
        <div class="card-body">
          <div class="bg-white">
            <div class="bg-primary text-light px-3">
              <div class="row">
                <div class="col-3 ps-1 pe-3 py-2">
                  {{ $t("views.objective.keyresult.keyResult") }}
                </div>
                <div class="col-1 col-unit px-1 py-2">
                  {{ $t("views.objective.keyresult.unit") }}
                </div>
                <div class="col-1 text-end px-1 py-2">
                  {{ $t("views.objective.keyresult.jop") }}
                </div>
                <div class="col-1 text-end ps-1 pe-3 py-2">
                  {{ $t("views.objective.keyresult.objective") }}
                </div>
                <div class="col-1 ps-3 pe-1 py-2 border-2 border-start">
                  {{ $t("views.objective.keyresult.date") }}
                </div>
                <div class="col-1 text-end px-1 py-2">
                  {{ $t("views.objective.keyresult.actual") }}
                </div>
                <div class="col-1 text-end ps-1 pe-3 py-2">
                  %
                </div>
                <div class="col-1 text-end px-1 py-2">
                  {{ $t("views.objective.keyresult.forecast") }}
                </div>
                <div class="col-1 text-end ps-1 pe-3 py-2">
                  %
                </div>
              </div>
            </div>
            <div v-for="keyresult in keyresults" :key="keyresult.id">
              <div v-if="!keyresult.new" class="row px-3 border-bottom">
                <div class="col-3 my-auto ps-1 pe-2">
                  {{ keyresult.name }}
                </div>
                <div class="col-1 col-unit my-auto px-1">
                  {{ keyresult.unit }}
                </div>
                <div class="col-1 text-end my-auto px-1">
                  {{ keyresult.jop?.toLocaleString('de-DE') }}
                </div>
                <div class="col-1 text-end my-auto ps-1 pe-3">
                  {{ keyresult.obj?.toLocaleString('de-DE') }}
                </div>
                <div class="col-1 border-2 border-start my-auto pe-1 ps-3 py-3">
                  <select v-if="keyresult.records?.length" v-model="keyresult.selectedRecord" class="select-th ps-1">
                    <option v-for="record, index in keyresult.records" :key="record.id" :value="index">{{ new Date(record.date).toLocaleDateString({dateStyle: 'short'}) }}</option>
                  </select>
                  <a v-else @click="openModal(keyresult.id, false, 'record', 'add')" href="#objectiveEditModal" class="small" data-bs-toggle="modal">{{ $t("views.objective.keyresult.linkRecord") }}</a>
                </div>
                <div class="col-1 text-end my-auto px-1">
                  {{ keyresult.records[keyresult.selectedRecord]?.actual.toLocaleString('de-DE') }}
                </div>
                <div class="col-1 text-end my-auto ps-1 pe-3">
                  <div v-if="keyresult.records[keyresult.selectedRecord]" class="progress position-relative border bg-dark border-dark w-75 float-end">
                    <span class="progress-label position-absolute start-50 top-50 text-light fw-bold">{{ keyresult.records[keyresult.selectedRecord].actualConversion }}%</span>
                    <div class="progress-bar progress-bar-striped" :class="getRecordStatus(keyresult.records[keyresult.selectedRecord].forecastConversion)" :style="'width: ' + keyresult.records[keyresult.selectedRecord].actualConversion + '%'" role="progressbar" :aria-valuenow="keyresult.records[keyresult.selectedRecord].actualConversion" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                <div class="col-1 text-end my-auto px-1">
                  {{ keyresult.records[keyresult.selectedRecord]?.forecast.toLocaleString('de-DE') }}
                </div>
                <div class="col-1 text-end my-auto ps-1 pe-3">
                  <div v-if="keyresult.records[keyresult.selectedRecord]" class="progress position-relative border bg-dark border-dark w-75 float-end">
                    <span class="progress-label position-absolute start-50 top-50 text-light fw-bold">{{ keyresult.records[keyresult.selectedRecord].forecastConversion }}%</span>
                    <div class="progress-bar progress-bar-striped" :class="getRecordStatus(keyresult.records[keyresult.selectedRecord].forecastConversion)" :style="'width: ' + keyresult.records[keyresult.selectedRecord].forecastConversion + '%'" role="progressbar" :aria-valuenow="keyresult.records[keyresult.selectedRecord].forecastConversion" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                <div class="col-1 col-context text-end my-auto px-1">
                  <div class="dropdown">
                    <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                      <svg class="bi" width="28" height="28" fill="currentColor">
                        <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                      </svg>
                    </a>
                    <ul class="dropdown-menu">
                      <li><a @click="openModal(keyresult.id, false, 'record', 'add')" class="dropdown-item" href="#objectiveEditModal" data-bs-toggle="modal">{{ $t("views.objective.keyresult.selectRecordNew") }}</a></li>
                      <li v-if="keyresult.records.length"><a @click="openModal(keyresult.id, keyresult.records[keyresult.selectedRecord]?.id, 'record', 'update')" class="dropdown-item" href="#objectiveEditModal" data-bs-toggle="modal">{{ $t("views.objective.keyresult.selectRecordEdit") }}</a></li>
                      <li v-if="keyresult.records.length"><a @click="openModal(keyresult.id, keyresult.records[keyresult.selectedRecord]?.id, 'record', 'delete')" class="dropdown-item" href="#objectiveDeleteModal" data-bs-toggle="modal">{{ $t("views.objective.keyresult.selectRecordDelete") }}</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row px-3 border-bottom">
              <div class="col-3 col-total fw-bold my-auto ps-1 pe-2 py-3">
                {{ $t("views.objective.keyresult.total") }}
              </div>
              <div class="col-1 offset-5 text-end my-auto ps-1 pe-3">
                <div class="progress position-relative border bg-dark border-dark w-75 float-end">
                  <span class="progress-label position-absolute start-50 top-50 text-light fw-bold">{{ selectedObjective.keyresultsTotal.actualConversion }}%</span>
                  <div class="progress-bar progress-bar-striped" :class="getRecordStatus(selectedObjective.keyresultsTotal.forecastConversion)" :style="'width: ' + selectedObjective.keyresultsTotal.actualConversion + '%'" role="progressbar" :aria-valuenow="selectedObjective.keyresultsTotal.actualConversion" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div class="col-1 offset-1 text-end my-auto ps-1 pe-3">
                <div class="progress position-relative border bg-dark border-dark w-75 float-end">
                  <span class="progress-label position-absolute start-50 top-50 text-light fw-bold">{{ selectedObjective.keyresultsTotal.forecastConversion }}%</span>
                  <div class="progress-bar progress-bar-striped" :class="getRecordStatus(selectedObjective.keyresultsTotal.forecastConversion)" :style="'width: ' + selectedObjective.keyresultsTotal.forecastConversion + '%'" role="progressbar" :aria-valuenow="selectedObjective.keyresultsTotal.forecastConversion" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KeyResultsTable',
  props: ['keyresults'],
  computed: {
    selectedObjective () {
      return this.$store.state.selectedObjective
    }
  },
  methods: {
    openModal (keyResultId, recordId, elementType, mode) {
      this.$parent.objectiveModalKeyResultId = keyResultId
      if (recordId) {
        this.$parent.objectiveModalElementId = recordId
      }
      this.$parent.objectiveModalElementType = elementType
      this.$parent.objectiveModalMode = mode
    },
    getRecordStatus (conversion) {
      return conversion >= 100 ? 'bg-success' : conversion >= 95 ? 'bg-warning' : 'bg-danger'
    }
  }
}
</script>

<style scoped>
.card-body .bg-dark {
  --bs-bg-opacity: 0.8;
}
.col-unit {
  width: 12.33333333%;
}
.col-context {
  width: 4.33333333%;
}
.col-total {
  width: 29%;
}
.select-th {
  background-color: transparent;
  border: 1px solid;
  border-radius: 0.3rem;
}
.progress-label {
  transform: translate(-50%, -50%);
}
</style>
