import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { languages } from './i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/main.scss'
import 'bootstrap'
import * as microsoftTeams from '@microsoft/teams-js'

store.dispatch('initialiseApp')

const messages = Object.assign(languages)
const userLocale = store.state.loginStatus && store.state.user.settings.language ? store.state.user.settings.language : (navigator.language || navigator.userLanguage)
const i18n = createI18n({
  locale: userLocale,
  fallbackLocale: 'de-DE',
  globalInjection: true,
  messages
})

createApp(App).use(store).use(router).use(i18n).mount('#app')

if (window.location.href.includes('context=msteams')) {
  microsoftTeams.app.initialize()
  microsoftTeams.app.getContext((context) => {
    const userId = document.getElementById('user')
    userId.innerHTML = context.userPrincipalName
  })
}

export default i18n
