<template>
  <Bar
    :id="chartId"
    :options="chartOptions"
    :data="chartData"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Tooltip, BarElement, LineElement, PointElement, LineController, CategoryScale, LinearScale, Legend } from 'chart.js'

ChartJS.register(BarElement, Tooltip, PointElement, CategoryScale, LinearScale, Legend)
ChartJS.register(LineController, LineElement)

export default {
  name: 'KPIPeriodMultiChart',
  components: { Bar },
  props: ['chartId', 'periods'],
  data () {
    return {
      chartData: {
        labels: this.periods.map(period => period.period),
        datasets: [
          {
            label: 'Ist',
            data: this.periods.map(period => period.actual),
            backgroundColor: '#6c80bb',
            barPercentage: 0.6,
            maxBarThickness: 50,
            order: 2
          },
          {
            label: 'Plan',
            data: this.periods.map(period => period.plan),
            backgroundColor: '#bfbfbf',
            barPercentage: 0.6,
            maxBarThickness: 50,
            order: 4
          },
          {
            label: 'Ziel',
            data: this.periods.map(period => period.objective),
            backgroundColor: '#dc3545',
            barPercentage: 0.6,
            maxBarThickness: 50,
            order: 0,
            pointRadius: 6,
            type: 'line'
          },
          {
            label: 'Forecast',
            data: this.periods.map(period => period.forecast),
            backgroundColor: '#6c80bb73',
            barPercentage: 0.6,
            maxBarThickness: 50,
            order: 3
          },
          {
            label: 'Benchmark',
            data: this.periods.map(period => period.benchmark),
            backgroundColor: '#198754',
            barPercentage: 0.6,
            maxBarThickness: 50,
            order: 1,
            pointRadius: 6,
            type: 'line'
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        animation: false
      }
    }
  }
}
</script>
