<template>
  <span class="ps-1">
    <a :href="createLink()" target="_blank">
      <svg class="bi" :class="'text-' + color" width="18" height="18" fill="currentColor">
        <use xlink:href="@/assets/icons/icons.svg#info-circle"/>
      </svg>
    </a>
  </span>
</template>

<script>
export default {
  name: 'DocsInfoLink',
  props: ['color', 'target'],
  data () {
    return {
      targets: {
        createObjective: {
          de: 'docs/objectives#ziel-erstellen',
          en: 'en/docs/objectives#create-objective'
        },
        viewObjective: {
          de: 'docs/objectives#zielübersicht',
          en: 'en/docs/objectives#objectives-overview'
        },
        createKPI: {
          de: 'docs/monitoring#kpi-erstellen',
          en: 'en/docs/monitoring#create-kpi'
        },
        manageKPIs: {
          de: 'docs/monitoring#kpis-verwalten',
          en: 'en/docs/monitoring#manage-kpis'
        },
        manageKPIRecordActual: {
          de: 'docs/monitoring#ist-und-forecastwerte-erfassen',
          en: 'en/docs/monitoring#entering-actual-and-forecast-values'
        },
        manageKPIRecordPlan: {
          de: 'docs/monitoring#planwerte-erfassen',
          en: 'en/docs/monitoring#entering-planned-values'
        },
        manageKPIRecordObjective: {
          de: 'docs/objectives#zielwerte-erfassen',
          en: 'en/docs/objectives#entering-objective-values'
        },
        manageKPIUnits: {
          de: 'docs/monitoring#kpi-einheiten-verwalten',
          en: 'en/docs/monitoring#manage-kpi-units'
        },
        kpiDeviation: {
          de: 'docs/monitoring#abweichungen-analysieren',
          en: 'en/docs/monitoring#analyze-deviations'
        },
        kpiChange: {
          de: 'docs/monitoring#veränderungen-analysieren',
          en: 'en/docs/monitoring#analyze-changes'
        },
        createProject: {
          de: 'docs/projects#projekt-erstellen',
          en: 'en/docs/projects#create-project'
        },
        manageProjects: {
          de: 'docs/projects',
          en: 'en/docs/projects'
        },
        createOrg: {
          de: 'docs/organization#org-einheit-erstellen',
          en: 'en/docs/organization#create-org-unit'
        },
        viewOrg: {
          de: 'docs/organization#organisationsübersicht',
          en: 'en/docs/organization#organization-overview'
        },
        manageEmployees: {
          de: 'docs/organization#mitarbeiter-verwalten',
          en: 'en/docs/organization#manage-employees'
        },
        managePermissions: {
          de: 'docs/settings#berechtigungen-verwalten',
          en: 'en/docs/settings#manage-authorisations'
        }
      }
    }
  },
  computed: {
    isDE () {
      return this.$store.state.user.settings.language?.includes('de')
    }
  },
  methods: {
    createLink () {
      const baseUrl = 'https://focusmt.de/'
      return this.isDE ? baseUrl + this.targets[this.target].de : baseUrl + this.targets[this.target].en
    }
  }
}
</script>

<style>
svg {
  margin-top: -2px;
}
</style>
