<template>
  <div class="container-fluid">
    <button v-if="objectivesSidebarView === 'hide'" @click="toggleObjectivesSidebarView('show')" class="btn btn-sm btn-primary rounded-0 rounded-end position-absolute start-0 mt-3">
      <svg class="bi" width="21" height="21" fill="currentColor">
        <use xlink:href="@/assets/icons/icons.svg#caret-right-fill"/>
      </svg>
    </button>
    <div class="row" :class="objectivesSidebarView === 'show' ? '' : 'justify-content-center'">
      <div v-if="objectivesSidebarView === 'show'" class="col-3 sidebar-objectives bg-custom-blue pb-5">
        <div class="row mt-3">
          <div class="col-12">
            <button v-if="objectivesContentView === 'detail'" @click="toggleObjectivesContentView('add')" class="btn btn-sm btn-primary">+ {{ $t("general.add") }}</button>
            <button v-if="objectivesContentView === 'add' && objectivesSidebarView === 'show'" @click="toggleObjectivesSidebarView('hide')" class="btn btn-sm btn-outline-primary">
              &lt; {{ $t("general.hide") }}
            </button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-5">
            <div class="dropdown">
              <button class="btn btn-sm btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span>{{ new Date(filterValidFrom).toLocaleDateString({dateStyle: 'short'}) }} &nbsp;-&nbsp; {{ new Date(filterValidTo).toLocaleDateString({dateStyle: 'short'}) }}</span>
              </button>
              <div class="dropdown-menu border-secondary p-3">
                <div class="row">
                  <div class="col-12">
                    <button @click="filterObjectives(new Date().toISOString().slice(0, 10), new Date().toISOString().slice(0, 10))" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ $t("general.today") }}</button>
                    <button @click="filterObjectives((new Date().getFullYear() + 1).toString() + '-01-01', (new Date().getFullYear() + 1).toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ new Date().getFullYear() + 1 }}</button>
                    <button @click="filterObjectives(new Date().getFullYear().toString() + '-01-01', new Date().getFullYear().toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ new Date().getFullYear() }}</button>
                    <button @click="filterObjectives((new Date().getFullYear() - 1).toString() + '-01-01', (new Date().getFullYear() - 1).toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ new Date().getFullYear() - 1 }}</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button @click="filterObjectives(new Date().getFullYear().toString() + '-01-01', new Date().getFullYear().toString() + '-03-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">Q1</button>
                    <button @click="filterObjectives(new Date().getFullYear().toString() + '-04-01', new Date().getFullYear().toString() + '-06-30')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">Q2</button>
                    <button @click="filterObjectives(new Date().getFullYear().toString() + '-07-01', new Date().getFullYear().toString() + '-09-30')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">Q3</button>
                    <button @click="filterObjectives(new Date().getFullYear().toString() + '-10-01', new Date().getFullYear().toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">Q4</button>
                  </div>
                </div>
                <hr class="dropdown-divider">
                <div class="row">
                  <div class="col-6">
                    <label for="filterValidFrom" class="form-label">{{ $t("views.kpi.periods.from") }}:</label>
                    <input v-model="filterValidFrom" type="date" class="form-control form-control-sm" id="filterValidFrom">
                  </div>
                  <div class="col-6">
                    <label for="filterValidTo" class="form-label">{{ $t("views.kpi.periods.to") }}:</label>
                    <input v-model="filterValidTo" type="date" class="form-control form-control-sm" id="filterValidTo">
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button @click="filterObjectives(filterValidFrom, filterValidTo)" class="btn btn-sm btn-outline-primary mt-3" type="button">{{ $t("views.kpi.periods.filter") }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-7 text-end">
            <span class="me-2">{{ $t("views.objective.detail.objectiveView") }}:</span>
            <div class="btn-group btn-group-objectives-view" role="group">
              <button @click="toggleObjectivesTreeView('org')" type="button" class="btn btn-sm btn-outline-primary" :class="objectivesTreeView === 'org' ? 'active' : ''">{{ $t("views.objective.detail.org") }}</button>
              <button @click="toggleObjectivesTreeView('objective')" type="button" class="btn btn-sm btn-outline-primary" :class="objectivesTreeView === 'objective' ? 'active' : ''">{{ $t("views.objective.detail.objective") }}</button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ObjectiveTree v-if="'nodes' in objectives && objectives.nodes.length" :node="objectives" :depth="0" :view="objectivesTreeView" class="mt-3"></ObjectiveTree>
          </div>
        </div>
      </div>
      <div class="col-9 px-5">
        <ObjectiveAdd v-if="objectivesContentView === 'add'"></ObjectiveAdd>
        <ObjectiveDetail v-else-if=" objectivesContentView === 'detail'"></ObjectiveDetail>
        <IntroObjective></IntroObjective>
      </div>
    </div>
  </div>
</template>

<script>
import ObjectiveTree from '../../components/objectives/ObjectiveTree.vue'
import ObjectiveDetail from '../../components/objectives/ObjectiveDetail.vue'
import ObjectiveAdd from '../../components/objectives/ObjectiveAdd.vue'
import IntroObjective from '../../components/guide/IntroObjective.vue'

export default {
  name: 'ObjectivesManageObjective',
  data () {
    return {
      objectivesTreeView: 'org',
      objectivesSidebarView: 'show',
      filterValidFrom: this.$store.state.user?.settings?.favorites?.objectiveManage?.filterValidFrom ? this.$store.state.user.settings.favorites.objectiveManage.filterValidFrom : new Date().getFullYear().toString() + '-01-01',
      filterValidTo: this.$store.state.user?.settings?.favorites?.objectiveManage?.filterValidTo ? this.$store.state.user.settings.favorites.objectiveManage.filterValidTo : (new Date().getFullYear() + 1).toString() + '-12-31'
    }
  },
  computed: {
    objectives () {
      return this.$store.state.objectives
    },
    objectivesContentView () {
      return this.$store.state.objectivesContentView
    },
    selectedObjective () {
      return this.$store.state.selectedObjective
    }
  },
  components: {
    ObjectiveTree,
    ObjectiveDetail,
    ObjectiveAdd,
    IntroObjective
  },
  methods: {
    async filterObjectives (filterFrom, filterTo) {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.filterValidFrom = filterFrom
      this.filterValidTo = filterTo
      await this.$store.dispatch('getObjectives', { validFrom: filterFrom, validTo: filterTo, treeView: this.objectivesTreeView })
      await this.$store.dispatch('favoriteObject', { isList: false, objectKey: 'objectiveManage', objectValue: { filterValidFrom: filterFrom, filterValidTo: filterTo } })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    toggleObjectivesSidebarView (objectivesSidebarView) {
      this.objectivesSidebarView = objectivesSidebarView
    },
    toggleObjectivesContentView (objectivesContentView) {
      this.$store.dispatch('updateObjectivesContentView', objectivesContentView)
    },
    async toggleObjectivesTreeView (objectivesTreeView) {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.objectivesTreeView = objectivesTreeView
      await this.$store.dispatch('getObjectives', { validFrom: this.filterValidFrom, validTo: this.filterValidTo, treeView: this.objectivesTreeView })
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  async created () {
    this.$store.commit('SET_LOADING_STATUS', true)
    await this.$store.dispatch('getObjectives', { validFrom: this.filterValidFrom, validTo: this.filterValidTo, treeView: this.objectivesTreeView })
    this.$store.commit('SET_LOADING_STATUS', false)
    if (Object.keys(this.selectedObjective).length) {
      await this.$store.dispatch('getObjective', this.selectedObjective.id)
    }
    await this.$store.dispatch('getOrgUnits', { validFrom: this.filterValidFrom, validTo: this.filterValidTo })
    await this.$store.dispatch('getKPIUnits')
    await this.$store.dispatch('getEmployees', { mode: 'default' })
    await this.$store.dispatch('getKPIs', { validFrom: this.filterValidFrom, validTo: this.filterValidTo })
  }
}
</script>

<style scoped>
.dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .2em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}
.dropdown-toggle:after {
  display: none;
}
.dropdown-menu {
  min-width: 330px;
}
</style>
