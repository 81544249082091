import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import LoginPage from '../views/public/LoginPage.vue'
import RegisterPage from '../views/public/RegisterPage.vue'
import AccountManageUser from '../views/account/ManageUser.vue'
import SettingsManagePermission from '../views/settings/ManagePermission.vue'
import SettingsManageTenant from '../views/settings/ManageTenant.vue'
import ObjectivesXMatrix from '../views/objectives/XMatrix.vue'
import ObjectivesManageObjective from '../views/objectives/ManageObjective.vue'
import MonitoringKPI from '../views/monitoring/KPI.vue'
import MonitoringKPIChange from '../views/monitoring/KPIChange.vue'
import MonitoringKPIDeviation from '../views/monitoring/KPIDeviation.vue'
import MonitoringManageKPI from '../views/monitoring/ManageKPI.vue'
import MonitoringManageKPIUnit from '../views/monitoring/ManageKPIUnit.vue'
import MonitoringManageKPIRecord from '../views/monitoring/ManageKPIRecord.vue'
import ProjectsManageProject from '../views/projects/ManageProject.vue'
import OrgManageOrgUnit from '../views/org/ManageOrgUnit.vue'
import OrgManageEmployee from '../views/org/ManageEmployee.vue'

function isAuthenticated (to, from, next) {
  store.state.loginStatus ? next() : next('/login')
}

function accessLogin (to, from, next) {
  store.state.loginStatus ? next('/objectives/manage/objectives') : next()
}

function accessRegister (to, from, next) {
  if (store.state.loginStatus) {
    next('/objectives/manage/objectives')
  } else if (process.env.VUE_APP_TENANT_MODE === 'Single') {
    next('/login')
  } else {
    next()
  }
}

function isAdmin (to, from, next) {
  const adminPages = ['/settings/permissions', '/organization/manage/employees', '/monitoring/manage/kpis/units']
  if (store.state.loginStatus) {
    if (adminPages.includes(to.path) && store.state.user.role !== 10387) {
      next('/objectives/manage/objectives')
    } else {
      next()
    }
  } else {
    next('/login')
  }
}

const routes = [
  {
    path: '/register',
    name: 'RegisterPage',
    beforeEnter: accessRegister,
    component: RegisterPage,
    meta: {
      title: 'Registrieren - focusMT'
    }
  },
  {
    path: '/login',
    name: 'LoginPage',
    beforeEnter: accessLogin,
    component: LoginPage,
    meta: {
      title: 'Login - focusMT'
    }
  },
  {
    path: '/account/manage/user',
    name: 'AccountManageUser',
    beforeEnter: isAuthenticated,
    component: AccountManageUser,
    meta: {
      title: 'Account verwalten - focusMT'
    }
  },
  {
    path: '/settings/permissions',
    name: 'SettingsManagePermission',
    beforeEnter: isAdmin,
    component: SettingsManagePermission,
    meta: {
      title: 'Berechtigungen verwalten - focusMT'
    }
  },
  {
    path: '/settings/tenant',
    name: 'SettingsManageTenant',
    beforeEnter: isAdmin,
    component: SettingsManageTenant,
    meta: {
      title: 'Tenant verwalten - focusMT'
    }
  },
  {
    path: '/objectives/manage/objectives',
    name: 'ObjectivesManageObjective',
    beforeEnter: isAuthenticated,
    component: ObjectivesManageObjective,
    meta: {
      title: 'Ziele verwalten - focusMT'
    }
  },
  {
    path: '/objectives/x-matrix',
    name: 'ObjectivesXMatrix',
    beforeEnter: isAuthenticated,
    component: ObjectivesXMatrix,
    meta: {
      title: 'X-Matrix anzeigen - focusMT'
    }
  },
  {
    path: '/monitoring/kpis',
    name: 'MonitoringKPI',
    beforeEnter: isAuthenticated,
    component: MonitoringKPI,
    meta: {
      title: 'KPIs anzeigen - focusMT'
    }
  },
  {
    path: '/monitoring/kpis/changes',
    name: 'MonitoringKPIChange',
    beforeEnter: isAuthenticated,
    component: MonitoringKPIChange,
    meta: {
      title: 'Veränderungen analysieren - focusMT'
    }
  },
  {
    path: '/monitoring/kpis/deviations',
    name: 'MonitoringKPIDeviation',
    beforeEnter: isAuthenticated,
    component: MonitoringKPIDeviation,
    meta: {
      title: 'Abweichungen analysieren - focusMT'
    }
  },
  {
    path: '/monitoring/manage/kpis',
    name: 'MonitoringManageKPI',
    beforeEnter: isAuthenticated,
    component: MonitoringManageKPI,
    meta: {
      title: 'KPIs verwalten - focusMT'
    }
  },
  {
    path: '/monitoring/manage/kpis/records',
    name: 'MonitoringManageKPIRecord',
    beforeEnter: isAuthenticated,
    component: MonitoringManageKPIRecord,
    meta: {
      title: 'KPI-Werte erfassen - focusMT'
    }
  },
  {
    path: '/monitoring/manage/kpis/units',
    name: 'MonitoringManageKPIUnit',
    beforeEnter: isAdmin,
    component: MonitoringManageKPIUnit,
    meta: {
      title: 'KPI-Einheiten verwalten - focusMT'
    }
  },
  {
    path: '/projects/manage/projects',
    name: 'ProjectsManageProject',
    beforeEnter: isAuthenticated,
    component: ProjectsManageProject,
    meta: {
      title: 'Projekte verwalten - focusMT'
    }
  },
  {
    path: '/organization/manage/org-units',
    name: 'OrgManageOrgUnit',
    beforeEnter: isAuthenticated,
    component: OrgManageOrgUnit,
    meta: {
      title: 'Org-Einheiten verwalten - focusMT'
    }
  },
  {
    path: '/organization/manage/employees',
    name: 'OrgManageEmployee',
    beforeEnter: isAdmin,
    component: OrgManageEmployee,
    meta: {
      title: 'Mitarbeiter verwalten - focusMT'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    beforeEnter: isAuthenticated,
    redirect: { name: 'LoginPage' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from) => {
  document.title = to.meta?.title ?? 'Das Management-Tool - focusMT'
})

export default router
