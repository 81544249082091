export function formatPeriod (period) {
  period = period.toString()
  if (period.length === 4) {
    return period
  } else if (period.length === 5) {
    return period.slice(0, 4) + '-Q' + period.slice(4)
  } else if (period.length === 6) {
    return period.slice(0, 4) + '-' + period.slice(4)
  }
}
