<template>
  <div v-if="isLoading" class="spinner-border text-primary m-auto" role="status">
    <span class="visually-hidden">{{ $t("general.loading") }}</span>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  computed: {
    isLoading () {
      return this.$store.state.loadingStatus
    }
  }
}
</script>

<style>
.spinner-border {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  font-size: 25px !important;
  width: 80px !important;
  height: 80px !important;
  z-index: 999 !important;
}
</style>
