<template>
  <div class="project-modal">
    <div ref="projectEditModal" class="modal fade" data-bs-backdrop="static" id="projectEditModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">
              {{ vocabulary[elementType] }} {{ vocabulary[mode] }}
              <DocsInfoLink color="light" target="createProject"></DocsInfoLink>
            </div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div v-if="elementType === 'project'" class="form-link">
              <div class="mb-3">
                <div class="form-check form-switch">
                  <input v-model="form.project.folder.content" type="checkbox" role="switch" id="projectFolderCheck" class="form-check-input" :checked="form.project.folder.content">
                  <label for="projectFolderCheck" class="form-check-label">{{ $t("views.project.edit.folder") }}</label>
                </div>
              </div>
              <div class="mb-3">
                <label for="projectParentId" class="form-label">{{ $t("views.project.edit.parentProject") }}</label>
                <select v-model="form.project.parentId.content" id="projectParentId" class="form-select">
                  <option value="" :disabled="!isAdmin">{{ $t("views.project.edit.noParentProject") }}</option>
                  <option v-for="project in projectsList" :key="project.id" :value="project.id" :disabled="mode === 'update' && project.id === selectedProject.id ? true : false">
                    {{ project.name }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="projectName" class="form-label">{{ $t("views.project.edit.name") }}<span class="text-danger">*</span></label>
                <input v-model="form.project.name.content" id="projectName" :class="form.project.name.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.project.name.error }}</div>
              </div>
              <div class="mb-3">
                <label for="projectDescription" class="form-label">{{ $t("views.project.edit.description") }}<span class="text-danger">*</span></label>
                <RTEditor v-model:content="form.project.description.content" contentType="html" id="projectDescription"></RTEditor>
                <div class="quill-invalid-feedback">{{ form.project.description.error }}</div>
              </div>
              <div class="row mb-3">
                <div class="col-6">
                  <label for="projectValidFrom" class="form-label">{{ $t("views.project.edit.validFrom") }}<span class="text-danger">*</span></label>
                  <input v-model="form.project.validFrom.content" type="date" id="projectValidFrom" :class="form.project.validFrom.error === '' ? 'form-control' : 'form-control is-invalid'">
                  <div class="invalid-feedback">{{ form.project.validFrom.error }}</div>
                </div>
                <div class="col-6">
                  <label for="projectValidTo" class="form-label">{{ $t("views.project.edit.validTo") }}<span class="text-danger">*</span></label>
                  <input v-model="form.project.validTo.content" type="date" id="projectValidTo" :class="form.project.validTo.error === '' ? 'form-control' : 'form-control is-invalid'">
                  <div class="invalid-feedback">{{ form.project.validTo.error }}</div>
                </div>
              </div>
            </div>
            <div v-if="elementType === 'review'" class="form-link">
              <div class="mb-3">
                <label for="reviewDate" class="form-label">{{ $t("views.project.edit.reviewDate") }}<span class="text-danger">*</span></label>
                <input v-model="form.review.date.content" type="date" id="reviewDate" :class="form.review.date.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.review.date.error }}</div>
              </div>
              <div class="mb-3">
                <label for="reviewAssessment" class="form-label">{{ $t("views.project.edit.reviewAssessment") }}<span class="text-danger">*</span></label>
                <RTEditor v-model:content="form.review.assessment.content" contentType="html" id="reviewAssessment"></RTEditor>
                <div class="quill-invalid-feedback">{{ form.review.assessment.error }}</div>
              </div>
              <div class="mb-3">
                <label for="reviewConversion" class="form-label">{{ $t("views.project.edit.reviewConversion") }}<span class="text-danger">*</span></label>
                <select v-model="form.review.conversion.content" id="reviewConversion" :class="form.review.conversion.error === '' ? 'form-select' : 'form-select is-invalid'">
                  <option value="0">0%</option>
                  <option value="1">25%</option>
                  <option value="2">50%</option>
                  <option value="3">75%</option>
                  <option value="4">100%</option>
                </select>
                <div class="invalid-feedback">{{ form.review.conversion.error }}</div>
              </div>
              <div class="mb-3">
                <label for="reviewPrediction" class="form-label">{{ $t("views.project.edit.reviewPrediction") }}<span class="text-danger">*</span></label>
                <select v-model="form.review.prediction.content" id="reviewPrediction" :class="form.review.prediction.error === '' ? 'form-select' : 'form-select is-invalid'">
                  <option value="Y">{{ $t("views.project.detail.review.prediction.y") }}</option>
                  <option value="D">{{ $t("views.project.detail.review.prediction.d") }}</option>
                  <option value="N">{{ $t("views.project.detail.review.prediction.n") }}</option>
                </select>
                <div class="invalid-feedback">{{ form.review.prediction.error }}</div>
              </div>
            </div>
            <div v-if="elementType === 'measure'" class="form-link">
              <div class="mb-3">
                <label for="measureTitle" class="form-label">{{ $t("views.project.edit.measureTitle") }}<span class="text-danger">*</span></label>
                <input v-model="form.measure.title.content" id="measureTitle" :class="form.measure.title.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.measure.title.error }}</div>
              </div>
              <div class="mb-3">
                <label for="measureMeasure" class="form-label">{{ $t("views.project.edit.measureDescription") }}<span class="text-danger">*</span></label>
                <RTEditor v-model:content="form.measure.measure.content" contentType="html" id="measureMeasure"></RTEditor>
                <div class="quill-invalid-feedback">{{ form.measure.measure.error }}</div>
              </div>
              <div class="mb-3">
                <label for="measureResponsible" class="form-label">{{ $t("views.project.edit.measureResponsible") }}<span class="text-danger">*</span></label>
                <select v-model="form.measure.responsible.content" id="measureResponsible" :class="form.measure.responsible.error === '' ? 'form-select' : 'form-select is-invalid'">
                  <option v-for="employee in employees" :key="employee.id" :value="employee.id">
                    {{ employee.lastName }}, {{ employee.firstName }}
                  </option>
                </select>
                <div class="invalid-feedback">{{ form.measure.responsible.error }}</div>
              </div>
              <div class="mb-3">
                <label for="measureConversion" class="form-label">{{ $t("views.project.edit.measureConversion") }}</label>
                <select v-model="form.measure.conversion.content" id="measureConversion" :class="form.measure.conversion.error === '' ? 'form-select' : 'form-select is-invalid'">
                  <option value="0">0%</option>
                  <option value="1">25%</option>
                  <option value="2">50%</option>
                  <option value="3">75%</option>
                  <option value="4">100%</option>
                </select>
                <div class="invalid-feedback">{{ form.measure.conversion.error }}</div>
              </div>
              <div class="mb-3">
                <label for="measureValidFrom" class="form-label">{{ $t("views.project.edit.measureValidFrom") }}<span class="text-danger">*</span></label>
                <input v-model="form.measure.validFrom.content" type="date" id="measureValidFrom" :class="form.measure.validFrom.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.measure.validFrom.error }}</div>
              </div>
              <div class="mb-3">
                <label for="measureValidTo" class="form-label">{{ $t("views.project.edit.measureValidTo") }}<span class="text-danger">*</span></label>
                <input v-model="form.measure.validTo.content" type="date" id="measureValidTo" :class="form.measure.validTo.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.measure.validTo.error }}</div>
              </div>
            </div>
          </div>
          <div class="modal-footer bg-light justify-content-between">
            <div>
              <button v-if="elementType === 'project'" type="button" class="btn btn-sm btn-outline-danger" :class="selectedProject.hasNodes ? 'disabled' : ''" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#projectDeleteModal">{{ $t("general.delete") }}</button>
            </div>
            <div>
              <button type="button" class="btn btn-sm btn-outline-secondary me-2" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
              <button @click="save()" type="button" class="btn btn-sm btn-primary">{{ $t("general.save") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as validate from '../../composables/validation'
import DocsInfoLink from '../../components/DocsInfoLink.vue'
import RTEditor from '../RTEditor.vue'
import { Modal } from 'bootstrap'
import { ref } from 'vue'

export default {
  name: 'ProjectEditModal',
  data () {
    return {
      vocabulary: {
        project: 'Projekt',
        review: 'Review',
        measure: 'Maßnahme',
        add: 'hinzufügen',
        update: 'bearbeiten'
      },
      form: {
        project: {
          folder: {
            content: '',
            error: '',
            validate: []
          },
          parentId: {
            content: '',
            error: '',
            validate: []
          },
          name: {
            content: '',
            error: '',
            maxLength: 255,
            validate: ['length', 'empty']
          },
          description: {
            content: '',
            error: '',
            validate: ['empty']
          },
          validFrom: {
            content: '',
            error: '',
            validate: ['empty']
          },
          validTo: {
            content: '',
            error: '',
            validate: ['empty']
          }
        },
        review: {
          date: {
            content: '',
            error: '',
            validate: ['empty']
          },
          assessment: {
            content: '',
            error: '',
            validate: ['empty']
          },
          conversion: {
            content: '',
            error: '',
            validate: ['empty']
          },
          prediction: {
            content: '',
            error: '',
            validate: ['empty']
          }
        },
        measure: {
          title: {
            content: '',
            error: '',
            maxLength: 255,
            validate: ['length', 'empty']
          },
          measure: {
            content: '',
            error: '',
            validate: ['empty']
          },
          responsible: {
            content: '',
            error: '',
            validate: ['empty']
          },
          conversion: {
            content: '',
            error: '',
            validate: []
          },
          validFrom: {
            content: '',
            error: '',
            validate: ['empty']
          },
          validTo: {
            content: '',
            error: '',
            validate: ['empty']
          }
        }
      }
    }
  },
  components: {
    DocsInfoLink,
    RTEditor
  },
  computed: {
    selectedProject () {
      return this.$store.state.selectedProject
    },
    projectsList () {
      return this.$store.state.projectsList
    },
    employees () {
      return this.$store.state.employees
    },
    elementId () {
      return this.$parent.projectModalElementId
    },
    elementType () {
      return this.$parent.projectModalElementType
    },
    mode () {
      return this.$parent.projectModalMode
    },
    isAdmin () {
      return this.$store.state.user.role === 10387
    }
  },
  methods: {
    async save () {
      this.$store.commit('SET_LOADING_STATUS', true)

      let valid = 0

      Object.keys(this.form[this.elementType]).forEach(field => {
        this.form[this.elementType][field].validate.forEach(validation => {
          const validationResult = validate.useValidation(this.form[this.elementType], field, validation)
          if (!validationResult.status) {
            this.form[this.elementType][field].error = validationResult.content
            valid++
          }
        })
      })

      if (valid === 0) {
        const elementData = Object.assign(
          ...Object.entries(this.form[this.elementType]).map(([key, value]) => ({ [key]: value.content }))
        )
        elementData.id = this.elementId
        let dispatchFunc = ''
        if (this.elementType !== 'project') {
          elementData.elementType = (this.elementType + 's')
          elementData.projectId = this.selectedProject.id
        }
        dispatchFunc = this.elementType === 'project' ? (this.mode + 'Project') : (this.mode + 'ProjectElement')
        await this.$store.dispatch(dispatchFunc, elementData).then(() => {
          this.closeModal()
          Object.keys(this.form[this.elementType]).forEach(field => {
            if (this.mode === 'add') {
              if (field === 'folder') {
                this.form[this.elementType][field].content = false
              } else {
                this.form[this.elementType][field].content = ''
              }
            }
            this.form[this.elementType][field].error = ''
          })
        })
      }

      this.$store.commit('SET_LOADING_STATUS', false)

      await this.$store.dispatch('getProjects', { validFrom: this.$parent.filterValidFrom, validTo: this.$parent.filterValidTo })
    },
    updateModalContent () {
      Object.keys(this.form[this.elementType]).forEach(field => {
        let selectedElement = this.selectedProject
        if (this.elementType !== 'project') {
          const elementTypePlural = this.elementType + 's'
          selectedElement = this.selectedProject[elementTypePlural].find(element => {
            return element.id === this.$parent.projectModalElementId
          })
          this.$parent.selectedElement = selectedElement
        }
        if (field in selectedElement) {
          if (field === 'responsible') {
            this.form[this.elementType].responsible.content = selectedElement[field].id
          } else {
            this.form[this.elementType][field].content = selectedElement[field]
          }
          this.form[this.elementType][field].error = ''
        }
      })
    }
  },
  watch: {
    elementId () {
      this.updateModalContent()
    },
    mode (newMode) {
      if (newMode === 'add') {
        Object.keys(this.form[this.elementType]).forEach(field => {
          if (field === 'folder') {
            this.form[this.elementType][field].content = false
          } else {
            this.form[this.elementType][field].content = this.form[this.elementType][field].error = ''
          }
        })
      } else if (newMode === 'update') {
        this.updateModalContent()
      }
    }
  },
  setup () {
    const projectEditModal = ref(null)
    const closeModal = () => Modal.getInstance(projectEditModal.value)?.hide()
    return { projectEditModal, closeModal }
  }
}
</script>
