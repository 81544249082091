<template>
  <div class="record-modal">
    <div ref="kpiRecordEditModal" class="modal fade" data-bs-backdrop="static" id="kpiRecordEditModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">{{ heading }}</div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div class="row justify-content-between">
              <div class="col">
                <div class="h4">{{ kpi.name }}</div>
              </div>
              <div class="col text-end">{{ $t("views.kpi.edit.unit") }}: {{ kpi.unit ? kpi.unit : 'n/a' }}</div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="text-start">{{ $t("views.kpi.edit.timeDim") }}</th>
                  <th v-if="kpiRecordMode !== 'actual'" scope="col">{{ $t("views.kpi.edit.plan") }}</th>
                  <th v-if="kpiRecordMode === 'actual'" scope="col">{{ $t("views.kpi.edit.actual") }}</th>
                  <th v-if="kpiRecordMode === 'objective'" scope="col">{{ $t("views.kpi.edit.objective") }}</th>
                  <th v-if="kpiRecordMode === 'actual'" scope="col">{{ $t("views.kpi.edit.forecast") }}</th>
                  <th v-if="kpiRecordMode === 'objective'" scope="col">{{ $t("views.kpi.edit.benchmark") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="periodIndex in selectedKPIRecords.order" :key="periodIndex">
                  <td><input :value="formatPeriod(selectedKPIRecords[periodIndex].period)" type="text" class="form-control form-control-sm" disabled></td>
                  <td v-if="kpiRecordMode !== 'actual'">
                    <input @input="calcValues('plan', periodIndex)" @change="mirrorForecast(periodIndex)" v-model="selectedKPIRecords[periodIndex].plan" type="number" class="form-control form-control-sm text-end" :disabled="selectedKPIRecords[periodIndex].disabled || kpiRecordMode === 'objective'">
                  </td>
                  <td v-if="kpiRecordMode === 'actual'">
                    <input @input="calcValues('actual', periodIndex)" v-model="selectedKPIRecords[periodIndex].actual" type="number" class="form-control form-control-sm text-end" :disabled="selectedKPIRecords[periodIndex].disabled">
                  </td>
                  <td v-if="kpiRecordMode === 'objective'">
                    <input @input="calcValues('objective', periodIndex)" v-model="selectedKPIRecords[periodIndex].objective" type="number" class="form-control form-control-sm text-end" :disabled="selectedKPIRecords[periodIndex].disabled">
                  </td>
                  <td v-if="kpiRecordMode === 'actual'">
                    <input @input="calcValues('forecast', periodIndex)" v-model="selectedKPIRecords[periodIndex].forecast" type="number" class="form-control form-control-sm text-end" :disabled="selectedKPIRecords[periodIndex].disabled || selectedKPIRecords[periodIndex].actual !== ''">
                  </td>
                  <td v-if="kpiRecordMode === 'objective'">
                    <input @input="calcValues('benchmark', periodIndex)" v-model="selectedKPIRecords[periodIndex].benchmark" type="number" class="form-control form-control-sm text-end" :disabled="selectedKPIRecords[periodIndex].disabled">
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              {{ $t("views.kpi.edit.aggregation") }}: {{ vocabulary.aggregation[kpi.aggregation] }}
            </div>
          </div>
          <div class="modal-footer bg-light justify-content-end">
            <div>
              <button type="button" class="btn btn-sm btn-outline-secondary me-2" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
              <button @click="save()" type="button" class="btn btn-sm btn-primary">{{ $t("general.save") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as periodUtils from '../../composables/period'
import { Modal } from 'bootstrap'
import { ref } from 'vue'

export default {
  name: 'KPIRecordEditModal',
  props: ['kpi'],
  computed: {
    vocabulary () {
      return this.$parent.vocabulary
    },
    selectedKPIRecords () {
      return this.$store.state.selectedKPIRecords
    },
    kpiRecordMode () {
      return this.$store.state.kpiRecordMode
    },
    selectedYear () {
      return this.$parent.selectedYear
    },
    heading () {
      return this.$parent.heading
    }
  },
  methods: {
    async save () {
      this.$store.commit('SET_LOADING_STATUS', true)

      const elementData = { id: this.kpi.id, records: this.selectedKPIRecords, selectedYear: this.selectedYear }
      await this.$store.dispatch('updateKPIRecords', elementData).then(() => {
        this.closeModal()
      })

      this.$store.commit('SET_LOADING_STATUS', false)
    },
    mirrorForecast (periodIndex) {
      if (!this.selectedKPIRecords[periodIndex].forecast) {
        this.selectedKPIRecords[periodIndex].forecast = this.selectedKPIRecords[periodIndex].plan
      }
    },
    calcValues (column, periodIndex) {
      if (column === 'actual' && this.selectedKPIRecords[periodIndex].forecast !== '') {
        this.selectedKPIRecords[periodIndex].forecast = ''
      }

      const order = this.selectedKPIRecords.order
      if (this.kpi.timeDimInput === 'M') {
        const quarters = [3, 7, 11, 15]
        quarters.forEach(quarter => {
          let quarterValue = ''
          let quarterValueForecast = ''
          let validMonths = 0
          let validForecastMonths = 0
          for (let i = 1; i <= 3; i++) {
            if ((this.selectedKPIRecords[order[quarter - i]][column] !== '') || (column === 'actual' && this.selectedKPIRecords[order[quarter - i]].forecast !== '') || (column === 'forecast' && this.selectedKPIRecords[order[quarter - i]].actual !== '')) {
              validForecastMonths++
              if (column !== 'forecast' && this.selectedKPIRecords[order[quarter - i]][column] !== '') {
                validMonths++
              }
              if (this.kpiRecordMode === 'actual' && this.kpi.aggregation !== 'A') {
                if (quarterValueForecast) {
                  if (this.selectedKPIRecords[order[quarter - i]].actual !== '') {
                    quarterValueForecast += parseFloat(this.selectedKPIRecords[order[quarter - i]].actual)
                  } else {
                    quarterValueForecast += parseFloat(this.selectedKPIRecords[order[quarter - i]].forecast)
                  }
                } else {
                  if (this.selectedKPIRecords[order[quarter - i]].actual !== '') {
                    quarterValueForecast = parseFloat(this.selectedKPIRecords[order[quarter - i]].actual)
                  } else {
                    quarterValueForecast = parseFloat(this.selectedKPIRecords[order[quarter - i]].forecast)
                  }
                }
              }

              if (column !== 'forecast' && this.selectedKPIRecords[order[quarter - i]][column] !== '') {
                if (quarterValue) {
                  quarterValue += parseFloat(this.selectedKPIRecords[order[quarter - i]][column])
                } else {
                  quarterValue = parseFloat(this.selectedKPIRecords[order[quarter - i]][column])
                }
              }
            }
          }

          if (column !== 'forecast') {
            if (quarterValue && this.kpi.aggregation === 'M') {
              quarterValue = (quarterValue / validMonths).toFixed(this.kpi.decimals)
            } else if (this.kpi.aggregation === 'A') {
              for (let i = 1; i <= 3; i++) {
                quarterValue = this.selectedKPIRecords[order[(quarter - 4) + i]][column] ? this.selectedKPIRecords[order[(quarter - 4) + i]][column] : quarterValue
              }
            }
            this.selectedKPIRecords[order[quarter]][column] = quarterValue
          }

          if (this.kpiRecordMode === 'actual') {
            if (this.kpi.aggregation === 'M' && quarterValueForecast) {
              quarterValueForecast = (quarterValueForecast / validForecastMonths).toFixed(this.kpi.decimals)
            } else if (this.kpi.aggregation === 'A') {
              for (let i = 1; i <= 3; i++) {
                quarterValueForecast = this.selectedKPIRecords[order[(quarter - 4) + i]].actual ? this.selectedKPIRecords[order[(quarter - 4) + i]].actual : this.selectedKPIRecords[order[(quarter - 4) + i]].forecast ? this.selectedKPIRecords[order[(quarter - 4) + i]].forecast : quarterValueForecast
              }
            }
            this.selectedKPIRecords[order[quarter]].forecast = quarterValueForecast
          }
        })
      }
      if (this.kpi.timeDimInput !== 'Y') {
        const quarters = this.kpi.timeDimInput === 'M' ? [3, 7, 11, 15] : [0, 1, 2, 3]
        let yearValue = ''
        let yearValueForecast = ''
        let validQuarters = 0
        let validForecastQuarters = 0
        quarters.forEach(quarter => {
          if (this.kpi.aggregation === 'A') {
            if (this.selectedKPIRecords[order[quarter]][column] !== '') {
              yearValue = parseFloat(this.selectedKPIRecords[order[quarter]][column])
              yearValueForecast = parseFloat(this.selectedKPIRecords[order[quarter]].forecast)
            }
          } else {
            if (this.kpiRecordMode === 'actual') {
              if (yearValueForecast === '') {
                if (this.selectedKPIRecords[order[quarter]].actual !== '') {
                  yearValueForecast = parseFloat(this.selectedKPIRecords[order[quarter]].actual)
                  validForecastQuarters++
                } else if (this.selectedKPIRecords[order[quarter]].forecast !== '') {
                  yearValueForecast = parseFloat(this.selectedKPIRecords[order[quarter]].forecast)
                  validForecastQuarters++
                }
              } else {
                if (this.selectedKPIRecords[order[quarter]].actual !== '') {
                  yearValueForecast += parseFloat(this.selectedKPIRecords[order[quarter]].actual)
                  validForecastQuarters++
                } else if (this.selectedKPIRecords[order[quarter]].forecast !== '') {
                  yearValueForecast += parseFloat(this.selectedKPIRecords[order[quarter]].forecast)
                  validForecastQuarters++
                }
              }
            }
            if (column !== 'forecast' && this.selectedKPIRecords[order[quarter]][column] !== '') {
              if (yearValue === '') {
                yearValue = parseFloat(this.selectedKPIRecords[order[quarter]][column])
                validQuarters++
              } else {
                yearValue += parseFloat(this.selectedKPIRecords[order[quarter]][column])
                validQuarters++
              }
            }
          }
          validForecastQuarters = validForecastQuarters + 0
        })

        // Year Value
        if (this.kpi.aggregation === 'M' && yearValue) {
          yearValue = (yearValue / validQuarters).toFixed(this.kpi.decimals)
        }
        if (this.kpi.timeDimInput === 'M') {
          this.selectedKPIRecords[order[16]][column] = yearValue
        } else {
          this.selectedKPIRecords[order[4]][column] = yearValue
        }

        // Year Forecast
        if (this.kpi.aggregation === 'M' && yearValueForecast) {
          yearValueForecast = (yearValueForecast / validForecastQuarters).toFixed(this.kpi.decimals)
        }
        if (this.kpi.timeDimInput === 'M') {
          this.selectedKPIRecords[order[16]].forecast = yearValueForecast
        } else {
          this.selectedKPIRecords[order[4]].forecast = yearValueForecast
        }
      }
    },
    formatPeriod (period) {
      return periodUtils.formatPeriod(period)
    }
  },
  setup () {
    const kpiRecordEditModal = ref(null)
    const closeModal = () => Modal.getInstance(kpiRecordEditModal.value)?.hide()
    return { kpiRecordEditModal, closeModal }
  }
}
</script>

<style scoped>
table {
  table-layout: fixed;
}
table thead th {
  text-align: end;
}
table input::-webkit-outer-spin-button,
table input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
table input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
