<template>
  <div>
    <div class="bg-custom-blue">
      <div class="container">
        <div class="row py-2">
          <div class="col-1">
            <select v-model="selectedYear" @change="getKPIDeviations()" class="form-select">
              <option v-for="(n, i) in 21" :key="'selectedYear' + (10 - i)" :value="((selectedYear + 10) - i)">{{ (selectedYear + 10) - i }}</option>
            </select>
          </div>
          <div class="col-3 ms-auto">
            <input v-model="filterName" type="text" class="form-control" :placeholder='$t("general.placeholderSearch")'>
          </div>
        </div>
      </div>
    </div>
    <div v-if="filteredKPIDeviations.length" class="container my-5">
      <div class="row">
        <div class="col-12">
          <div class="h4">
            {{ $t("views.kpi.detail.headingDeviation") }}
            <DocsInfoLink color="dark" target="kpiDeviation"></DocsInfoLink>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="bg-white rounded-2 p-2">
            <div class="row px-3">
              <div class="col-4 offset-5 text-center border-2 border-start border-end border-dark px-1 pb-3">
                {{ $t("views.kpi.detail.ytd") }}
              </div>
              <div class="col-3 text-center px-1">
                {{ $t("views.kpi.detail.yearPreview") }}
              </div>
            </div>
            <div class="row fw-bold px-3">
              <div class="col-2 px-1">
                {{ $t("views.kpi.detail.kpi") }}
              </div>
              <div class="col-2 px-1">
                {{ $t("views.kpi.edit.responsible") }}
              </div>
              <div class="col-1 px-1">
                {{ $t("views.kpi.edit.unit") }}
              </div>
              <div class="col-1 ps-3 pe-1 border-2 border-start border-dark">
                {{ $t("views.kpi.periods.period") }}
              </div>
              <div class="col-1 text-end px-1">
                {{ $t("views.kpi.edit.actual") }}
              </div>
              <div class="col-1 text-end px-0">
                <select v-model="selectedYTDPreview" class="select-th text-end">
                  <option value="actualPlan">{{ $t("views.kpi.edit.plan") }}</option>
                  <option value="actualObjective">{{ $t("views.kpi.edit.objective") }}</option>
                </select>
              </div>
              <div class="col-1 text-end ps-1 pe-3 border-2 border-end border-dark">
                {{ $t("views.kpi.detail.abbreviationDev") }}
              </div>
              <div class="col-1 text-end px-1">
                {{ $t("views.kpi.edit.forecast") }}
              </div>
              <div class="col-1 text-end px-0">
                <select v-model="selectedYearPreview" class="select-th text-end">
                  <option value="forecastPlan">{{ $t("views.kpi.edit.plan") }}</option>
                  <option value="forecastObjective">{{ $t("views.kpi.edit.objective") }}</option>
                </select>
              </div>
              <div class="col-1 text-end ps-1 pe-3">
                {{ $t("views.kpi.detail.abbreviationDev") }}
              </div>
            </div>
            <div v-for="deviation in filteredKPIDeviations" :key="deviation.id" class="row px-3 border-bottom">
              <div class="col-2 my-auto col-kpi-name px-1">
                <a @click="selectKPI(deviation.id)" :href="'#kpiPeriodsModal' + selectedKPI.id" data-bs-toggle="modal">{{ deviation.name }}</a>
              </div>
              <div class="col-2 my-auto px-1">
                {{ deviation.responsible }}
              </div>
              <div class="col-1 my-auto px-1">
                {{ deviation.unit }}
              </div>
              <div class="col-1 my-auto ps-3 pe-1 py-3 border-2 border-start border-dark">
                {{ formatPeriod(deviation.period) }}
              </div>
              <div class="col-1 text-end my-auto px-1">
                {{ deviation.actual.toLocaleString('de-DE') }}
              </div>
              <div class="col-1 text-end my-auto px-1">
                <div v-if="selectedYTDPreview === 'actualPlan'" class="text-end">{{ deviation.plan.toLocaleString('de-DE') }}</div>
                <div v-else class="text-end">{{ deviation.objective.toLocaleString('de-DE') }}</div>
              </div>
              <div class="col-1 text-end border-2 border-end border-dark my-auto ps-1 pe-3 py-3">
                <div v-if="selectedYTDPreview === 'actualPlan'" class="text-end" :class="deviation.devActualPlan?.includes('+') ? 'text-success' : 'text-danger'">{{ deviation.devActualPlan }}</div>
                <div v-else class="text-end" :class="deviation.devActualObjective?.includes('+') ? 'text-success' : 'text-danger'">{{ deviation.devActualObjecitve }}</div>
              </div>
              <div class="col-1 text-end my-auto px-1">
                {{ deviation.yearForecast.toLocaleString('de-DE') }}
              </div>
              <div class="col-1 text-end my-auto px-1">
                <div v-if="selectedYearPreview === 'forecastPlan'" class="text-end">{{ deviation.yearPlan.toLocaleString('de-DE') }}</div>
                <div v-else class="text-end">{{ deviation.yearObjective.toLocaleString('de-DE') }}</div>
              </div>
              <div class="col-1 text-end my-auto ps-1 pe-3">
                <div v-if="selectedYearPreview === 'forecastPlan'" class="text-end" :class="deviation.devForecastPlan?.includes('+') ? 'text-success' : 'text-danger'">{{ deviation.devForecastPlan }}</div>
                <div v-else class="text-end" :class="deviation.devForecastObjective?.includes('+') ? 'text-success' : 'text-danger'">{{ deviation.devForecastObjective }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!loadingStatus" class="text-center mt-5">
      <img src="@/assets/img/no_data.svg" class="img-fluid" width="250">
      <p class="h5 mt-3">{{ $t("views.kpi.detail.noKPIs") }}.</p>
    </div>
    <KPIPeriodsModal :kpi="selectedKPI"></KPIPeriodsModal>
  </div>
</template>

<script>
import DocsInfoLink from '../../components/DocsInfoLink.vue'
import * as periodUtils from '../../composables/period'
import KPIPeriodsModal from '../../components/monitoring/KPIPeriodsModal.vue'

export default {
  name: 'MonitoringKPIDeviation',
  data () {
    return {
      selectedYear: this.$store.state.user?.settings?.favorites?.kpiDeviationSelectedYear ? this.$store.state.user.settings.favorites.kpiDeviationSelectedYear : new Date().getFullYear(),
      selectedYTDPreview: 'actualPlan',
      selectedYearPreview: 'forecastPlan',
      filterName: '',
      vocabulary: {
        timeDimInput: {
          M: 'Monat',
          Q: 'Quartal',
          Y: 'Jahr'
        }
      }
    }
  },
  components: {
    DocsInfoLink,
    KPIPeriodsModal
  },
  computed: {
    kpiDeviations () {
      return this.$store.state.kpiDeviations
    },
    filteredKPIDeviations () {
      return this.kpiDeviations.filter(deviation => {
        return deviation.name.toLowerCase().includes(this.filterName.toLowerCase())
      })
    },
    selectedKPI () {
      return this.$store.state.selectedKPI
    },
    loadingStatus () {
      return this.$store.state.loadingStatus
    }
  },
  methods: {
    async getKPIDeviations () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getKPIDeviations', this.selectedYear)
      await this.$store.dispatch('favoriteObject', { isList: false, objectKey: 'kpiDeviationSelectedYear', objectValue: this.selectedYear })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    formatPeriod (period) {
      return periodUtils.formatPeriod(period)
    },
    unitDisplay (kpi, displayMode) {
      const unit = this.$store.state.kpiUnits.find(unit => {
        return unit.id === this.selectedKPI.unit
      })
      if (unit) {
        if (displayMode === 'short') {
          return unit.name
        } else {
          return unit.name + ' (' + unit.unit + ')'
        }
      } else {
        return 'n/a'
      }
    },
    async selectKPI (kpiId) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getKPI', { mode: 'manage', id: kpiId })
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  async created () {
    this.$store.commit('SET_LOADING_STATUS', true)
    await this.$store.dispatch('getKPIDeviations', this.selectedYear)
    this.$store.commit('SET_LOADING_STATUS', false)
    await this.$store.dispatch('getKPIUnits')
  }
}
</script>

<style scoped>
.container {
  max-width: 1600px;
}
.col-kpi-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-th {
  background-color: transparent;
  border: 0;
  font-weight: bold;
}
</style>
