<template>
  <div class="objective-modal">
    <div ref="objectiveEditModal" class="modal fade" data-bs-backdrop="static" id="objectiveEditModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">{{ vocabulary[elementType] }} {{ mode === 'add' && elementType === 'record' ? 'erfassen' : vocabulary[mode] }}</div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div v-if="elementType === 'objective'" class="form-link">
              <div class="mb-3">
                <label for="objectiveOrgUnit" class="form-label">{{ $t("views.objective.edit.orgUnit") }}<span class="text-danger">*</span></label>
                <select v-model="form.objective.orgUnit.content" id="objectiveOrgUnit" :class="form.objective.orgUnit.error === '' ? 'form-select' : 'form-select is-invalid'">
                  <option v-for="orgUnit in orgUnitsList" :key="orgUnit.id" :value="orgUnit.id">{{ orgUnit.name }}</option>
                </select>
                <div class="invalid-feedback">{{ form.objective.orgUnit.error }}</div>
              </div>
              <div class="mb-3">
                <label for="objectiveParentId" class="form-label">{{ $t("views.objective.edit.parentObjective") }}<span class="text-danger">*</span></label>
                <select v-model="form.objective.parentId.content" id="objectiveParentId" class="form-select">
                  <option value="">{{ $t("views.objective.edit.noParentObjective") }}</option>
                  <option v-for="objective in objectivesList" :key="objective.id" :value="objective.id" :disabled="((mode === 'update' && objective.id === selectedObjective.id) || objective.org) ? true : false">
                    {{ objective.name }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="objectiveMentor" class="form-label">{{ $t("views.objective.edit.mentor") }}</label>
                <select v-model="form.objective.mentor.content" id="objectiveMentor" :class="form.objective.mentor.error === '' ? 'form-select' : 'form-select is-invalid'">
                  <option v-for="mentor in employees" :key="mentor.id" :value="mentor.id">
                    {{ mentor.lastName }}, {{ mentor.firstName }}
                  </option>
                </select>
                <div class="invalid-feedback">{{ form.objective.mentor.error }}</div>
              </div>
              <div class="mb-3">
                <label for="objectiveName" class="form-label">{{ $t("views.objective.edit.name") }}<span class="text-danger">*</span></label>
                <input v-model="form.objective.name.content" id="objectiveName" :class="form.objective.name.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.objective.name.error }}</div>
                <div class="mt-2">
                  <input v-model="form.objective.category.content" id="objectiveCategory" class="form-check-input" type="checkbox">
                  <label class="form-check-label ms-1" for="objectiveCategory">
                    {{ $t("views.objective.edit.breakthrough") }}
                  </label>
                </div>
              </div>
              <div class="mb-3">
                <label for="objectiveDescription" class="form-label">{{ $t("views.objective.edit.description") }}<span class="text-danger">*</span></label>
                <RTEditor v-model:content="form.objective.description.content" contentType="html" id="objectiveDescription"></RTEditor>
                <div class="quill-invalid-feedback">{{ form.objective.description.error }}</div>
              </div>
              <div class="row mb-3">
                <div class="col-6">
                  <label for="objectiveValidFrom" class="form-label">
                    {{ $t("views.objective.edit.validFrom") }}<span class="text-danger">*</span>
                  </label>
                  <input v-model="form.objective.validFrom.content" type="date" id="objectiveValidFrom" :class="form.objective.validFrom.error === '' ? 'form-control' : 'form-control is-invalid'">
                  <div class="invalid-feedback">{{ form.objective.validFrom.error }}</div>
                </div>
                <div class="col-6">
                  <label for="objectiveValidTo" class="form-label">
                    {{ $t("views.objective.edit.validTo") }}<span class="text-danger">*</span>
                  </label>
                  <input v-model="form.objective.validTo.content" type="date" id="objectiveValidTo" :class="form.objective.validTo.error === '' ? 'form-control' : 'form-control is-invalid'">
                  <div class="invalid-feedback">{{ form.objective.validTo.error }}</div>
                </div>
              </div>
              <div v-if="selectedObjective.criterion === 'KPI'">
                <div class="row">
                  <div class="col-4">
                    <label for="kpis" class="form-label">
                      {{ $t("views.objective.edit.kpis") }}<span class="text-danger">*</span>
                    </label>
                    <div class="input-group">
                      <select @change="addKPI()" v-model="kpiSelected" id="kpis" class="form-select">
                        <option :value="{ id: 0, name: 'Auswählen...' }">{{ kpiSelected.name }}</option>
                        <option v-for="kpi in kpisList" :key="kpi.id" :value="kpi" :disabled="kpi.folder ? true : false">{{ kpi.name }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row selected-kpis">
                  <div class="col-12 mt-3">
                    <div v-for="selectedKPI, index in form.objective.kpis.content" :key="'selected-kpi' + selectedKPI.id" class="input-group input-group-sm mb-3">
                      <span class="input-group-text">
                        {{ selectedKPI.name }}
                      </span>
                      <button @click="deleteKPI(index)" class="btn btn-sm btn-outline-danger" type="button">
                        <svg class="bi" width="18" height="18" fill="currentColor">
                          <use xlink:href="@/assets/icons/icons.svg#trash-fill"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="selectedObjective.criterion === 'KRT'">
                <div class="card">
                  <div class="card-header">{{ $t("views.objective.edit.btnKeyResults") }}<span class="text-danger">*</span></div>
                  <div class="card-body pt-0">
                    <div>
                      <button @click="addKeyResult()" type="button" class="btn btn-sm btn-outline-primary mt-3">+ {{ $t("views.objective.edit.btnAddKeyResult") }}</button>
                    </div>
                    <div v-for="keyresult, index in form.objective.keyresults.content" :key="'keyresult' + keyresult.id" class="row mt-3">
                      <div class="col-4">
                        <label for="name" class="form-label">
                          {{ $t("views.objective.edit.keyResultName") }}<span class="text-danger">*</span>
                        </label>
                        <input v-model="keyresult.name" id="keyresultName" class="form-control">
                      </div>
                      <div class="col-3">
                        <label for="name" class="form-label">
                          {{ $t("views.objective.keyresult.unit") }}<span class="text-danger">*</span>
                        </label>
                        <input v-model="keyresult.unit" id="keyresultUnit" class="form-control">
                      </div>
                      <div class="col-2">
                        <label for="name" class="form-label">
                          {{ $t("views.objective.keyresult.jop") }}<span class="text-danger">*</span>
                        </label>
                        <input v-model="keyresult.jop" type="number" id="keyresultJOP" class="form-control">
                      </div>
                      <div class="col-2">
                        <label for="name" class="form-label">
                          {{ $t("views.objective.edit.keyResultObjective") }}<span class="text-danger">*</span>
                        </label>
                        <input v-model="keyresult.obj" type="number" id="keyresultObjective" class="form-control">
                      </div>
                      <div class="col-1 text-end mt-auto">
                        <button @click="deleteKeyResult(index)" class="btn btn-outline-danger px-2" type="button">
                          <svg class="bi" width="16" height="16" fill="currentColor">
                            <use xlink:href="@/assets/icons/icons.svg#trash-fill"/>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="elementType === 'record'" class="form-link">
              <div class="h4 mb-3">{{ getKeyResultName() }}</div>
              <div class="mb-3">
                <label for="recordDate" class="form-label">{{ $t("views.objective.keyresult.date") }}<span class="text-danger">*</span></label>
                <input v-model="form.record.date.content" type="date" id="recordDate" :class="form.record.date.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.record.date.error }}</div>
              </div>
              <div class="mb-3">
                <label for="recordActual" class="form-label">{{ $t("views.objective.keyresult.actual") }}<span class="text-danger">*</span></label>
                <input v-model="form.record.actual.content" type="number" id="recordActual" :class="form.record.actual.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.record.actual.error }}</div>
              </div>
              <div class="mb-3">
                <label for="recordForecast" class="form-label">{{ $t("views.objective.keyresult.forecast") }}<span class="text-danger">*</span></label>
                <input v-model="form.record.forecast.content" type="number" id="recordForecast" :class="form.record.forecast.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.record.forecast.error }}</div>
              </div>
              <div class="mb-3">
                <label for="reviewComment" class="form-label">{{ $t("views.objective.keyresult.comment") }}</label>
                <RTEditor v-model:content="form.record.comment.content" contentType="html" id="reviewComment"></RTEditor>
                <div class="quill-invalid-feedback">{{ form.record.comment.error }}</div>
              </div>
            </div>
            <div v-if="elementType === 'review'" class="form-link">
              <div class="h4 mb-3">{{ selectedObjective.name }}</div>
              <div class="mb-3">
                <label for="reviewDate" class="form-label">{{ $t("views.objective.detail.reviewDate") }}<span class="text-danger">*</span></label>
                <input v-model="form.review.date.content" type="date" id="reviewDate" :class="form.review.date.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.review.date.error }}</div>
              </div>
              <div class="mb-3">
                <label for="reviewAssessment" class="form-label">{{ $t("views.objective.detail.reviewAssessment") }}<span class="text-danger">*</span></label>
                <RTEditor v-model:content="form.review.assessment.content" contentType="html" id="reviewAssessment"></RTEditor>
                <div class="quill-invalid-feedback">{{ form.review.assessment.error }}</div>
              </div>
              <div class="mb-3">
                <label for="reviewConversion" class="form-label">{{ $t("views.objective.detail.reviewConversion") }}<span class="text-danger">*</span></label>
                <select v-model="form.review.conversion.content" id="reviewConversion" :class="form.review.conversion.error === '' ? 'form-select' : 'form-select is-invalid'">
                  <option value="0">0%</option>
                  <option value="1">25%</option>
                  <option value="2">50%</option>
                  <option value="3">75%</option>
                  <option value="4">100%</option>
                </select>
                <div class="invalid-feedback">{{ form.review.conversion.error }}</div>
              </div>
              <div class="mb-3">
                <label for="reviewPrediction" class="form-label">{{ $t("views.objective.detail.reviewPrediction") }}<span class="text-danger">*</span></label>
                <select v-model="form.review.prediction.content" id="reviewPrediction" :class="form.review.prediction.error === '' ? 'form-select' : 'form-select is-invalid'">
                  <option value="Y">{{ $t("views.objective.detail.review.prediction.y") }}</option>
                  <option value="D">{{ $t("views.objective.detail.review.prediction.d") }}</option>
                  <option value="N">{{ $t("views.objective.detail.review.prediction.n") }}</option>
                </select>
                <div class="invalid-feedback">{{ form.review.prediction.error }}</div>
              </div>
            </div>
            <div v-if="elementType === 'measure'" class="form-link">
              <div class="mb-3">
                <label for="measureTitle" class="form-label">{{ $t("views.objective.detail.measureTitle") }}<span class="text-danger">*</span></label>
                <input v-model="form.measure.title.content" id="measureTitle" :class="form.measure.title.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.measure.title.error }}</div>
              </div>
              <div class="mb-3">
                <label for="measureDescription" class="form-label">{{ $t("views.objective.detail.measureDescription") }}<span class="text-danger">*</span></label>
                <RTEditor v-model:content="form.measure.measure.content" contentType="html" id="measureDescription"></RTEditor>
                <div class="quill-invalid-feedback">{{ form.measure.measure.error }}</div>
              </div>
              <div class="mb-3">
                <label for="measureResponsible" class="form-label">{{ $t("views.objective.detail.measureResponsible") }}<span class="text-danger">*</span></label>
                <select v-model="form.measure.responsible.content" id="measureResponsible" :class="form.measure.responsible.error === '' ? 'form-select' : 'form-select is-invalid'">
                  <option v-for="employee in employees" :key="employee.id" :value="employee.id">
                    {{ employee.lastName }}, {{ employee.firstName }}
                  </option>
                </select>
                <div class="invalid-feedback">{{ form.measure.responsible.error }}</div>
              </div>
              <div class="mb-3">
                <label for="measureConversion" class="form-label">{{ $t("views.objective.detail.measureConversion") }}</label>
                <select v-model="form.measure.conversion.content" id="measureConversion" :class="form.measure.conversion.error === '' ? 'form-select' : 'form-select is-invalid'">
                  <option value="0">0%</option>
                  <option value="1">25%</option>
                  <option value="2">50%</option>
                  <option value="3">75%</option>
                  <option value="4">100%</option>
                </select>
                <div class="invalid-feedback">{{ form.measure.conversion.error }}</div>
              </div>
              <div class="mb-3">
                <label for="measureValidFrom" class="form-label">{{ $t("views.objective.detail.measureValidFrom") }}<span class="text-danger">*</span></label>
                <input v-model="form.measure.validFrom.content" type="date" id="measureValidFrom" :class="form.measure.validFrom.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.measure.validFrom.error }}</div>
              </div>
              <div class="mb-3">
                <label for="measureValidTo" class="form-label">{{ $t("views.objective.detail.measureValidTo") }}<span class="text-danger">*</span></label>
                <input v-model="form.measure.validTo.content" type="date" id="measureValidTo" :class="form.measure.validTo.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.measure.validTo.error }}</div>
              </div>
            </div>
            <div v-if="elementType === 'conflict'" class="form-link">
              <div class="mb-3">
                <label for="conflictDescription" class="form-label">{{ $t("views.objective.detail.conflictDescription") }}<span class="text-danger">*</span></label>
                <RTEditor v-model:content="form.conflict.description.content" contentType="html" id="conflictDescription"></RTEditor>
                <div class="quill-invalid-feedback">{{ form.conflict.description.error }}</div>
              </div>
              <div class="mb-3">
                <input v-model="form.conflict.status.content" class="form-check-input" type="checkbox" id="conflictStatus">
                <label for="conflictStatus" class="form-check-label ms-2">{{ $t("views.objective.detail.conflictStatus") }}</label>
              </div>
            </div>
            <div v-if="elementType === 'trial'" class="form-link">
              <div class="mb-3">
                <label for="trialDescription" class="form-label">{{ $t("views.objective.detail.trialDescription") }}<span class="text-danger">*</span></label>
                <input v-model="form.trial.description.content" id="trialDescription" :class="form.trial.description.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.trial.description.error }}</div>
              </div>
              <div class="mb-3">
                <input v-model="form.trial.priority.content" id="trialPriority" class="form-check-input" type="checkbox">
                <label for="trialPriority" class="form-check-label ms-2">{{ $t("views.objective.detail.trialPriority") }}</label>
              </div>
            </div>
          </div>
          <div class="modal-footer bg-light justify-content-between">
            <div>
              <button v-if="elementType === 'objective'" type="button" class="btn btn-sm btn-outline-danger" :class="selectedObjective.hasNodes ? 'disabled' : ''" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#objectiveDeleteModal">Löschen</button>
            </div>
            <div>
              <button type="button" class="btn btn-sm btn-outline-secondary me-2" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
              <button @click="save()" type="button" class="btn btn-sm btn-primary">{{ $t("general.save") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as validate from '../../composables/validation'
import RTEditor from '../RTEditor.vue'
import { Modal } from 'bootstrap'
import { ref } from 'vue'

export default {
  name: 'ObjectiveEditModal',
  data () {
    return {
      kpiSelected: { id: 0, name: 'Auswählen...' },
      vocabulary: {
        objective: 'Ziel',
        record: 'Key Result Werte',
        review: 'Review',
        measure: 'Maßnahme',
        conflict: 'Konflikt',
        trial: 'Lösungsansatz',
        note: 'Notiz',
        link: 'Link',
        add: 'hinzufügen',
        update: 'bearbeiten'
      },
      form: {
        objective: {
          orgUnit: {
            content: '',
            error: '',
            validate: ['empty']
          },
          parentId: {
            content: '',
            error: '',
            validate: []
          },
          mentor: {
            content: '',
            error: '',
            validate: []
          },
          name: {
            content: '',
            error: '',
            maxLength: 255,
            validate: ['length', 'empty']
          },
          category: {
            content: '',
            error: '',
            validate: []
          },
          description: {
            content: '',
            error: '',
            validate: ['empty']
          },
          validFrom: {
            content: '',
            error: '',
            validate: ['empty']
          },
          validTo: {
            content: '',
            error: '',
            validate: ['empty']
          },
          kpis: {
            content: '',
            error: '',
            validate: []
          },
          keyresults: {
            content: '',
            error: '',
            validate: []
          }
        },
        record: {
          date: {
            content: '',
            error: '',
            validate: ['empty']
          },
          actual: {
            content: '',
            error: '',
            validate: ['empty']
          },
          forecast: {
            content: '',
            error: '',
            validate: ['empty']
          },
          comment: {
            content: '',
            error: '',
            validate: []
          }
        },
        review: {
          date: {
            content: '',
            error: '',
            validate: ['empty']
          },
          assessment: {
            content: '',
            error: '',
            validate: ['empty']
          },
          conversion: {
            content: '',
            error: '',
            validate: ['empty']
          },
          prediction: {
            content: '',
            error: '',
            validate: ['empty']
          }
        },
        measure: {
          title: {
            content: '',
            error: '',
            maxLength: 255,
            validate: ['length', 'empty']
          },
          measure: {
            content: '',
            error: '',
            validate: ['empty']
          },
          responsible: {
            content: '',
            error: '',
            validate: ['empty']
          },
          conversion: {
            content: '',
            error: '',
            validate: []
          },
          validFrom: {
            content: '',
            error: '',
            validate: ['empty']
          },
          validTo: {
            content: '',
            error: '',
            validate: ['empty']
          }
        },
        conflict: {
          description: {
            content: '',
            error: '',
            validate: ['empty']
          },
          status: {
            content: '',
            error: '',
            validate: []
          }
        },
        trial: {
          description: {
            content: '',
            error: '',
            maxLength: 1000,
            validate: ['length', 'empty']
          },
          priority: {
            content: '',
            error: '',
            validate: []
          }
        }
      }
    }
  },
  components: {
    RTEditor
  },
  computed: {
    selectedObjective () {
      return this.$store.state.selectedObjective
    },
    kpisList () {
      return this.$store.state.kpisList
    },
    objectivesList () {
      return this.$store.state.objectivesList
    },
    orgUnitsList () {
      return this.$store.state.orgUnitsList
    },
    employees () {
      return this.$store.state.employees
    },
    elementId () {
      return this.$parent.objectiveModalElementId
    },
    elementType () {
      return this.$parent.objectiveModalElementType
    },
    mode () {
      return this.$parent.objectiveModalMode
    }
  },
  methods: {
    addKPI () {
      if (!this.form.objective.kpis.content.find(kpi => kpi.id === this.kpiSelected.id)) {
        this.kpiSelected.new = true
        this.form.objective.kpis.content.push(this.kpiSelected)
      }
      this.kpiSelected = { id: 0, name: 'Auswählen...' }
    },
    deleteKPI (index) {
      this.form.objective.kpis.content.splice(index, 1)
    },
    getKeyResultName () {
      return this.selectedObjective.keyresults.filter(keyresult => {
        return keyresult.id === this.$parent.objectiveModalKeyResultId
      })[0].name
    },
    addKeyResult () {
      this.form.objective.keyresults.content.push({
        name: '',
        unit: '',
        jop: '',
        obj: '',
        records: [],
        selectedRecord: 0,
        new: true
      })
    },
    deleteKeyResult (index) {
      this.form.objective.keyresults.content.splice(index, 1)
    },
    async save () {
      this.$store.commit('SET_LOADING_STATUS', true)

      let valid = 0

      Object.keys(this.form[this.elementType]).forEach(field => {
        this.form[this.elementType][field].validate.forEach(validation => {
          const validationResult = validate.useValidation(this.form[this.elementType], field, validation)
          if (!validationResult.status) {
            this.form[this.elementType][field].error = validationResult.content
            valid++
          }
        })
      })

      if (valid === 0) {
        const elementData = Object.assign(
          ...Object.entries(this.form[this.elementType]).map(([key, value]) => ({ [key]: value.content }))
        )
        elementData.id = this.elementId
        let dispatchFunc = ''
        if (this.elementType !== 'objective') {
          if (this.elementType === 'record') {
            elementData.elementType = 'keyresults/' + this.$parent.objectiveModalKeyResultId + '/records'
          } else {
            elementData.elementType = (this.elementType + 's')
          }
          elementData.objectiveId = this.selectedObjective.id
        }
        dispatchFunc = this.elementType === 'objective' ? (this.mode + 'Objective') : (this.mode + 'ObjectiveElement')
        await this.$store.dispatch(dispatchFunc, elementData).then(() => {
          this.closeModal()
          Object.keys(this.form[this.elementType]).forEach(field => {
            if (this.mode === 'add') {
              this.form[this.elementType][field].content = ''
            }
            this.form[this.elementType][field].error = ''
          })
        })
      }

      this.$store.commit('SET_LOADING_STATUS', false)

      if (this.elementType === 'objective') {
        this.updateModalContent()
      }
      await this.$store.dispatch('getObjectives', { validFrom: this.$parent.$parent.filterValidFrom, validTo: this.$parent.$parent.filterValidTo, treeView: this.$parent.$parent.objectivesTreeView })
    },
    updateModalContent () {
      Object.keys(this.form[this.elementType]).forEach(field => {
        let selectedElement = this.selectedObjective
        if (['objective', 'record'].indexOf(this.elementType) === -1) {
          const elementTypePlural = this.elementType + 's'
          selectedElement = this.selectedObjective[elementTypePlural].find(element => {
            return element.id === this.$parent.objectiveModalElementId
          })
          this.$parent.selectedElement = selectedElement
        } else if (this.elementType === 'record') {
          const selectedKeyResult = this.selectedObjective.keyresults.find(keyresult => {
            return keyresult.id === this.$parent.objectiveModalKeyResultId
          })
          selectedElement = selectedKeyResult.records.find(record => {
            return record.id === this.$parent.objectiveModalElementId
          })
          this.$parent.selectedElement = selectedElement
        }
        if (field in selectedElement) {
          if (field === 'responsible' || field === 'mentor') {
            this.form[this.elementType][field].content = selectedElement[field].id
          } else {
            this.form[this.elementType][field].content = selectedElement[field]
          }
          this.form[this.elementType][field].error = ''
        }
      })
    }
  },
  watch: {
    elementId () {
      this.updateModalContent()
    },
    mode (newMode) {
      if (newMode === 'add') {
        Object.keys(this.form[this.elementType]).forEach(field => {
          if (['status', 'priority'].includes(field)) {
            this.form[this.elementType][field].content = false
          } else if (this.elementType === 'review' && field === 'date') {
            this.form[this.elementType][field].content = new Date().toISOString().substring(0, 10)
          } else if (this.elementType === 'record' && field === 'date') {
            this.form[this.elementType][field].content = new Date().toISOString().substring(0, 10)
          } else {
            this.form[this.elementType][field].content = this.form[this.elementType][field].error = ''
          }
        })
      } else if (newMode === 'update') {
        this.updateModalContent()
      }
    }
  },
  setup () {
    const objectiveEditModal = ref(null)
    const closeModal = () => Modal.getInstance(objectiveEditModal.value)?.hide()
    return { objectiveEditModal, closeModal }
  }
}
</script>
