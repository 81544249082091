<template>
  <div class="container">
    <div class="row py-5 justify-content-center">
      <div class="col-12 col-lg-6 py-5">
        <div class="row border-bottom border-2">
          <div class="col-6 my-auto">
            {{ $t("views.account.detail.name") }}
          </div>
          <div class="col-6 text-end">
            <a @click="openModal('name')" href="#accountEditModal" class="btn btn-sm btn-outline-dark mb-1" data-bs-toggle="modal">&bull; {{ $t("general.update") }}</a>
          </div>
        </div>
        <div class="row">
          <div class="col-12 fs-4">
            {{ user.lastName }}, {{ user.firstName }}
          </div>
        </div>
        <div class="row border-bottom border-2 mt-5">
          <div class="col-6 my-auto">
            {{ $t("views.account.detail.email") }}
          </div>
          <div class="col-6 text-end">
            <a @click="openModal('email')" href="#accountEditModal" class="btn btn-sm btn-outline-dark mb-1" data-bs-toggle="modal">&bull; {{ $t("general.update") }}</a>
          </div>
        </div>
        <div class="row">
          <div class="col-12 fs-4">
            {{ user.email }}
          </div>
        </div>
        <div class="row border-bottom border-2 mt-5">
          <div class="col-6 my-auto">
            {{ $t("views.account.detail.language") }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 fs-4 mt-2">
            <button @click="setLocale('de-DE')" class="btn btn-sm me-2 mb-1" :class="$i18n.locale === 'de-DE' ? 'btn-dark' : 'btn-outline-dark'">Deutsch (de-DE)</button>
            <button @click="setLocale('en-GB')" class="btn btn-sm me-2 mb-1" :class="$i18n.locale === 'en-GB' ? 'btn-dark' : 'btn-outline-dark'">English (en-GB)</button>
            <button @click="setLocale('en-US')" class="btn btn-sm me-2 mb-1" :class="$i18n.locale === 'en-US' ? 'btn-dark' : 'btn-outline-dark'">English (en-US)</button>
            <button @click="setLocale('fr-FR')" class="btn btn-sm me-2 mb-1" :class="$i18n.locale === 'fr-FR' ? 'btn-dark' : 'btn-outline-dark'">Français (fr-FR)</button>
          </div>
        </div>
        <div class="row border-bottom border-2 mt-5">
          <div class="col my-auto">
            Account löschen
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="mt-3">Falls Sie Ihren Account löschen möchten, schicken Sie uns bitte eine E-Mail an
              <a href="mailto:support@focusmt.de">support@focusmt.de</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
    <AccountEditModal></AccountEditModal>
  </div>
</template>

<script>
import AccountEditModal from '../../components/account/AccountEditModal.vue'

export default {
  name: 'ManageUser',
  data () {
    return {
      userModalElementType: ''
    }
  },
  components: {
    AccountEditModal
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    openModal (elementType) {
      this.userModalElementType = elementType
    },
    async setLocale (locale) {
      this.$store.commit('SET_LOADING_STATUS', true)

      await this.$store.dispatch('updateUserDetails', { settings: { language: locale } }).then(() => {
        this.$i18n.locale = locale
      })

      this.$store.commit('SET_LOADING_STATUS', false)
    }
  }
}
</script>
