export function useValidation (formObject, field, validation) {
  const reEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const reSpecialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
  const content = formObject[field].content

  switch (validation) {
    case 'empty':
      if (content.toString().trim() !== '') {
        return { content: '', status: true }
      } else {
        return { content: 'Dieses Feld darf nicht leer sein.', status: false }
      }

    case 'length':
      if (content.toString().length <= formObject[field].maxLength) {
        return { content: '', status: true }
      } else {
        return { content: 'Dieses Feld enthält zu viele Zeichen.', status: false }
      }

    case 'email':
      if (reEmail.test(content.toString().trim())) {
        return { content: '', status: true }
      } else {
        return { content: 'Das ist keine gültige E-Mail-Adresse.', status: false }
      }

    case 'emailConfirmation':
      if (formObject.email.content.toString().trim() === '' || content.toString().trim() === formObject.email.content.toString().trim()) {
        return { content: '', status: true }
      } else {
        return { content: 'Die E-Mail-Adressen stimmen nicht überein.', status: false }
      }

    case 'password':
      if (content.trim().length >= 8 && /\d/.test(content) && /[a-zA-Z]/.test(content) && reSpecialChars.test(content)) {
        return { content: '', status: true }
      } else {
        return { content: 'Das Passwort muss aus mind. 8 Zeichen bestehen und Buchstaben, Zahlen und Sonderzeichen beinhalten.', status: false }
      }

    case 'passwordConfirmation':
      if (formObject.password.content === formObject.passwordConfirmation.content) {
        return { content: '', status: true }
      } else {
        return { content: 'Die Passwörter sind nicht identisch.', status: false }
      }
  }
}
