<template>
  <div>
    <div>
      <div class="bg-custom-blue">
        <div class="container">
          <div class="row justify-content-between py-2">
            <div class="col-1">
              <select v-model="selectedYear" @change="getKPIsRecord()" class="form-select">
                <option v-for="(n, i) in 85" :key="'selectedYear' + i" :value="(2015 + i)">{{ 2015 + i }}</option>
              </select>
            </div>
            <div class="col-5 d-flex">
              <select v-model="selectedEmployee" class="form-select me-3">
                <option value="">{{ $t("views.kpi.detail.allEmployees") }}</option>
                <option v-for="employee in employees" :key="'employee' + employee.trim()" :value="employee">{{ employee }}</option>
              </select>
              <input v-model="filterName" type="text" class="form-control" :placeholder='$t("general.placeholderSearch")'>
            </div>
          </div>
        </div>
      </div>
      <div v-if="filteredKPIs.length" class="container my-5">
        <div class="row">
          <div class="col-12">
            <div class="h4">
              {{ heading }}
              <DocsInfoLink color="dark" :target="kpiRecordMode === 'actual' ? 'manageKPIRecordActual' : kpiRecordMode === 'plan' ? 'manageKPIRecordPlan' : 'manageKPIRecordObjective'"></DocsInfoLink>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="bg-white rounded-2 p-2">
              <table class="table">
                <thead>
                  <tr class="border-dark">
                    <th scope="col">{{ $t("views.kpi.detail.kpi") }}</th>
                    <th scope="col">{{ $t("views.kpi.edit.responsible") }}</th>
                    <th scope="col">{{ $t("views.kpi.edit.unit") }}</th>
                    <th scope="col">{{ $t("views.kpi.edit.timeDim") }}</th>
                    <th scope="col">{{ $t("views.kpi.edit.aggregation") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="kpi in filteredKPIs" :key="kpi.id">
                    <th role="button" scope="row">
                      <a @click="openModal(kpi)" href="#" data-bs-toggle="modal" data-bs-target="#kpiRecordEditModal">{{ kpi.name }}</a>
                    </th>
                    <td>{{ kpi.responsible }}</td>
                    <td>{{ kpi.unit }}</td>
                    <td>{{ vocabulary.timeDimInput[kpi.timeDimInput] }}</td>
                    <td>{{ vocabulary.aggregation[kpi.aggregation] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!loadingStatus" class="text-center mt-5">
        <img src="@/assets/img/no_data.svg" class="img-fluid" width="250">
        <p class="h5 mt-3">{{ $t("views.kpi.detail.noKPIs") }}</p>
      </div>
      <KPIRecordEditModal :kpi="kpiModalElement"></KPIRecordEditModal>
    </div>
  </div>
</template>

<script>
import DocsInfoLink from '../../components/DocsInfoLink.vue'
import KPIRecordEditModal from '../../components/monitoring/KPIRecordEditModal.vue'

export default {
  name: 'MonitoringManageKPIRecord',
  data () {
    return {
      kpiModalElement: {
        id: false,
        timeDimInput: 'M',
        aggregation: 'S'
      },
      selectedYear: this.$store.state.user?.settings?.favorites?.kpiManageRecordSelectedYear ? this.$store.state.user.settings.favorites.kpiManageRecordSelectedYear : new Date().getFullYear(),
      selectedEmployee: '',
      filterName: '',
      vocabulary: {
        timeDimInput: {
          M: 'Monat',
          Q: 'Quartal',
          Y: 'Jahr'
        },
        aggregation: {
          S: 'Summenwertbildung',
          M: 'Mittelwertbildung',
          A: 'Endwertbildung'
        }
      }
    }
  },
  components: {
    DocsInfoLink,
    KPIRecordEditModal
  },
  computed: {
    kpis () {
      return this.$store.state.kpis
    },
    filteredKPIs () {
      if (this.kpis.length) {
        const filteredByFormula = this.kpiRecordMode !== 'objective' ? this.kpis.filter(kpi => { return !kpi.activeFormula }) : this.kpis

        const filteredByName = filteredByFormula.filter(kpi => {
          return kpi.name.toLowerCase().includes(this.filterName.toLowerCase())
        })

        if (this.selectedEmployee) {
          return filteredByName.filter(kpi => {
            return kpi.responsible === this.selectedEmployee
          })
        } else {
          return filteredByName
        }
      } else {
        return {}
      }
    },
    employees () {
      if (this.kpis.length) {
        const employees = []
        this.kpis.forEach(kpi => {
          if (!employees.includes(kpi.responsible) && kpi.responsible) {
            employees.push(kpi.responsible)
          }
        })
        return employees.sort()
      } else {
        return []
      }
    },
    kpiRecordMode () {
      return this.$store.state.kpiRecordMode
    },
    heading () {
      if (this.kpiRecordMode === 'actual') {
        return 'Ist- & Forecastwerte erfassen'
      } else if (this.kpiRecordMode === 'plan') {
        return 'Planwerte erfassen'
      } else {
        return 'Zielwerte erfassen'
      }
    },
    loadingStatus () {
      return this.$store.state.loadingStatus
    }
  },
  methods: {
    async getKPIsRecord () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getKPIsRecord', this.selectedYear)
      await this.$store.dispatch('favoriteObject', { isList: false, objectKey: 'kpiManageRecordSelectedYear', objectValue: this.selectedYear })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async openModal (kpi) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getKPIRecords', { kpiId: kpi.id, selectedYear: this.selectedYear }).then(() => {
        this.kpiModalElement = kpi
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  watch: {
    kpiRecordMode () {
      this.filterName = ''
    }
  },
  async created () {
    this.getKPIsRecord()
  }
}
</script>

<style scoped>
table tbody th {
  font-weight: normal;
}
</style>
