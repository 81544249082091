<template>
  <div>
    <div v-if="xMatrix" class="bg-custom-blue">
      <div class="container text-center py-2">
        <button @click="toggleView()" type="button" class="btn btn-sm btn-outline-primary">
          <span v-if="showXMatrix">Neue X-Matrix</span>
          <span v-else>{{ $t("general.back") }}</span>
        </button>
      </div>
    </div>
    <div v-if="xMatrix && showXMatrix">
      <div class="text-center my-5">
        <div class="h4">{{ orgUnitsList.find(orgUnit => orgUnit.id === selectedOrgUnit)?.name.replace(/- /gm, '').trim() }} &bull; {{ selectedYear }}</div>
      </div>
      <div id="x-matrix" class="my-5 mx-auto pb-5 d-flex justify-content-center">
        <!-- Annual Objectives -->
        <div v-for="aObjective, index in xMatrix.aObjectives" :key="'aObjective' + index" class="col-s mx-1">
          <div v-for="i in xMatrix.cObjectives.length" :key="'acObjective' + i" class="row-s">
            <div v-if="aObjective.cBullets.includes(i - 1)" class="bull"></div>
          </div>
          <div class="row-l">
            <div class="cell-text-rt">{{ aObjective.name }}</div>
          </div>
          <div v-for="i in xMatrix.bObjectives.length" :key="'abObjective' + i" class="row-s">
            <div v-if="aObjective.bBullets.includes(i - 1)" class="bull"></div>
          </div>
        </div>
        <div class="col-l mx-1">
          <!-- Contribution Objectives -->
          <div v-for="cObjective, index in xMatrix.cObjectives" :key="'cObjective' + index" class="row-s d-flex">
            <div class="m-auto">{{ cObjective }}</div>
          </div>
          <!-- Center Element -->
          <div class="row-l">
            <div id="cell-x" class="container h-100 p-5">
              <div class="row">
                <div class="col-12 text-center fw-bold">
                  <div class="mb-2">
                    <svg class="bi bg-primary rounded-3 p-2" width="40" height="40" fill="#fff">
                      <use xlink:href="@/assets/icons/icons.svg#plus-lg"/>
                    </svg>
                  </div>
                  Verbesserungsprios
                </div>
              </div>
              <div class="row">
                <div class="col-6 my-auto fw-bold">
                  <div class="mb-2">
                    <svg class="bi bg-primary rounded-3 p-2" width="40" height="40" fill="#fff">
                      <use xlink:href="@/assets/icons/icons.svg#list"/>
                    </svg>
                  </div>
                  Jährliche
                  <br>
                  Hoshin-Ziele
                </div>
                <div class="col-6 text-end my-auto fw-bold">
                  <div class="mb-2">
                    <svg class="bi bg-primary rounded-3 p-2" width="40" height="40" fill="#fff">
                      <use xlink:href="@/assets/icons/icons.svg#send-plus"/>
                    </svg>
                  </div>
                  Erfolgs-<br>indikatoren
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center mt-auto fw-bold">
                  <div class="mb-2">
                    <svg class="bi bg-primary rounded-3 p-2" width="40" height="40" fill="#fff">
                      <use xlink:href="@/assets/icons/icons.svg#radios"/>
                    </svg>
                  </div>
                  Durchbruchsziele
                </div>
              </div>
            </div>
          </div>
          <!-- Breakthrough Objectives -->
          <div v-for="bObjective, index in xMatrix.bObjectives" :key="'bObjective' + index" class="row-s d-flex">
            <div class="m-auto">{{ bObjective }}</div>
          </div>
        </div>
        <div v-for="cIndicator, index in xMatrix.cIndicators" :key="'cIndicator' + index" class="col-s mx-1">
          <div v-for="i in xMatrix.cObjectives.length" :key="'icObjective' + i" class="row-s">
            <div v-if="cIndicator.cBullets.includes(i - 1)" class="bull"></div>
          </div>
          <div class="row-l">
            <div class="cell-text-rt">{{ cIndicator.name }}</div>
          </div>
        </div>
        <div class="col-s mx-1" :class="!xMatrix.cIndicators?.length && !xMatrix.cEmployees?.length ? 'd-none' : ''">
          <div class="row-s bg-transparent shadow-none"></div>
          <div class="row-s bg-transparent shadow-none"></div>
          <div class="row-l bg-transparent shadow-none">
            <div class="cell-text-rt fs-5">RESSOURCEN</div>
          </div>
        </div>
        <div v-for="cEmployee, index in xMatrix.cEmployees" :key="'cEmployee' + index" class="col-s mx-1">
          <div v-for="i in xMatrix.cObjectives.length" :key="'ecObjective' + i" class="row-s">
            <div v-if="cEmployee.cmBullets.includes(i - 1)" class="bull"></div>
            <div v-else-if="cEmployee.csBullets.includes(i - 1)" class="bull bull-tp"></div>
          </div>
          <div class="row-l">
            <div class="cell-text-rt">{{ cEmployee.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row justify-content-center my-5">
        <div class="col-3">
          <div class="mb-3">
            <label for="selectedOrgUnit" class="form-label">{{ $t("views.objective.edit.orgUnit") }}<span class="text-danger">*</span></label>
            <select v-model="selectedOrgUnit" id="selectedOrgUnit" class="form-select">
              <option v-for="orgUnit in orgUnitsList" :key="orgUnit.id" :value="orgUnit.id">{{ orgUnit.name }}</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="selectedYear" class="form-label">{{ $t("general.year") }}<span class="text-danger">*</span></label>
            <select v-model="selectedYear" id="selectedYear" class="form-select">
              <option v-for="(n, i) in 85" :key="'selectedYear' + i" :value="(2015 + i)">{{ 2015 + i }}</option>
            </select>
          </div>
          <div class="mb-3">
            <button @click="getXMatrix()" type="button" class="btn btn-sm btn-primary">{{ $t("views.nav.viewXMatrix") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ObjectivesXMatrix',
  data () {
    return {
      showXMatrix: false,
      selectedOrgUnit: '',
      selectedYear: ''
    }
  },
  computed: {
    xMatrix () {
      return this.$store.state.xMatrix
    },
    orgUnitsList () {
      if ('nodes' in this.$store.state.orgUnits) {
        return this.$store.state.orgUnitsList
      } else {
        return []
      }
    }
  },
  methods: {
    toggleView () {
      this.showXMatrix = !this.showXMatrix
    },
    async getXMatrix () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getXMatrix', { orgUnit: this.selectedOrgUnit, year: this.selectedYear }).then(() => {
        this.showXMatrix = true
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  async created () {
    await this.$store.dispatch('getOrgUnits', { validFrom: '2015-01-01', validTo: '2099-12-31' })
  }
}
</script>

<style scoped>
#x-matrix {
  width: 90vw;
  .col-s {
    width: 3.375vw;
  }
  .col-l {
    width: 25vw;

    .row-s div {
      display: -webkit-box;
      overflow: hidden;
      text-align: center;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      padding: 0 1rem;
    }
  }
  .row-s {
    position: relative;
    height: 3.375vw;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    margin: 0.5rem 0;
  }
  .row-l {
    position: relative;
    height: 25vw;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    margin: 0.5rem 0;
  }
  .bull {
    position: absolute;
    width: 20%;
    height: 20%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000;
    border-radius: 50%;
    margin: auto;
  }
  .bull-tp {
    background-color: #fff;
    border: 2px solid #000;
  }
  #cell-x {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><line x1="10" y1="50" x2="90" y2="50" stroke="black" stroke-width="0.3" transform="rotate(45, 50, 50)" /><line x1="50" y1="10" x2="50" y2="90" stroke="black" stroke-width="0.3" transform="rotate(45, 50, 50)" /></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    .row {
      height: 33.33%;
    }
  }
  .cell-text-rt {
    display: -webkit-box;
    height: 100%;
    overflow: hidden;
    writing-mode: vertical-rl;
    rotate: 180deg;
    margin: auto;
    text-align: center;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 1rem 0;
  }
}
</style>
