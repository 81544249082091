import deDE from './de-DE.json'
import enGB from './en-GB.json'
import enUS from './en-US.json'
import frFR from './fr-FR.json'

export const languages = {
  en: enUS,
  'de-DE': deDE,
  'en-GB': enGB,
  'en-US': enUS,
  'fr-FR': frFR
}
