<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-3 sidebar-project bg-custom-blue py-3">
        <div class="row">
          <div class="col-12">
            <button @click="openModal(false, 'project', 'add')" type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#projectEditModal">+ {{ $t("general.add") }}</button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="dropdown">
              <button class="btn btn-sm btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span>{{ new Date(filterValidFrom).toLocaleDateString({dateStyle: 'short'}) }} &nbsp;-&nbsp; {{ new Date(filterValidTo).toLocaleDateString({dateStyle: 'short'}) }}</span>
              </button>
              <div class="dropdown-menu border-secondary p-3">
                <div class="row">
                  <div class="col-12">
                    <button @click="filterProjects(new Date().toISOString().slice(0, 10), new Date().toISOString().slice(0, 10))" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ $t("general.today") }}</button>
                    <button @click="filterProjects((new Date().getFullYear() + 1).toString() + '-01-01', (new Date().getFullYear() + 1).toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ new Date().getFullYear() + 1 }}</button>
                    <button @click="filterProjects(new Date().getFullYear().toString() + '-01-01', new Date().getFullYear().toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ new Date().getFullYear() }}</button>
                    <button @click="filterProjects((new Date().getFullYear() - 1).toString() + '-01-01', (new Date().getFullYear() - 1).toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ new Date().getFullYear() - 1 }}</button>
                  </div>
                </div>
                <hr class="dropdown-divider">
                <div class="row">
                  <div class="col-6">
                    <label for="filterValidFrom" class="form-label">{{ $t("views.kpi.periods.from") }}:</label>
                    <input v-model="filterValidFrom" type="date" class="form-control form-control-sm" id="filterValidFrom">
                  </div>
                  <div class="col-6">
                    <label for="filterValidTo" class="form-label">{{ $t("views.kpi.periods.to") }}:</label>
                    <input v-model="filterValidTo" type="date" class="form-control form-control-sm" id="filterValidTo">
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button @click="filterProjects(filterValidFrom, filterValidTo)" class="btn btn-sm btn-outline-primary mt-3" type="button">{{ $t("views.kpi.periods.filter") }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ProjectTree v-if="'nodes' in projects && projects.nodes.length" :node="projects" :depth="0" class="mt-3"></ProjectTree>
          </div>
        </div>
      </div>
      <div class="col-9 px-5 py-3">
        <div v-if="Object.keys(selectedProject).length" class="row">
          <div class="col-12">
            <div class="row">
              <div class="col">
                <button v-if="selectedProject.write" @click="openModal(selectedProject.id, 'project', 'update')" type="button" class="btn btn-sm btn-outline-dark" data-bs-toggle="modal" data-bs-target="#projectEditModal">&bull; {{ $t("general.update") }}</button>
              </div>
              <div class="col text-end">
                <DocsInfoLink color="dark" target="manageProjects"></DocsInfoLink>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="h3">{{ selectedProject.name }}</div>
                <div>
                  {{ $t("views.project.structure.validityPeriod") }}:
                  <span v-if="selectedProject.validFrom">
                    {{ new Date(selectedProject.validFrom).toLocaleDateString({dateStyle: 'short'}) }} {{ $t("general.until") }} {{ new Date(selectedProject.validTo).toLocaleDateString({dateStyle: 'short'}) }}
                  </span>
                  <span v-else class="fst-italic">
                    {{ $t("views.project.structure.infinite") }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <div class="card">
                  <div class="card-header fw-bold">{{ $t("views.project.detail.headingCard") }}</div>
                  <div class="card-body">
                    <ul class="nav nav-tabs border-bottom" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark active" id="definition-tab" data-bs-toggle="tab" data-bs-target="#definition" type="button" role="tab" aria-controls="definition" aria-selected="false">{{ $t("views.project.detail.tabDefinition") }}</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews" type="button" role="tab" aria-controls="reviews" aria-selected="false">{{ $t("views.project.detail.tabReviews") }} <span class="badge bg-dark">{{ selectedProject.reviews.length }}</span></button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark" id="measures-tab" data-bs-toggle="tab" data-bs-target="#measures" type="button" role="tab" aria-controls="measures" aria-selected="false">{{ $t("views.project.detail.tabMeasures") }} <span class="badge bg-dark">{{ selectedProject.measures.length }}</span></button>
                      </li>
                    </ul>
                    <div class="tab-content mt-2">
                      <div class="tab-pane fade show active" id="definition" role="tabpanel">
                        <div v-if="selectedProject.description" v-html="selectedProject.description" class="mt-3"></div>
                        <div v-else class="mt-3 h6">{{ $t("views.project.detail.noDescription") }}</div>
                      </div>
                      <div class="tab-pane fade" id="reviews" role="tabpanel">
                        <div v-if="selectedProject.write" class="row my-3">
                          <div class="col">
                            <button @click="openModal(false, 'review', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#projectEditModal">+ {{ $t("general.add") }}</button>
                          </div>
                        </div>
                        <div v-if="selectedProject.reviews.length">
                          <table class="table">
                            <thead>
                              <tr class="border-dark">
                                <th scope="col">{{ $t("views.objective.detail.reviewDate") }}</th>
                                <th scope="col">{{ $t("views.objective.detail.reviewAssessment") }}</th>
                                <th scope="col">{{ $t("views.objective.detail.reviewConversion") }}</th>
                                <th scope="col">{{ $t("views.objective.detail.reviewPrediction") }}</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="review in selectedProject.reviews" :key="'review' + review.id">
                                <th scope="row" class="fw-normal">{{ new Date(review.date).toLocaleDateString({dateStyle: 'short'}) }}</th>
                                <td v-html="review.assessment" class="w-50"></td>
                                <td>
                                  <div class="progress position-relative border bg-dark border-dark w-50">
                                    <span class="progress-label position-absolute start-50 top-50 text-light fw-bold">{{ 25 * review.conversion }}%</span>
                                    <div class="progress-bar progress-bar-striped bg-primary" :style="'width: ' + (25 * review.conversion) + '%'" role="progressbar" :aria-valuenow="(25 * review.conversion)" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                </td>
                                <td>
                                  <span class="badge fw-normal" :class="review.prediction === 'Y' ? 'text-bg-success' : review.prediction === 'D' ? 'text-bg-warning' : 'text-bg-danger'">
                                    {{ $t("views.project.detail.review.prediction." + review.prediction.toLowerCase()) }}
                                  </span>
                                </td>
                                <td v-if="selectedProject.write" class="text-end">
                                  <div class="dropdown">
                                    <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                      <svg class="bi" width="28" height="28" fill="currentColor">
                                        <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                      </svg>
                                    </a>
                                    <ul class="dropdown-menu">
                                      <li><a @click="openModal(review.id, 'review', 'update')" class="dropdown-item" href="#projectEditModal" data-bs-toggle="modal">{{ $t("general.update") }}</a></li>
                                      <li><a @click="openModal(review.id, 'review', 'delete')" class="dropdown-item" href="#projectDeleteModal" data-bs-toggle="modal">{{ $t("general.delete") }}</a></li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div v-else class="mt-3 h6">{{ $t("views.project.detail.noReviews") }}</div>
                      </div>
                      <div class="tab-pane fade" id="measures" role="tabpanel">
                        <div v-if="selectedProject.write" class="row my-3">
                          <div class="col">
                            <button @click="openModal(false, 'measure', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#projectEditModal">+ {{ $t("general.add") }}</button>
                          </div>
                        </div>
                        <div v-if="selectedProject.measures.length">
                          <table class="table">
                            <thead>
                              <tr class="border-dark">
                                <th scope="col">{{ $t("views.objective.detail.measureTitle") }}</th>
                                <th scope="col">{{ $t("views.objective.detail.measureResponsible") }}</th>
                                <th scope="col">{{ $t("views.objective.detail.measureConversion") }}</th>
                                <th scope="col">{{ $t("views.objective.detail.measureValidFrom") }}</th>
                                <th scope="col">{{ $t("views.objective.detail.measureValidTo") }}</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="measure in selectedProject.measures" :key="'measure' + measure.id">
                                <th scope="row" class="fw-normal">{{ measure.title }}</th>
                                <td>{{ measure.responsible.name }}</td>
                                <td>
                                  <div class="progress position-relative border bg-dark border-dark w-50">
                                    <span class="progress-label position-absolute start-50 top-50 text-light fw-bold">{{ 25 * measure.conversion }}%</span>
                                    <div class="progress-bar progress-bar-striped bg-primary" :style="'width: ' + (25 * measure.conversion) + '%'" role="progressbar" :aria-valuenow="(25 * measure.conversion)" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                </td>
                                <td>{{ new Date(measure.validFrom).toLocaleDateString({dateStyle: 'short'}) }}</td>
                                <td>{{ new Date(measure.validTo).toLocaleDateString({dateStyle: 'short'}) }}</td>
                                <td v-if="selectedProject.write" class="text-end">
                                  <div class="dropdown">
                                    <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                      <svg class="bi" width="28" height="28" fill="currentColor">
                                        <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                      </svg>
                                    </a>
                                    <ul class="dropdown-menu">
                                      <li><a @click="openModal(measure.id, 'measure', 'update')" class="dropdown-item" href="#projectEditModal" data-bs-toggle="modal">{{ $t("general.update") }}</a></li>
                                      <li><a @click="openModal(measure.id, 'measure', 'delete')" class="dropdown-item" href="#projectDeleteModal" data-bs-toggle="modal">{{ $t("general.delete") }}</a></li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div v-else class="mt-3 h6">{{ $t("views.project.detail.noMeasures") }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center mt-5">
          <img src="@/assets/img/no_data.svg" class="img-fluid" width="250">
          <p v-if="'nodes' in projects && projects.nodes.length" class="h5 mt-3">{{ $t("views.project.structure.helpChooseProject") }}</p>
          <p v-else class="h5 mt-3">{{ $t("views.project.structure.helpCreateProject") }}</p>
        </div>
        <ProjectEditModal></ProjectEditModal>
        <ProjectDeleteModal></ProjectDeleteModal>
      </div>
    </div>
  </div>
</template>

<script>
import DocsInfoLink from '../../components/DocsInfoLink.vue'
import ProjectTree from '../../components/projects/ProjectTree.vue'
import ProjectEditModal from '../../components/projects/ProjectEditModal.vue'
import ProjectDeleteModal from '../../components/projects/ProjectDeleteModal.vue'

export default {
  name: 'ProjectManageProject',
  data () {
    return {
      projectModalElementId: false,
      projectModalElementType: '',
      projectModalMode: '',
      selectedElement: '',
      filterValidFrom: this.$store.state.user?.settings?.favorites?.projectManage?.filterValidFrom ? this.$store.state.user.settings.favorites.projectManage.filterValidFrom : new Date().getFullYear().toString() + '-01-01',
      filterValidTo: this.$store.state.user?.settings?.favorites?.projectManage?.filterValidTo ? this.$store.state.user.settings.favorites.projectManage.filterValidTo : (new Date().getFullYear() + 1).toString() + '-12-31'
    }
  },
  components: {
    DocsInfoLink,
    ProjectTree,
    ProjectEditModal,
    ProjectDeleteModal
  },
  computed: {
    projects () {
      return this.$store.state.projects.constructor === Array ? [] : this.$store.state.projects
    },
    selectedProject () {
      return this.$store.state.selectedProject
    }
  },
  methods: {
    async filterProjects (filterFrom, filterTo) {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.filterValidFrom = filterFrom
      this.filterValidTo = filterTo
      await this.$store.dispatch('getProjects', { validFrom: filterFrom, validTo: filterTo })
      await this.$store.dispatch('favoriteObject', { isList: false, objectKey: 'projectManage', objectValue: { filterValidFrom: filterFrom, filterValidTo: filterTo } })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    openModal (id, elementType, mode) {
      if (id) {
        this.projectModalElementId = id
      }
      this.projectModalElementType = elementType
      this.projectModalMode = mode
    }
  },
  async created () {
    this.$store.commit('SET_LOADING_STATUS', true)
    await this.$store.dispatch('getProjects', { validFrom: this.filterValidFrom, validTo: this.filterValidTo })
    if (Object.keys(this.selectedProject).length) {
      await this.$store.dispatch('getProject', this.selectedProject.id)
    }
    await this.$store.dispatch('getEmployees', { mode: 'default' })
    this.$store.commit('SET_LOADING_STATUS', false)
  }
}
</script>

<style lang="scss" scoped>
.progress-label {
  transform: translate(-50%, -50%);
}
.tab-content .progress {
  margin-top: 6px;
}
</style>
