<template>
  <div class="objective-tree">
    <div class="py-2 border-bottom border-dark" :class="!depth ? 'd-none' : ''">
      <div :style="indent">
        <div class="d-inline-block" role="button">
          <div v-if="node.nodes.length && depth > 1" @click="toggleNodes">
            <svg class="bi" width="22" height="22" fill="currentColor">
              <use v-if="showNodes" xlink:href="@/assets/icons/icons.svg#dash"/>
              <use v-else xlink:href="@/assets/icons/icons.svg#plus"/>
            </svg>
          </div>
          <div v-else-if="depth > 1" class="tree-node-collapse-placeholder"></div>
        </div>
        <div class="cont-icon-objective d-inline-block me-1">
          <svg v-if="node.org" class="bi" width="20" height="20" fill="currentColor">
            <use xlink:href="@/assets/icons/icons.svg#org-unit"/>
          </svg>
          <svg v-else class="bi" width="20" height="20">
            <use :xlink:href="require('@/assets/icons/icons.svg') + '#harvey-' + node.conversion" :fill="getHarveyColor(node.prediction)" :stroke="getHarveyColor(node.prediction)"/>
          </svg>
        </div>
        <div class="d-inline-block tree-node-name">
          <span v-if="node.org">{{ node.name.replace(/- /gm, '').trim() }}</span>
          <span v-else @click="selectObjective(node)" class="objective-name" :class="selectedObjective.id === node.id ? 'fw-bold' : ''" role="button">
            {{ node.category ? node.name.replace(/- /gm, '').trim().toUpperCase() : node.name.replace(/- /gm, '').trim() }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="showNodes || depth < 2">
      <ObjectiveTree v-for="node in node.nodes" :key="node.id" :node="node" :depth="depth + 1"></ObjectiveTree>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ObjectiveTree',
  props: ['node', 'depth', 'view'],
  data () {
    return {
      showNodes: false
    }
  },
  computed: {
    indent () {
      if (this.node.nodes && this.depth) {
        return { paddingLeft: `${(this.depth - 1) * 22}px` }
      } else {
        return { paddingLeft: `${this.depth * 22}px` }
      }
    },
    selectedObjective () {
      return this.$store.state.selectedObjective
    }
  },
  methods: {
    async selectObjective (node) {
      this.$store.commit('SET_LOADING_STATUS', true)

      await this.$store.dispatch('getObjective', node.id).then(() => {
        this.$parent.objectiveModalElementId = node.id
        this.$store.dispatch('updateObjectivesContentView', 'detail')
      })

      this.$store.commit('SET_LOADING_STATUS', false)
      window.scrollTo(0, 0)
    },
    toggleNodes () {
      this.showNodes = !this.showNodes
    },
    getHarveyColor (prediction) {
      return prediction === 'Y' ? '#198754' : prediction === 'D' ? '#ebad20' : '#dc3545'
    }
  }
}
</script>

<style lang="scss" scoped>
.objective-tree {
  .cont-icon-objective svg {
    margin-top: -3px;
  }
  .objective-name:hover {
    font-weight: 500;
  }
}
</style>
