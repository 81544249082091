<template>
  <div>
    <TopNavigation></TopNavigation>
    <TenantNotActivated></TenantNotActivated>
    <ToastAlert></ToastAlert>
    <LoadingSpinner></LoadingSpinner>
    <main :class="isLoading ? 'bg-light opacity-25' : ''">
      <router-view/>
    </main>
  </div>
</template>

<script>
import TopNavigation from './components/TopNavigation.vue'
import TenantNotActivated from './components/TenantNotActivated.vue'
import ToastAlert from './components/ToastAlert.vue'
import LoadingSpinner from './components/LoadingSpinner.vue'

export default {
  name: 'App',
  components: {
    TopNavigation,
    TenantNotActivated,
    ToastAlert,
    LoadingSpinner
  },
  computed: {
    isLoading () {
      return this.$store.state.loadingStatus
    }
  }
}
</script>

<style>
a {
  text-decoration: none !important;
}
.bg-custom-blue {
  background-color: rgb(0 87 177 / 11%);
}
.sidebar-objectives, .sidebar-monitoring, .sidebar-org, .sidebar-project  {
  min-height: 100vh !important;
}
.modal-dialog {
  max-width: 40vw !important;
}
.nav-tabs {
  --bs-nav-tabs-link-active-border-color: #212529 #212529 #fff !important;
}
.tree-node-name {
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: -6px;
}
.tree-node-collapse-placeholder {
  width: 22px;
}
.tab-content p, #orgUnitDesc p {
  margin-bottom: 0;
}
</style>
