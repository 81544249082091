<template>
  <div v-if="alert" class="toast-container position-absolute top-0 end-0 mt-5 py-3 px-2">
    <div class="toast show">
      <div class="toast-header" :class="alert.category === 'Error' ? 'bg-danger' : 'bg-primary'">
        <strong class="text-light me-auto">{{ alert.category }}</strong>
        <button @click="deleteAlert()" type="button" class="btn-close btn-close-white" aria-label="Close"></button>
      </div>
      <div class="toast-body bg-light rounded-2">
        {{ alert.message }}
        <div id="bar-deletion" class="bg-dark rounded-2 mt-2" :class="barAnimation"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToastAlert',
  data () {
    return {
      barAnimation: ''
    }
  },
  computed: {
    alert () {
      return this.$store.state.alert
    }
  },
  methods: {
    deleteAlert () {
      this.$store.commit('DELETE_ALERT')
    }
  },
  watch: {
    alert () {
      this.barAnimation = 'bar-animation'
    }
  }
}
</script>

<style scoped>
.toast-container {
  z-index: 9999 !important;
}
#bar-deletion {
  width: 0%;
  height: 3px;
}
.bar-animation {
  animation: progressAnimation 3.5s;
}
@keyframes progressAnimation {
  from { width: 100% }
  to   { width: 0% }
}
</style>
