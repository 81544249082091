<template>
  <div class="card kpi-detail mb-4 h-100">
    <div class="card-header fw-bold text-truncate">
      <div class="row">
        <div class="col-12">
          {{ kpi.name }}
          <span class="fw-normal">&bull; {{ unitDisplay }}</span>
          <span class="d-flex float-end">
            <svg @click=favoriteKPI(kpi.id) role="button" class="btn-favorite bi me-3" fill="currentColor">
              <use v-if="favoriteKPIs?.includes(kpi.id)" xlink:href="@/assets/icons/icons.svg#star-fill"/>
              <use v-else xlink:href="@/assets/icons/icons.svg#star"/>
            </svg>
            <span @click=deselectKPI(kpi.id) role="button" class="btn-close small opacity-100"></span>
          </span>
        </div>
      </div>
    </div>
    <div class="card-body pt-2 pb-0">
      <div class="row justify-content-center mb-4">
        <div class="col-3">
          <label for="filterTimeDim" class="form-label small mb-0">{{ $t("views.kpi.periods.timeDim") }}:</label>
          <select @change="updateFilterFromTo()" v-model="filterTimeDim" id="filterTimeDim" class="form-select form-select-sm">
            <option v-if="kpi.timeDimInput === 'M'" value="M">{{ $t("general.month") }}</option>
            <option v-if="kpi.timeDimInput !== 'Y'" value="Q">{{ $t("general.quarter") }}</option>
            <option value="Y">{{ $t("general.year") }}</option>
          </select>
        </div>
        <div class="col-3">
          <label for="filterFrom" class="form-label small mb-0">{{ $t("views.kpi.periods.from") }}:</label>
          <select v-model="filterFrom" id="filterFrom" class="form-select form-select-sm">
            <option v-for="option in filterOptions" :key="'filterFrom' + option" :value="option">{{ formatPeriod(option) }}</option>
          </select>
        </div>
        <div class="col-3">
          <label for="filterTo" class="form-label small mb-0">{{ $t("views.kpi.periods.to") }}:</label>
          <select v-model="filterTo" id="filterTo" class="form-select form-select-sm">
            <option v-for="option in filterOptions" :key="'filterTo' + option" :value="option">{{ formatPeriod(option) }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <KPIPeriodMultiChart :key="'kpiPeriods' + kpiKeyInt + kpi.id" :chartId="'chartMulti' + kpi.id" :periods="filteredPeriods" class="kpi-chart"></KPIPeriodMultiChart>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="table-kpi-data mt-3">
            <table class="table table-bordered table-striped mb-0">
              <thead>
                <tr>
                  <th scope="col">{{ $t("views.kpi.periods.period") }}</th>
                  <th scope="col">{{ $t("views.kpi.edit.actual") }}</th>
                  <th scope="col">{{ $t("views.kpi.edit.plan") }}</th>
                  <th scope="col">{{ $t("views.kpi.edit.objective") }}</th>
                  <th scope="col">{{ $t("views.kpi.edit.forecast") }}</th>
                  <th scope="col">{{ $t("views.kpi.edit.benchmark") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="period in filteredPeriodsReverse" :key="kpi.id + period" class="text-end">
                  <th scope="row" class="fw-normal">{{ formatPeriod(period.period) }}</th>
                  <td>{{ period.actual ? period.actual.toLocaleString('de-DE') : '-' }}</td>
                  <td>{{ period.plan ? period.plan.toLocaleString('de-DE') : '-' }}</td>
                  <td>{{ period.objective ? period.objective.toLocaleString('de-DE') : '-' }}</td>
                  <td>{{ period.forecast ? period.forecast.toLocaleString('de-DE') : '-' }}</td>
                  <td>{{ period.benchmark ? period.benchmark.toLocaleString('de-DE') : '-' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as periodUtils from '../../composables/period'
import KPIPeriodMultiChart from '../charts/KPIPeriodMulti.vue'

export default {
  name: 'KPIPeriods',
  props: ['kpi'],
  data () {
    return {
      kpiKeyInt: 0,
      filterTimeDim: this.kpi.timeDimDisplay,
      filterFrom: '',
      filterTo: '',
      filterOptions: ''
    }
  },
  components: {
    KPIPeriodMultiChart
  },
  computed: {
    favoriteKPIs () {
      return this.$store.state.user?.settings?.favorites?.kpis
    },
    unitDisplay () {
      const unit = this.$store.state.kpiUnits.find(unit => {
        return unit.id === this.kpi.unit
      })
      if (unit) {
        return unit.name
      } else {
        return 'n/a'
      }
    },
    filteredPeriods () {
      return this.kpi.records.filter(record => {
        return record.period.toString().length === this.filterFrom.toString().length &&
               record.period >= this.filterFrom && record.period <= this.filterTo
      })
    },
    filteredPeriodsReverse () {
      return this.kpi.records.filter(record => {
        return record.period.toString().length === this.filterFrom.toString().length &&
               record.period >= this.filterFrom && record.period <= this.filterTo
      }).reverse()
    }
  },
  methods: {
    updateFilterFromTo () {
      let filterStartYear = ''
      let filterEndYear = ''
      if (this.$parent.filterValidFrom && this.$parent.filterValidTo) {
        filterStartYear = this.$parent.filterValidFrom.substr(0, 4)
        filterEndYear = this.$parent.filterValidTo.substr(0, 4)
      } else {
        filterStartYear = filterEndYear = new Date().getFullYear()
      }

      if (this.filterTimeDim === 'M') {
        this.filterFrom = filterStartYear + '01'
        this.filterTo = filterEndYear + '12'
      } else if (this.filterTimeDim === 'Q') {
        this.filterFrom = filterStartYear + '1'
        this.filterTo = filterEndYear + '4'
      } else {
        this.filterFrom = (Number(filterStartYear) - 2).toString()
        this.filterTo = (Number(filterEndYear) + 2).toString()
      }
      this.kpiKeyInt++
    },
    updateFilterOptions () {
      let yearStart = 2000
      const filterOptions = []
      let periodsFilterUnits = []
      if (this.filterTimeDim === 'M') {
        periodsFilterUnits = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
      } else if (this.filterTimeDim === 'Q') {
        periodsFilterUnits = ['1', '2', '3', '4']
      } else {
        periodsFilterUnits = ['']
      }
      while (yearStart <= 2099) {
        periodsFilterUnits.forEach(unit => {
          const curPeriod = yearStart.toString() + unit
          const foundRecord = this.kpi.records?.find(record => {
            return record.period.substr(0, 4) === yearStart.toString()
          })
          if (foundRecord) {
            filterOptions.push(curPeriod)
          }
        })
        yearStart++
      }
      this.filterOptions = filterOptions
      this.kpiKeyInt++
    },
    async favoriteKPI (kpiId) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('favoriteObject', { isList: true, objectKey: 'kpis', objectValue: kpiId })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    deselectKPI (kpiId) {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('deleteSelectedKPI', { kpiId })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    formatPeriod (period) {
      return periodUtils.formatPeriod(period)
    }
  },
  watch: {
    kpi () {
      this.updateFilterFromTo()
    },
    filterTimeDim () {
      this.updateFilterOptions()
      this.updateFilterFromTo()
    },
    filterFrom () {
      this.kpiKeyInt++
    },
    filterTo () {
      this.kpiKeyInt++
    }
  },
  created () {
    this.updateFilterOptions()
    this.updateFilterFromTo()
  }
}
</script>

<style scoped>
.kpi-chart {
  max-height: 150px;
}
.table-kpi-data {
  max-height: 200px;
  overflow: scroll;
}
.btn-favorite, .btn-close {
  width: 19px;
  height: 19px;
  margin-top: 3px;
}
.btn-close {
  font-size: 15px;
  padding: 1px 0 0 0;
}
</style>
