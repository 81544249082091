<template>
  <Bar
    :id="chartId"
    :options="chartOptions"
    :data="chartData"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Tooltip, BarElement, PointElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(BarElement, Tooltip, PointElement, CategoryScale, LinearScale)

export default {
  name: 'KPIPeriodSingleChart',
  components: { Bar },
  props: ['chartId', 'period'],
  data () {
    return {
      chartData: {
        labels: ['Ist Vorjahr', 'Ist YTD', 'Plan', 'Ziel', 'Forecast', 'Benchmark'],
        datasets: [{
          data: [this.period.actualPrev, this.period.actual, this.period.plan, this.period.objective, this.period.forecast, this.period.benchmark],
          backgroundColor: ['#6c80bb', '#6c80bb', '#bfbfbf', '#dc3545', '#6c80bb73', '#198754']
        }]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    }
  }
}
</script>
