<template>
  <div>
    <div class="bg-custom-blue">
      <div class="container">
        <div class="row py-2">
          <div class="col-1">
            <select v-model="selectedYear" @change="getKPIChanges()" class="form-select">
              <option v-for="(n, i) in 21" :key="'selectedYear' + (10 - i)" :value="((selectedYear + 10) - i)">{{ (selectedYear + 10) - i }}</option>
            </select>
          </div>
          <div class="col-3 ms-auto">
            <input v-model="filterName" type="text" class="form-control" :placeholder='$t("general.placeholderSearch")'>
          </div>
        </div>
      </div>
    </div>
    <div v-if="filteredKPIChanges.length" class="container my-5">
      <div class="row">
        <div class="col-12">
          <div class="h4">
            {{ $t("views.kpi.detail.headingChange") }}
            <DocsInfoLink color="dark" target="kpiChange"></DocsInfoLink>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="bg-white rounded-2 p-2">
            <div class="row fw-bold px-3">
              <div class="col-2 px-1">
                {{ $t("views.kpi.detail.kpi") }}
              </div>
              <div class="col-2 px-1">
                {{ $t("views.kpi.edit.responsible") }}
              </div>
              <div class="col-1 px-1">
                {{ $t("views.kpi.edit.unit") }}
              </div>
              <div class="col-1 ps-3">
                {{ $t("views.kpi.periods.period") }}
              </div>
              <div class="col-2 text-end ps-1 pe-3">
                {{ $t("views.kpi.detail.actualYTD") }} {{ selectedYear - 1 }}
              </div>
              <div class="col-2 text-end ps-1 pe-3">
                {{ $t("views.kpi.detail.actualYTD") }} {{ selectedYear }}
              </div>
              <div class="col-2 text-end ps-1 pe-3">
                {{ $t("views.kpi.detail.yoy") }}
              </div>
            </div>
            <div v-for="change in filteredKPIChanges" :key="change.id" class="row p-3 border-bottom">
              <div class="col-2 col-truncate px-1">
                <a @click="selectKPI(change.id)" :href="'#kpiPeriodsModal' + selectedKPI.id" data-bs-toggle="modal">{{ change.name }}</a>
              </div>
              <div class="col-2 col-truncate px-1">
                {{ change.responsible }}
              </div>
              <div class="col-1 px-1">
                {{ change.unit }}
              </div>
              <div class="col-1 ps-3">
                {{ formatPeriod(change.period) }}
              </div>
              <div class="col-2 text-end ps-1 pe-3">
                {{ change.previous?.toLocaleString('de-DE') }}
              </div>
              <div class="col-2 text-end ps-1 pe-3">
                {{ change.current?.toLocaleString('de-DE') }}
              </div>
              <div class="col-2 text-end ps-1 pe-3">
                <div class="text-end" :class="change.yoyStatus">{{ change.yoy }}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!loadingStatus" class="text-center mt-5">
      <img src="@/assets/img/no_data.svg" class="img-fluid" width="250">
      <p class="h5 mt-3">{{ $t("views.kpi.detail.noKPIs") }}.</p>
    </div>
    <KPIPeriodsModal :kpi="selectedKPI"></KPIPeriodsModal>
  </div>
</template>

<script>
import DocsInfoLink from '../../components/DocsInfoLink.vue'
import * as periodUtils from '../../composables/period'
import KPIPeriodsModal from '../../components/monitoring/KPIPeriodsModal.vue'

export default {
  name: 'MonitoringKPIChange',
  data () {
    return {
      selectedYear: this.$store.state.user?.settings?.favorites?.kpiChangeSelectedYear ? this.$store.state.user.settings.favorites.kpiChangeSelectedYear : new Date().getFullYear(),
      filterName: '',
      vocabulary: {
        timeDimInput: {
          M: 'Monat',
          Q: 'Quartal',
          Y: 'Jahr'
        }
      }
    }
  },
  components: {
    DocsInfoLink,
    KPIPeriodsModal
  },
  computed: {
    kpiChanges () {
      return this.$store.state.kpiChanges
    },
    filteredKPIChanges () {
      return this.kpiChanges.filter(change => {
        return change.name.toLowerCase().includes(this.filterName.toLowerCase())
      })
    },
    selectedKPI () {
      return this.$store.state.selectedKPI
    },
    loadingStatus () {
      return this.$store.state.loadingStatus
    }
  },
  methods: {
    async getKPIChanges () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getKPIChanges', this.selectedYear)
      await this.$store.dispatch('favoriteObject', { isList: false, objectKey: 'kpiChangeSelectedYear', objectValue: this.selectedYear })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    formatPeriod (period) {
      return periodUtils.formatPeriod(period)
    },
    unitDisplay (kpi, displayMode) {
      const unit = this.$store.state.kpiUnits.find(unit => {
        return unit.id === this.selectedKPI.unit
      })
      if (unit) {
        if (displayMode === 'short') {
          return unit.name
        } else {
          return unit.name + ' (' + unit.unit + ')'
        }
      } else {
        return 'n/a'
      }
    },
    async selectKPI (kpiId) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getKPI', { mode: 'manage', id: kpiId })
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  async created () {
    this.$store.commit('SET_LOADING_STATUS', true)
    await this.$store.dispatch('getKPIChanges', this.selectedYear)
    this.$store.commit('SET_LOADING_STATUS', false)
    await this.$store.dispatch('getKPIUnits')
  }
}
</script>

<style scoped>
.container {
  max-width: 1600px;
}
.col-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-th {
  background-color: transparent;
  border: 0;
  font-weight: bold;
}
</style>
