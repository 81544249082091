<template>
  <div class="kpi-modal">
    <div ref="kpiEditModal" class="modal fade" data-bs-backdrop="static" id="kpiEditModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">
              {{ vocabulary[elementType] }} {{ vocabulary[mode] }}
              <DocsInfoLink color="light" target="createKPI"></DocsInfoLink>
            </div>>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div v-if="elementType === 'kpi'" class="form-kpi">
              <div class="row mb-3">
                <div class="col">
                  <div class="form-check form-switch">
                    <input v-model="form.kpi.folder.content" type="checkbox" role="switch" id="kpiFolderCheck" class="form-check-input" :checked="form.kpi.folder.content">
                    <label for="kpiFolderCheck" class="form-check-label">{{ $t("views.kpi.edit.folder") }}</label>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label for="kpiParent" class="form-label">{{ $t("views.kpi.edit.parentKPI") }}</label>
                  <select v-model="form.kpi.parentId.content" class="form-select" id="kpiParent">
                    <option value="" :disabled="!isAdmin">{{ $t("views.kpi.edit.noParentKPI") }}</option>
                    <option v-for="kpi in kpisList" :key="kpi.id" :value="kpi.id" :disabled="mode === 'update' && kpi.id === selectedKPI.id ? true : false">
                      {{ kpi.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label for="kpiName" class="form-label">{{ $t("views.kpi.edit.name") }}<span class="text-danger">*</span></label>
                  <input v-model="form.kpi.name.content" id="kpiName" :class="form.kpi.name.error === '' ? 'form-control' : 'form-control is-invalid'">
                  <div class="invalid-feedback">{{ form.kpi.name.error }}</div>
                </div>
              </div>
              <div v-if="!form.kpi.folder.content" class="row mb-3">
                <div class="col">
                  <label for="kpiResponsible" class="form-label">{{ $t("views.kpi.edit.responsible") }}</label>
                  <select v-model="form.kpi.responsible.content" id="kpiResponsible" :class="form.kpi.responsible.error === '' ? 'form-select' : 'form-select is-invalid'">
                    <option v-for="employee in employees" :key="employee.id" :value="employee.id">
                      {{ employee.lastName }}, {{ employee.firstName }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ form.kpi.responsible.error }}</div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label for="kpiDescription" class="form-label">{{ $t("views.kpi.edit.description") }}</label>
                  <RTEditor v-model:content="form.kpi.description.content" contentType="html" id="kpiDescription"></RTEditor>
                  <div class="quill-invalid-feedback">{{ form.kpi.description.error }}</div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <div class="form-check form-switch">
                    <input @change="toggleKPIValidCheck(!validCheck)" type="checkbox" role="switch" id="kpiValidCheck" class="form-check-input" :checked="validCheck">
                    <label for="kpiValidCheck" class="form-check-label">{{ $t("views.kpi.edit.validCheck") }}</label>
                  </div>
                </div>
              </div>
              <div v-if="validCheck" class="row mb-3">
                <div class="col-6">
                  <label for="kpiValidFrom" class="form-label">{{ $t("views.kpi.edit.validFrom") }}</label>
                  <input v-model="form.kpi.validFrom.content" type="date" id="kpiValidFrom" :class="form.kpi.validFrom.error === '' ? 'form-control' : 'form-control is-invalid'">
                  <div class="invalid-feedback">{{ form.kpi.validFrom.error }}</div>
                </div>
                <div class="col-6">
                  <label for="kpiValidTo" class="form-label">{{ $t("views.kpi.edit.validTo") }}</label>
                  <input v-model="form.kpi.validTo.content" type="date" id="kpiValidTo" :class="form.kpi.validTo.error === '' ? 'form-control' : 'form-control is-invalid'">
                  <div class="invalid-feedback">{{ form.kpi.validTo.error }}</div>
                </div>
              </div>
              <div v-if="!form.kpi.folder.content">
                <div class="row mb-3">
                  <div class="col">
                    <label for="kpiStatusRed" class="form-label">
                      {{ $t("views.kpi.edit.status") }}
                      <svg class="bi mb-1" width="20" height="20" fill="currentColor">
                        <use class="text-danger" xlink:href="@/assets/icons/icons.svg#circle-fill"/>
                      </svg>
                      {{ $t("views.kpi.edit.at") }} {{ statusRed()[0] }} {{ statusRed()[1] }}%
                    </label>
                    <input v-model="form.kpi.statusRed.content" type="number" id="kpiStatusRed" :class="form.kpi.statusRed.error === '' ? 'form-control' : 'form-control is-invalid'">
                    <div class="invalid-feedback">{{ form.kpi.statusRed.error }}</div>
                  </div>
                  <div class="col">
                    <label for="kpiStatusYellow" class="form-label">
                      {{ $t("views.kpi.edit.status") }}
                      <svg class="bi mb-1" width="20" height="20" fill="currentColor">
                        <use class="text-warning" xlink:href="@/assets/icons/icons.svg#circle-fill"/>
                      </svg>
                      {{ $t("views.kpi.edit.at") }} {{ statusYellow()[0] }} {{ statusYellow()[1] }}%
                    </label>
                    <input v-model="form.kpi.statusYellow.content" type="number" id="kpiStatusYellow" :class="form.kpi.statusYellow.error === '' ? 'form-control' : 'form-control is-invalid'">
                    <div class="invalid-feedback">{{ form.kpi.statusYellow.error }}</div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col">
                    <label for="kpiTimeDimInput" class="form-label">{{ $t("views.kpi.edit.timeDimInput") }}</label>
                    <select v-model="form.kpi.timeDimInput.content" class="form-select" id="kpiTimeDimInput" :disabled="isFormulaActive">
                      <option value="M">{{ $t("general.month") }}</option>
                      <option value="Q">{{ $t("general.quarter") }}</option>
                      <option value="Y">{{ $t("general.year") }}</option>
                    </select>
                  </div>
                  <div class="col">
                    <label for="kpiTimeDimDisplay" class="form-label">{{ $t("views.kpi.edit.timeDimDisplay") }}</label>
                    <select v-model="form.kpi.timeDimDisplay.content" class="form-select" id="kpiTimeDimDisplay" :disabled="isFormulaActive">
                      <option value="M">{{ $t("general.month") }}</option>
                      <option value="Q">{{ $t("general.quarter") }}</option>
                      <option value="Y">{{ $t("general.year") }}</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col">
                    <label for="kpiUnit" class="form-label">{{ $t("views.kpi.edit.unit") }}</label>
                    <select v-model="form.kpi.unit.content" id="kpiUnit" :class="form.kpi.unit.error === '' ? 'form-select' : 'form-select is-invalid'">
                      <option v-for="unit in kpiUnits" :key="unit.id" :value="unit.id">
                        {{ unit.unit }} ({{ unit.name }})
                      </option>
                    </select>
                  </div>
                  <div class="col">
                    <label for="kpiAggregation" class="form-label">{{ $t("views.kpi.edit.aggregation") }}</label>
                    <select v-model="form.kpi.aggregation.content" class="form-select" id="kpiAggregation">
                      <option value="S">{{ $t("views.kpi.edit.sum") }}</option>
                      <option value="M">{{ $t("views.kpi.edit.mean") }}</option>
                      <option value="A">{{ $t("views.kpi.edit.ultimate") }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="elementType === 'formula'" class="form-kpi-formula">
              <div class="row mb-3">
                <div class="col">
                  <label for="formulaValidFrom" class="form-label">{{ $t("views.kpi.edit.validFrom") }}<span class="text-danger">*</span></label>
                  <select v-model="form.formula.validFrom.content" :class="form.formula.validFrom.error === '' ? 'form-select' : 'form-select is-invalid'" id="formulaValidFrom">
                    <option v-for="(n, i) in 85" :key="'formulaValidFrom' + i" :value="(2015 + i)">{{ 2015 + i }}</option>
                  </select>
                  <div class="invalid-feedback">{{ form.formula.validFrom.error }}</div>
                </div>
                <div class="col">
                  <label for="formulaValidTo" class="form-label">{{ $t("views.kpi.edit.validTo") }}<span class="text-danger">*</span></label>
                  <select v-model="form.formula.validTo.content" :class="form.formula.validTo.error === '' ? 'form-select' : 'form-select is-invalid'" id="formulaValidTo">
                    <option v-for="(n, i) in 85" :key="'formulaValidTo' + i" :value="(2015 + i)">{{ 2015 + i }}</option>
                  </select>
                  <div class="invalid-feedback">{{ form.formula.validTo.error }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label for="formulaFormula" class="form-label">{{ $t("views.kpi.edit.formula") }}<span class="text-danger">*</span></label>
                  <div class="row mb-2">
                    <div class="col-6">
                      <select @change="addToFormula('kpi', $event)" class="form-select form-select-sm">
                        <option id="selectOptionFormulaKPIDefault" selected="selected" disabled>{{ $t("views.kpi.edit.insertKPI") }}</option>
                        <option v-for="kpi in kpisList" :key="'formulaKPI' + kpi.id" :value="kpi.id" :disabled="kpi.folder || kpi.id === selectedKPI.id ? true : false">{{ kpi.name }}</option>
                      </select>
                    </div>
                    <div class="col-6">
                      <select @change="addToFormula('operation', $event)" class="form-select form-select-sm">
                        <option id="selectOptionFormulaOperationDefault" selected disabled>{{ $t("views.kpi.edit.insertOperation") }}</option>
                        <option v-for="(operation, index) in formulaOperations" :key="'formulaOperation' + index" :value="operation">{{ operation }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-8">
                      <div class="input-group input-group-sm">
                        <input v-model="formulaNumberInput" v-on:keyup.enter="addToFormula('number', false)" type="number" class="form-control" placeholder="Zahlenwert einfügen">
                        <button @click="addToFormula('number', false)" class="btn btn-outline-secondary" type="button">{{ $t("views.kpi.edit.insert") }}</button>
                      </div>
                    </div>
                  </div>
                  <div id="input-formula" class="p-2 mb-2" :class="form.formula.formula.error === '' ? '' : 'is-invalid'">
                    <span v-if="!form.formula.formula.content.length" class="text-muted">{{ $t("views.kpi.edit.helpFormula") }}</span>
                    <span v-for="(element, index) in form.formula.formula.content" :key="'formulaElement' + index" class="badge fw-normal me-2 mb-2" :class="element.elementType === 'kpi' ? 'text-bg-primary text-light' : 'text-bg-secondary'">{{ element.name }}</span>
                  </div>
                  <div class="invalid-feedback mb-2">{{ form.formula.formula.error }}</div>
                  <button @click="deleteFormula()" class="btn btn-sm btn-outline-secondary" type="button">{{ $t("views.kpi.edit.resetFormula") }}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer bg-light justify-content-between">
            <div>
              <button v-if="mode === 'update'" @click="updateModalMode()" type="button" class="btn btn-sm btn-outline-danger" :class="selectedKPI.hasNodes || this.elementType === 'formula' ? 'disabled' : ''" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#kpiDeleteModal">{{ $t("general.delete") }}</button>
            </div>
            <div>
              <button type="button" class="btn btn-sm btn-outline-secondary me-2" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
              <button @click="save()" type="button" class="btn btn-sm btn-primary">{{ $t("general.save") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as validate from '../../composables/validation'
import DocsInfoLink from '../../components/DocsInfoLink.vue'
import RTEditor from '../RTEditor.vue'
import { Modal } from 'bootstrap'
import { ref } from 'vue'

export default {
  name: 'KPIEditModal',
  data () {
    return {
      vocabulary: {
        kpi: 'KPI',
        formula: 'Formel',
        add: 'hinzufügen',
        update: 'bearbeiten'
      },
      form: {
        kpi: {
          folder: {
            content: '',
            error: '',
            validate: []
          },
          parentId: {
            content: '',
            error: '',
            validate: []
          },
          name: {
            content: '',
            error: '',
            maxLength: 255,
            validate: ['length', 'empty']
          },
          description: {
            content: '',
            error: '',
            validate: []
          },
          responsible: {
            content: '',
            error: '',
            validate: []
          },
          timeDimInput: {
            content: '',
            error: '',
            validate: []
          },
          timeDimDisplay: {
            content: '',
            error: '',
            validate: []
          },
          unit: {
            content: '',
            error: '',
            validate: []
          },
          aggregation: {
            content: '',
            error: '',
            validate: []
          },
          statusYellow: {
            content: '',
            error: '',
            validate: []
          },
          statusRed: {
            content: '',
            error: '',
            validate: []
          },
          validFrom: {
            content: '',
            error: '',
            validate: []
          },
          validTo: {
            content: '',
            error: '',
            validate: []
          }
        },
        formula: {
          validFrom: {
            content: '',
            error: '',
            validate: ['empty']
          },
          validTo: {
            content: '',
            error: '',
            validate: ['empty']
          },
          formula: {
            content: '',
            error: '',
            validate: ['empty']
          }
        }
      },
      formulaOperations: ['(', ')', '+', '-', '*', '/'],
      formulaNumberInput: '',
      selectedElement: ''
    }
  },
  components: {
    DocsInfoLink,
    RTEditor
  },
  computed: {
    elementId () {
      return this.$parent.kpiModalElementId
    },
    elementType () {
      return this.$parent.kpiModalElementType
    },
    mode () {
      return this.$parent.kpiModalMode
    },
    kpis () {
      return this.$store.state.kpis
    },
    kpiUnits () {
      return this.$store.state.kpiUnits
    },
    kpisList () {
      return this.$store.state.kpisList
    },
    employees () {
      return this.$store.state.employees
    },
    selectedKPI () {
      return this.$store.state.selectedKPI
    },
    validCheck () {
      if (this.form.kpi.validFrom.content) {
        return true
      }
      return false
    },
    isFormulaActive () {
      if (this.selectedKPI.formulas?.length) {
        let isActive = false
        this.selectedKPI.formulas.forEach(formula => {
          const curYear = new Date().getFullYear()
          if (formula.validFrom <= curYear && formula.validTo >= curYear) {
            isActive = true
          }
        })
        return isActive
      } else {
        return false
      }
    },
    isAdmin () {
      return this.$store.state.user.role === 10387
    }
  },
  methods: {
    async save () {
      this.$store.commit('SET_LOADING_STATUS', true)

      let valid = 0

      Object.keys(this.form[this.elementType]).forEach(field => {
        this.form[this.elementType][field].validate.forEach(validation => {
          const validationResult = validate.useValidation(this.form[this.elementType], field, validation)
          if (!validationResult.status) {
            this.form[this.elementType][field].error = validationResult.content
            valid++
          }
        })
      })

      if (valid === 0) {
        let elementData = {}
        let dispatchFunc = ''
        if (this.elementType !== 'kpi') {
          elementData = {
            element: {
              id: this.elementId,
              validFrom: this.form.formula.validFrom.content,
              validTo: this.form.formula.validTo.content,
              formula: this.form.formula.formula.content
            }
          }
          elementData.elementType = (this.elementType + 's')
          elementData.kpiId = this.selectedKPI.id
        } else {
          elementData = Object.assign(
            ...Object.entries(this.form[this.elementType]).map(([key, value]) => ({ [key]: value.content }))
          )
          elementData.id = this.elementId
        }
        dispatchFunc = this.elementType === 'kpi' ? (this.mode + 'KPI') : (this.mode + 'KPIElement')
        await this.$store.dispatch(dispatchFunc, elementData).then(() => {
          this.closeModal()
          Object.keys(this.form[this.elementType]).forEach(field => {
            if (this.mode === 'add') {
              if (field === 'folder') {
                this.form[this.elementType][field].content = false
              } else {
                this.form[this.elementType][field].content = ''
              }
            }
            this.form[this.elementType][field].error = ''
          })
        })
      }

      this.$parent.kpiKeyInt++

      this.$store.commit('SET_LOADING_STATUS', false)

      await this.$store.dispatch('getKPIs', { validFrom: this.$parent.filterValidFrom, validTo: this.$parent.filterValidTo })
    },
    toggleKPIValidCheck (validCheck) {
      if (validCheck) {
        if (this.mode === 'update' && this.selectedKPI.validFrom && this.selectedKPI.validTo) {
          [this.form.kpi.validFrom.content, this.form.kpi.validTo.content] = [this.selectedKPI.validFrom, this.selectedKPI.validTo]
        } else {
          this.form.kpi.validFrom.content = '2000-01-01'
          this.form.kpi.validTo.content = '2099-12-31'
        }
      } else {
        this.form.kpi.validFrom.content = this.form.kpi.validTo.content = ''
      }
    },
    statusRed () {
      const inputRed = this.form.kpi.statusRed.content
      if (inputRed === 100) {
        this.form.kpi.statusRed.content = 99
        return ['<', 99]
      } else if (inputRed < 100) {
        return ['<', inputRed]
      } else if (inputRed > 100) {
        return ['>', inputRed]
      } else {
        return ['<', 0]
      }
    },
    statusYellow () {
      const inputRed = this.form.kpi.statusRed.content
      const inputYellow = this.form.kpi.statusYellow.content
      if (inputRed < 100) {
        if (inputYellow > inputRed) {
          return ['<', inputYellow]
        } else {
          this.form.kpi.statusYellow.content = inputRed + 1
          return ['<', inputRed + 1]
        }
      } else if (inputRed > 100) {
        if (inputYellow < inputRed) {
          return ['>', inputYellow]
        } else {
          this.form.kpi.statusYellow.content = inputRed - 1
          return ['>', inputRed - 1]
        }
      } else {
        return ['<', 0]
      }
    },
    updateModalContent () {
      Object.keys(this.form[this.elementType]).forEach(field => {
        let selectedElement = this.selectedKPI
        if (this.elementType !== 'kpi') {
          const elementTypePlural = this.elementType + 's'
          selectedElement = this.selectedKPI[elementTypePlural].find(element => {
            return element.id === this.$parent.kpiModalElementId
          })
          this.$parent.selectedElement = selectedElement
        }
        if (field in selectedElement) {
          if (field === 'responsible' && selectedElement[field].id) {
            this.form[this.elementType].responsible.content = selectedElement[field].id
          } else {
            this.form[this.elementType][field].content = selectedElement[field]
          }
          this.form[this.elementType][field].error = ''
        }
      })
    },
    updateModalMode () {
      if (this.elementType === 'kpi') {
        this.$parent.kpiModalMode = 'delete'
      }
    },
    addToFormula (elementType, event) {
      let element = {}
      if (!Array.isArray(this.form.formula.formula.content)) {
        this.form.formula.formula.content = []
      }
      if (elementType === 'kpi') {
        if (!this.form.formula.formula.content.length || this.form.formula.formula.content.at(-1).elementType === 'operation') {
          element = this.kpisList.find(kpi => {
            return kpi.id === event.target.value
          })
          const elementName = element.name.replace(/- /gm, '').trim()
          this.form.formula.formula.content.push({ id: element.id, name: elementName, elementType })
        }
        document.getElementById('selectOptionFormulaKPIDefault').selected = true
      } else if (elementType === 'operation') {
        element = { id: ('operation' + this.form.formula.formula.content.length), name: event.target.value }
        this.form.formula.formula.content.push({ id: element.id, name: element.name, elementType })
        document.getElementById('selectOptionFormulaOperationDefault').selected = true
      } else if (elementType === 'number') {
        if (this.formulaNumberInput !== '' && (!this.form.formula.formula.content.length || this.form.formula.formula.content.at(-1).elementType === 'operation')) {
          element = { id: ('number' + this.form.formula.formula.content.length), name: this.formulaNumberInput }
          this.form.formula.formula.content.push({ id: element.id, name: element.name, elementType })
          this.formulaNumberInput = ''
        }
      }
    },
    deleteFormula () {
      this.form.formula.formula.content = ''
    },
    checkFormula () {
      this.form.formula.formula.content = this.form.formula.formula.content.replace(/[^+\-*/]/gm, '')
    }
  },
  watch: {
    elementId () {
      this.updateModalContent()
    },
    mode (newMode) {
      this.employeesExistingCheck = false
      if (newMode === 'add') {
        Object.keys(this.form[this.elementType]).forEach(field => {
          if (field === 'folder') {
            this.form[this.elementType][field].content = false
          } else {
            this.form[this.elementType][field].content = this.form[this.elementType][field].error = ''
          }
        })
      } else if (newMode === 'update') {
        this.updateModalContent()
      }
    }
  },
  setup () {
    const kpiEditModal = ref(null)
    const closeModal = () => Modal.getInstance(kpiEditModal.value)?.hide()
    return { kpiEditModal, closeModal }
  }
}
</script>

<style scoped>
#input-formula {
  width: 100%;
  height: 160px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  background-color: #fff;
  overflow-y: scroll;
}
</style>
