export function treeToList (tree) {
  const resultList = []
  function addToList (node, depth) {
    let dashes = '- '
    while (dashes.length < (depth * 2)) {
      dashes = dashes + '- '
    }
    node.name = dashes + node.name
    resultList.push(node)
    if (node.nodes.length) {
      node.nodes.forEach(node => {
        addToList(node, (depth + 1))
      })
    }
  }
  tree?.forEach(node => {
    addToList(node, 1)
  })
  return resultList
}
