<template>
  <div class="container">
    <div class="row py-5 justify-content-center">
      <div class="col-12 col-lg-6 py-5">
        <div class="row border-bottom border-2">
          <div class="col my-auto">
            Status
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="fs-5 my-2">
              <span v-if="tenant.activated" class="badge text-bg-success fs-6 fw-normal px-2 py-1 me-2">Aktiv</span>
              <span v-else class="badge text-bg-warning fs-6 fw-normal px-2 py-1 me-2">Testphase</span>
            </div>
            <div class="fs-5 my-2">
              Registriert am {{ new Date(tenant.created).toLocaleDateString({dateStyle: 'short'}) }}
            </div>
            <p class="mt-3">Für die Verwaltung des Abonnements arbeiten wir mit dem Zahlungsdienstleister Stripe zusammen, zu dessen Website Sie entsprechend weitergeleitet werden.</p>
            <div>
              <a v-if="tenant.activated" :href="stripeCustomerPortal" target="_blank" class="btn btn-primary mb-1">Abonnement verwalten</a>
              <a v-else :href="stripeURL" class="btn btn-primary mb-1">Abonnieren</a>
            </div>
          </div>
        </div>
        <div v-if="tenant.invoices?.length" class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <div class="fs-5 my-2">
                  Rechnungen
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <ul class="list-group">
                  <li v-for="invoice in tenant.invoices" :key="invoice.id" class="list-group-item d-flex justify-content-between">
                    <span>{{ new Date(invoice.created * 1000).toLocaleDateString({dateStyle: 'short'}) }}</span>
                    <a :href="invoice.pdf" target="_blank" class="text-primary fw-bold ms-auto">PDF</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row border-bottom border-2 mt-5">
          <div class="col my-auto">
            Tenant löschen
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="mt-3">Falls Sie diesen Tenant löschen möchten, schicken Sie uns bitte eine E-Mail an
              <a href="mailto:support@focusmt.de">support@focusmt.de</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingsManageTenant',
  data () {
    return {
      stripeURL: process.env.VUE_APP_STRIPE_URL,
      stripeCustomerPortal: process.env.VUE_APP_STRIPE_CUSTOMER_PORTAL
    }
  },
  computed: {
    tenant () {
      return this.$store.state.tenant
    }
  },
  methods: {
    async activateTenant (checkoutId) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('updateTenantSubscription', { action: 'subscribed', id: checkoutId })
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  beforeMount () {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('action') === 'subscribed' && urlParams.get('id')) {
      this.activateTenant(urlParams.get('id'))
    }
  },
  async created () {
    this.$store.commit('SET_LOADING_STATUS', true)
    await this.$store.dispatch('getTenant')
    this.$store.commit('SET_LOADING_STATUS', false)
  }
}
</script>
