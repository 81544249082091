<template>
  <div class="row">
    <div class="col-12">
      <div class="row mt-3">
        <div class="col">
          <button @click="toggleObjectivesContentView('detail')" type="button" class="btn btn-sm btn-outline-dark">&lt; {{ $t("general.back") }}</button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="h3">
            {{ $t("views.objective.edit.heading") }}
            <DocsInfoLink color="dark" target="createObjective"></DocsInfoLink>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12">
          <span v-for="(value, key) in smart" :key="'smart-' + key" :class="value ? 'text-bg-success' : 'text-bg-danger'" class="badge fs-5 fw-normal px-2 py-1 me-2">{{ key }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="progress">
            <div class="progress-bar" role="progressbar" :style="progress.width" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="card w-100">
            <div class="card-body">
              <div class="row mb-5">
                <div class="col-4">
                  <label for="orgUnit" class="form-label">
                    {{ $t("views.objective.edit.orgUnit") }}<span class="text-danger">*</span>
                  </label>
                  <select v-model="newObjective.orgUnit" id="orgUnit" class="form-select">
                    <option v-for="orgUnit in orgUnitsList" :key="orgUnit.id" :value="orgUnit.id">{{ orgUnit.name }}</option>
                  </select>
                </div>
                <div class="col-5 offset-2">
                  <label for="parentId" class="form-label">
                    {{ $t("views.objective.edit.parentObjective") }}<span class="text-danger">*</span>
                  </label>
                  <select v-model="newObjective.parentId" id="parentId" class="form-select">
                    <option value="0">{{ $t("views.objective.edit.noParentObjective") }}</option>
                    <option v-for="objective in objectivesList" :key="objective.id" :value="objective.id" :disabled="objective.org ? true : false">
                      {{ objective.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-5">
                  <div class="row">
                    <div class="col-12">
                      <label for="name" class="form-label w-100">
                        <div class="row">
                          <div class="col-6">
                            {{ $t("views.objective.edit.name") }}<span class="text-danger">*</span>
                          </div>
                          <div class="col-6 text-end">
                            <div>
                              <input v-model="newObjective.category" id="checkCategory" class="form-check-input" type="checkbox">
                              <label class="form-check-label ms-1" for="checkCategory">
                                {{ $t("views.objective.edit.breakthrough") }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </label>
                      <input v-model="newObjective.name" id="name" :class="form.name.error === '' ? 'form-control' : 'form-control is-invalid'">
                      <div class="invalid-feedback">{{ form.name.error }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-4 offset-1">
                  <label for="mentor" class="form-label">
                    {{ $t("views.objective.edit.mentor") }}
                  </label>
                  <select v-model="newObjective.mentor" id="mentor" class="form-select">
                    <option v-for="person in employees" :key="person.id" :value="person.id">
                      {{ person.lastName }}, {{ person.firstName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-5">
                  <label for="description" class="form-label">
                    {{ $t("views.objective.edit.description") }}<span class="text-danger">*</span>
                  </label>
                  <RTEditor v-model:content="newObjective.description" contentType="html" id="description"></RTEditor>
                </div>
                <div class="col-4 offset-1">
                  <div class="row mb-3">
                    <div class="col-6">
                      <label for="validFrom" class="form-label">
                        {{ $t("views.objective.edit.validFrom") }}<span class="text-danger">*</span>
                      </label>
                      <input v-model="newObjective.validFrom" type="date" id="validFrom" :class="form.validFrom.error === '' ? 'form-control' : 'form-control is-invalid'">
                      <div class="invalid-feedback">{{ form.validFrom.error }}</div>
                    </div>
                    <div class="col-6">
                      <label for="validTo" class="form-label">
                        {{ $t("views.objective.edit.validTo") }}<span class="text-danger">*</span>
                      </label>
                      <input v-model="newObjective.validTo" type="date" id="validTo" :class="form.validTo.error === '' ? 'form-control' : 'form-control is-invalid'">
                      <div class="invalid-feedback">{{ form.validTo.error }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="mb-2">{{ $t("views.objective.edit.performanceBenchmarks") }}<span class="text-danger">*</span></div>
                  <div class="btn-group btn-group-objectives-view" role="group">
                    <button @click="toggleCriterion('KRT')" type="button" class="btn btn-sm btn-outline-primary" :class="criterion === 'KRT' ? 'active' : ''">{{ $t("views.objective.edit.btnKeyResults") }}</button>
                    <span class="mx-2 mt-1">- {{ $t("general.or") }} -</span>
                    <button @click="toggleCriterion('KPI')" type="button" class="btn btn-sm btn-outline-primary" :class="criterion === 'KPI' ? 'active' : ''">{{ $t("views.objective.edit.btnKPIs") }}</button>
                  </div>
                </div>
              </div>
              <div v-if="criterion === 'KRT'" class="mt-3 mb-2">
                <div>
                  <button @click="addKeyResult()" type="button" class="btn btn-sm btn-outline-primary mt-auto">+ {{ $t("views.objective.edit.btnAddKeyResult") }}</button>
                </div>
                <div v-for="keyresult, index in newObjective.keyresults" :key="'keyresult' + index" class="row mt-3">
                  <div class="col-3">
                    <label for="name" class="form-label">
                      {{ $t("views.objective.edit.keyResultName") }}<span class="text-danger">*</span>
                    </label>
                    <input v-model="keyresult.name" id="keyresultName" class="form-control">
                  </div>
                  <div class="col-3">
                    <label for="name" class="form-label">
                      {{ $t("views.objective.keyresult.unit") }}<span class="text-danger">*</span>
                    </label>
                    <input v-model="keyresult.unit" id="keyresultUnit" class="form-control">
                  </div>
                  <div class="col-2">
                    <label for="name" class="form-label">
                      {{ $t("views.objective.keyresult.jop") }}<span class="text-danger">*</span>
                    </label>
                    <input v-model="keyresult.jop" type="number" id="keyresultJOP" class="form-control">
                  </div>
                  <div class="col-2">
                    <label for="name" class="form-label">
                      {{ $t("views.objective.edit.keyResultObjective") }}<span class="text-danger">*</span>
                    </label>
                    <input v-model="keyresult.obj" type="number" id="keyresultObjective" class="form-control">
                  </div>
                  <div class="col-1 mt-auto">
                    <button @click="deleteKeyResult(index)" class="btn btn-outline-danger" type="button">
                      <svg class="bi" width="18" height="18" fill="currentColor">
                        <use xlink:href="@/assets/icons/icons.svg#trash-fill"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="criterion === 'KPI'" class="mt-3 mb-2">
                <div class="row">
                  <div class="col-4">
                    <select @change="addKPI()" v-model="kpiSelected" id="kpis" class="form-select">
                      <option :value="{ id: 0, name: 'Auswählen...' }">{{ kpiSelected.name }}</option>
                      <option v-for="kpi in kpisList" :key="kpi.id" :value="kpi" :disabled="kpi.folder ? true : false">{{ kpi.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="row selected-kpis">
                  <div class="col-12">
                    <div v-for="userSelectedKPI in newObjective.kpis" :key="'user-selected-kpi' + userSelectedKPI.id" class="input-group input-group-sm mt-3">
                      <span class="input-group-text">
                        {{ userSelectedKPI.name.replace(/- /gm, '').trim() }}
                      </span>
                      <a @click="selectKPI(userSelectedKPI.id)" :href="'#kpiPeriodsModal' + selectedKPI.id" data-bs-toggle="modal" class="btn btn-sm btn-outline-primary">{{ $t("views.objective.edit.viewKPI") }}</a>
                      <a @click="recordKPI(userSelectedKPI)" href="#kpiRecordEditModal" data-bs-toggle="modal" class="btn btn-sm btn-outline-primary">{{ $t("views.objective.edit.recordKPI") }}</a>
                      <button @click="deleteKPI()" class="btn btn-sm btn-outline-danger" type="button">
                        <svg class="bi" width="18" height="18" fill="currentColor">
                          <use xlink:href="@/assets/icons/icons.svg#trash-fill"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col text-end">
          <input v-model="newObjective.ambitious" id="checkNameAmbitious" class="form-check-input" type="checkbox">
          <label class="form-check-label ms-1" for="checkNameAmbitious">
            {{ $t("views.objective.edit.ambitious") }}<span class="text-danger">*</span>
          </label>
          <input v-model="newObjective.realistic" id="checkNameRealistic" class="form-check-input ms-3" type="checkbox">
          <label class="form-check-label ms-1" for="checkNameRealistic">
            {{ $t("views.objective.edit.realistic") }}<span class="text-danger">*</span>
          </label>
          <button @click="save()" type="button" class="btn btn-sm btn-primary ms-3" :class="progress.value >= 100 ? '' : 'disabled'">{{ $t("views.objective.edit.btnSave") }}</button>
        </div>
      </div>
      <KPIPeriodsModal :kpi="selectedKPI"></KPIPeriodsModal>
      <KPIRecordEditModal :kpi="selectedKPI"></KPIRecordEditModal>
    </div>
  </div>
</template>

<script>
import * as validate from '../../composables/validation'
import DocsInfoLink from '../../components/DocsInfoLink.vue'
import RTEditor from '../RTEditor.vue'
import KPIPeriodsModal from '../monitoring/KPIPeriodsModal.vue'
import KPIRecordEditModal from '../../components/monitoring/KPIRecordEditModal.vue'

export default {
  name: 'ObjectivesManageObjectiveAdd',
  data () {
    return {
      checkNameAmbitious: false,
      checkNameRealistic: false,
      criterion: 'KRT',
      kpiSelected: { id: 0, name: 'Auswählen...' },
      form: {
        orgUnit: {
          error: '',
          validate: ['empty']
        },
        parentId: {
          error: '',
          validate: []
        },
        name: {
          error: '',
          maxLength: 255,
          validate: ['length', 'empty']
        },
        mentor: {
          error: '',
          validate: []
        },
        category: {
          error: '',
          validate: []
        },
        description: {
          error: '',
          validate: []
        },
        validFrom: {
          error: '',
          validate: ['empty']
        },
        validTo: {
          error: '',
          validate: ['empty']
        },
        kpis: {
          error: '',
          validate: []
        }
      },
      vocabulary: {
        timeDimInput: {
          M: 'Monat',
          Q: 'Quartal',
          Y: 'Jahr'
        },
        aggregation: {
          S: 'Summenwertbildung',
          M: 'Mittelwertbildung',
          A: 'Endwertbildung'
        }
      },
      selectedYear: 2023,
      heading: 'Zielwerte erfassen'
    }
  },
  components: {
    DocsInfoLink,
    RTEditor,
    KPIPeriodsModal,
    KPIRecordEditModal
  },
  computed: {
    newObjective () {
      return this.$store.state.newObjective
    },
    objectivesList () {
      return this.$store.state.objectivesList
    },
    orgUnitsList () {
      return this.$store.state.orgUnitsList
    },
    employees () {
      return this.$store.state.employees
    },
    kpisList () {
      return this.$store.state.kpisList
    },
    selectedKPI () {
      return this.$store.state.selectedKPI
    },
    progress () {
      let progress = 0
      Object.entries(this.newObjective).forEach(entry => {
        if (!['category', 'criterion'].includes(entry[0]) && ((!Array.isArray(entry[1]) && entry[1]) || (Array.isArray(entry[1]) && entry[1].length))) {
          if (((entry[0] === 'name' && entry[1].trim()) || entry[0] !== 'name') && entry[0] !== 'mentor') {
            progress = progress + (100 / 9)
          }
        }
      })
      return { width: { width: (Math.round(progress) + '%') }, value: Math.round(progress) }
    },
    smart () {
      const smart = { S: false, M: false, A: false, R: false, T: false }
      Object.keys(smart).forEach(key => {
        switch (key) {
          case 'S':
            if (this.newObjective.name?.trim() && this.newObjective.parentId && this.newObjective.orgUnit) {
              smart[key] = true
            }
            break
          case 'M':
            if (this.newObjective.kpis?.length || this.newObjective.keyresults?.length) {
              smart[key] = true
            }
            break
          case 'A':
            if (this.newObjective.name?.trim() && this.newObjective.ambitious) {
              smart[key] = true
            }
            break
          case 'R':
            if (this.newObjective.name?.trim() && this.newObjective.realistic) {
              smart[key] = true
            }
            break
          case 'T':
            if (this.newObjective.validFrom && this.newObjective.validTo) {
              smart[key] = true
            }
            break
        }
      })
      return smart
    }
  },
  methods: {
    addKPI () {
      this.$store.dispatch('addObjectiveKPI', { kpi: this.kpiSelected })
      this.kpiSelected = { id: 0, name: 'Auswählen...' }
    },
    async selectKPI (kpiId) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getKPI', { mode: 'manage', id: kpiId })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async recordKPI (kpi) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getKPIRecords', { kpiId: kpi.id, selectedYear: this.selectedYear }).then(() => {
        this.$store.state.kpiRecordMode = 'objective'
        this.$store.state.selectedKPI = kpi
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    deleteKPI () {
      this.$store.dispatch('deleteObjectiveKPI', { kpiId: this.kpiSelected.id })
    },
    addKeyResult () {
      if ('keyresults' in this.newObjective) {
        this.newObjective.keyresults.push({})
      } else {
        this.newObjective.keyresults = [{}]
      }
    },
    deleteKeyResult (index) {
      this.newObjective.keyresults.splice(index, 1)
    },
    unitDisplay (kpi, displayMode) {
      const unit = this.$store.state.kpiUnits.find(unit => {
        return unit.id === this.selectedKPI.unit
      })
      if (unit) {
        if (displayMode === 'short') {
          return unit.name
        } else {
          return unit.name + ' (' + unit.unit + ')'
        }
      } else {
        return 'n/a'
      }
    },
    async save () {
      this.$store.commit('SET_LOADING_STATUS', true)

      let valid = 0

      // Validation: Objective
      Object.keys(this.form).forEach(field => {
        this.form[field].content = this.newObjective[field]
        this.form[field].validate.forEach(validation => {
          const validationResult = validate.useValidation(this.form, field, validation)
          if (!validationResult.status) {
            this.form[field].error = validationResult.content
            valid++
          }
        })
      })

      // Validation: Key Results
      if (this.criterion === 'KRT') {
        this.newObjective.keyresults.forEach(keyresult => {
          const fields = ['name', 'unit', 'jop', 'obj']
          fields.forEach(field => {
            if (field in keyresult) {
              if (field === 'name' || field === 'unit') {
                if (keyresult[field].trim() !== '' && keyresult[field].length <= 255) {
                  return
                }
              } else if ((field === 'jop' || field === 'obj') && keyresult[field] !== '') {
                return
              }
            }
            valid++
          })
        })
      }

      // Save, if validation without errors
      if (valid === 0) {
        this.newObjective.criterion = this.criterion
        await this.$store.dispatch('addObjective', this.newObjective).then(() => {
          Object.keys(this.form).forEach(field => {
            this.form[field].error = ''
          })
        })
      }

      this.$store.commit('SET_LOADING_STATUS', false)

      await this.$store.dispatch('getObjectives', { validFrom: this.$parent.filterValidFrom, validTo: this.$parent.filterValidTo, treeView: this.$parent.objectivesTreeView })
    },
    toggleObjectivesContentView (objectivesContentView) {
      this.$store.commit('SET_OBJECTIVES_CONTENT_VIEW', objectivesContentView)
    },
    toggleCriterion (criterion) {
      this.criterion = criterion
    }
  }
}
</script>

<style scoped>
.page-link:active, .page-link:focus {
  box-shadow: none;
  background-color: #fff;
}
@keyframes progress-pusher {
  0%   { opacity: 0 }
  33%  { opacity: 33 }
  66%  { opacity: 66 }
  100% { opacity: 0 }
}
#progress-pusher-1, #progress-pusher-2, #progress-pusher-3 {
  animation: progress-pusher;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
.selected-kpis input {
  max-width: 10%;
}
</style>
