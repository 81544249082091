<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-3 sidebar-org bg-custom-blue pb-5">
        <div class="row mt-3">
          <div class="col-12">
            <button @click="openModal(false, 'orgUnit', 'add')" type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#orgUnitEditModal">+ {{ $t("general.add") }}</button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="dropdown">
              <button class="btn btn-sm btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span>{{ new Date(filterValidFrom).toLocaleDateString({dateStyle: 'short'}) }} &nbsp;-&nbsp; {{ new Date(filterValidTo).toLocaleDateString({dateStyle: 'short'}) }}</span>
              </button>
              <div class="dropdown-menu border-secondary p-3">
                <div class="row">
                  <div class="col-12">
                    <button @click="filterOrgUnits(new Date().toISOString().slice(0, 10), new Date().toISOString().slice(0, 10))" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ $t("general.today") }}</button>
                    <button @click="filterOrgUnits((new Date().getFullYear() + 1).toString() + '-01-01', (new Date().getFullYear() + 1).toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ new Date().getFullYear() + 1 }}</button>
                    <button @click="filterOrgUnits(new Date().getFullYear().toString() + '-01-01', new Date().getFullYear().toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ new Date().getFullYear() }}</button>
                    <button @click="filterOrgUnits((new Date().getFullYear() - 1).toString() + '-01-01', (new Date().getFullYear() - 1).toString() + '-12-31')" class="btn btn-sm btn-outline-primary me-2 mb-2" type="button">{{ new Date().getFullYear() - 1 }}</button>
                  </div>
                </div>
                <hr class="dropdown-divider">
                <div class="row">
                  <div class="col-6">
                    <label for="filterValidFrom" class="form-label">{{ $t("views.kpi.periods.from") }}:</label>
                    <input v-model="filterValidFrom" type="date" class="form-control form-control-sm" id="filterValidFrom">
                  </div>
                  <div class="col-6">
                    <label for="filterValidTo" class="form-label">{{ $t("views.kpi.periods.to") }}:</label>
                    <input v-model="filterValidTo" type="date" class="form-control form-control-sm" id="filterValidTo">
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button @click="filterOrgUnits(filterValidFrom, filterValidTo)" class="btn btn-sm btn-outline-primary mt-3" type="button">{{ $t("views.kpi.periods.filter") }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <OrgUnitTree v-if="'nodes' in orgUnits && orgUnits.nodes.length" :node="orgUnits" :depth="0" class="mt-3"></OrgUnitTree>
          </div>
        </div>
      </div>
      <div class="col-9 px-5">
        <div v-if="Object.keys(selectedOrgUnit).length" class="row">
          <div class="col-12">
            <div class="row mt-3">
              <div class="col">
                <button v-if="selectedOrgUnit.write" @click="openModal(selectedOrgUnit.id, 'orgUnit', 'update')" type="button" class="btn btn-sm btn-outline-dark" data-bs-toggle="modal" data-bs-target="#orgUnitEditModal">&bull; {{ $t("general.update") }}</button>
              </div>
              <div class="col text-end">
                <DocsInfoLink color="dark" target="viewOrg"></DocsInfoLink>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="h3">{{ selectedOrgUnit.name }}</div>
                <div>
                  {{ $t("views.org.structure.validityPeriod") }}:
                  <span v-if="selectedOrgUnit.validFrom">
                    {{ new Date(selectedOrgUnit.validFrom).toLocaleDateString({dateStyle: 'short'}) }} {{ $t("general.until") }} {{ new Date(selectedOrgUnit.validTo).toLocaleDateString({dateStyle: 'short'}) }}
                  </span>
                  <span v-else class="fst-italic">
                    {{ $t("views.org.structure.infinite") }}
                  </span>
                </div>
              </div>
            </div>
            <div v-if="selectedOrgUnit.description" class="row mt-2">
              <div class="col-6">
                <div v-html="selectedOrgUnit.description" id="orgUnitDesc"></div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-4">
                <div class="card mb-3">
                  <div class="card-header bg-primary text-light">{{ $t("views.org.detail.mission") }}</div>
                  <div class="card-body">
                    <div v-if="selectedOrgUnit.mission" v-html="selectedOrgUnit.mission"></div>
                    <div v-else class="card-text">{{ $t("views.org.detail.noMission") }}</div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="card mb-3">
                  <div class="card-header bg-primary text-light">{{ $t("views.org.detail.vision") }}</div>
                  <div class="card-body">
                    <div v-if="selectedOrgUnit.vision" v-html="selectedOrgUnit.vision"></div>
                    <div v-else class="card-text">{{ $t("views.org.detail.noVision") }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <div class="card">
                  <div class="card-header fw-bold">{{ $t("views.org.detail.headingCard") }}</div>
                  <div class="card-body">
                    <ul class="nav nav-tabs border-bottom" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark active" id="employees-tab" data-bs-toggle="tab" data-bs-target="#employees" type="button" role="tab" aria-controls="employees" aria-selected="false">{{ $t("views.org.employee.employees") }} <span class="badge bg-dark">{{ selectedOrgUnit.employees.length }}</span></button>
                      </li>
                    </ul>
                    <div class="tab-content mt-2">
                      <div class="tab-pane fade show active" id="employees" role="tabpanel">
                        <div v-if="selectedOrgUnit.write" class="row my-3">
                          <div class="col">
                            <button @click="openModal(false, 'employee', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#orgUnitEditModal">+ {{ $t("general.add") }}</button>
                          </div>
                        </div>
                        <div v-if="selectedOrgUnit.employees.length">
                          <table class="table">
                            <thead>
                              <tr class="border-dark">
                                <th scope="col">{{ $t("views.org.employee.name") }}</th>
                                <th scope="col">{{ $t("views.org.employee.email") }}</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="person in selectedOrgUnit.employees" :key="'person' + person.id">
                                <th @click="selectEmployee(person.id)" role="button" scope="row" class="fw-normal">{{ person.lastName }}, {{ person.firstName }}</th>
                                <td>{{ person.email }}</td>
                                <td v-if="selectedOrgUnit.write">
                                  <div class="dropdown">
                                    <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                      <svg class="bi" width="28" height="28" fill="currentColor">
                                        <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                      </svg>
                                    </a>
                                    <ul class="dropdown-menu">
                                      <li><a @click="openModal(person.id, 'employee', 'remove')" class="dropdown-item" href="#orgUnitDeleteModal" data-bs-toggle="modal">{{ $t("general.remove") }}</a></li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div v-else class="mt-3 h6">{{ $t("views.org.detail.noEmployees") }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center mt-5">
          <img src="@/assets/img/no_data.svg" class="img-fluid" width="250">
          <p v-if="'nodes' in orgUnits && orgUnits.nodes.length" class="h5 mt-3">{{ $t("views.org.structure.helpChooseOrgUnit") }}</p>
          <p v-else class="h5 mt-3">{{ $t("views.org.structure.helpCreateOrgUnit") }}</p>
        </div>
      </div>
    </div>
    <OrgUnitEditModal></OrgUnitEditModal>
    <OrgUnitDeleteModal></OrgUnitDeleteModal>
  </div>
</template>

<script>
import DocsInfoLink from '../../components/DocsInfoLink.vue'
import OrgUnitTree from '../../components/org/OrgUnitTree.vue'
import OrgUnitEditModal from '../../components/org/OrgUnitEditModal.vue'
import OrgUnitDeleteModal from '../../components/org/OrgUnitDeleteModal.vue'

export default {
  name: 'OrgManageOrgUnit',
  data () {
    return {
      orgUnitModalElementId: false,
      orgUnitModalElementType: '',
      orgUnitModalMode: '',
      selectedElement: '',
      filterValidFrom: this.$store.state.user?.settings?.favorites?.orgUnitManage?.filterValidFrom ? this.$store.state.user.settings.favorites.orgUnitManage.filterValidFrom : new Date().getFullYear().toString() + '-01-01',
      filterValidTo: this.$store.state.user?.settings?.favorites?.orgUnitManage?.filterValidTo ? this.$store.state.user.settings.favorites.orgUnitManage.filterValidTo : (new Date().getFullYear() + 1).toString() + '-12-31'
    }
  },
  components: {
    DocsInfoLink,
    OrgUnitTree,
    OrgUnitEditModal,
    OrgUnitDeleteModal
  },
  computed: {
    orgUnits () {
      return this.$store.state.orgUnits
    },
    selectedOrgUnit () {
      return this.$store.state.selectedOrgUnit
    }
  },
  methods: {
    async filterOrgUnits (filterFrom, filterTo) {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.filterValidFrom = filterFrom
      this.filterValidTo = filterTo
      await this.$store.dispatch('getOrgUnits', { validFrom: filterFrom, validTo: filterTo })
      await this.$store.dispatch('favoriteObject', { isList: false, objectKey: 'orgUnitManage', objectValue: { filterValidFrom: filterFrom, filterValidTo: filterTo } })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    openModal (id, elementType, mode) {
      if (id) {
        this.orgUnitModalElementId = id
      }
      this.orgUnitModalElementType = elementType
      this.orgUnitModalMode = mode
    },
    async selectEmployee (employeeId) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getEmployee', employeeId).then(() => {
        this.$router.push({ name: 'OrgManageEmployee' })
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  async created () {
    this.$store.commit('SET_LOADING_STATUS', true)
    await this.$store.dispatch('getOrgUnits', { validFrom: this.filterValidFrom, validTo: this.filterValidTo })
    if (Object.keys(this.selectedOrgUnit).length) {
      await this.$store.dispatch('getOrgUnit', this.selectedOrgUnit.id)
    }
    this.$store.commit('SET_LOADING_STATUS', false)
  }
}
</script>
