<template>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col">
        <div class="text-center">
          <svg id="navbar-logo" class="bi mb-2" width="70" height="70" fill="currentColor">
            <use xlink:href="@/assets/icons/icons.svg#nut-fill"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-12 col-lg-3">
        <div>
          <div class="form-floating mb-3">
            <input v-model="newUser.orgName.content" type="text" :class="newUser.orgName.error === '' ? 'form-control' : 'form-control is-invalid'"
              id="inputOrgName" :placeholder='$t("views.register.placeholderOrg")'>
            <label for="inputOrgName">{{ $t("views.register.org") }}</label>
            <div class="invalid-feedback">{{ newUser.orgName.error }}</div>
          </div>
          <div class="form-floating mb-3">
            <input v-model="newUser.firstName.content" type="text" :class="newUser.firstName.error === '' ? 'form-control' : 'form-control is-invalid'"
              id="inputFirstName" :placeholder='$t("views.register.placeholderFirstName")'>
            <label for="inputFirstName">{{ $t("views.register.firstName") }}</label>
            <div class="invalid-feedback">{{ newUser.firstName.error }}</div>
          </div>
          <div class="form-floating mb-3">
            <input v-model="newUser.lastName.content" type="text" :class="newUser.lastName.error === '' ? 'form-control' : 'form-control is-invalid'"
              id="inputLastName" :placeholder='$t("views.register.placeholderLastName")'>
            <label for="inputLastName">{{ $t("views.register.lastName") }}</label>
            <div class="invalid-feedback">{{ newUser.lastName.error }}</div>
          </div>
          <div class="form-floating mb-3">
            <input v-model="newUser.email.content" type="email" :class="newUser.email.error === '' ? 'form-control' : 'form-control is-invalid'"
              id="inputEmail" :placeholder='$t("views.register.placeholderEmail")'>
            <label for="inputEmail">{{ $t("views.register.email") }}</label>
            <div class="invalid-feedback">{{ newUser.email.error }}</div>
          </div>
          <div class="form-floating mb-3">
            <input v-model="newUser.emailConfirmation.content" type="email" :class="newUser.emailConfirmation.error === '' ? 'form-control' : 'form-control is-invalid'"
              id="inputEmailConfirmation" :placeholder='$t("views.register.placeholderEmailConfirmation")'>
            <label for="inputEmailConfirmation">{{ $t("views.register.emailConfirmation") }}</label>
            <div class="invalid-feedback">{{ newUser.emailConfirmation.error }}</div>
          </div>
          <div class="form-check">
            <input v-model="legalAccept" class="form-check-input" type="checkbox" value="">
            <label class="form-check-label" for="flexCheckDefault">
              Ich habe die <a href="/docs/AGB_focusMT_202408.pdf" target="_blank">AGBs</a> und den <a href="/docs/Enbenutzervertrag_focusMT_202310.pdf" target="_blank">Endbenutzervertrag</a> gelesen und akzeptiert.
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center my-3">
      <div class="col-6 col-lg-3">
        <button @click="register" class="btn btn-primary w-100" :class="!legalAccept ? 'disabled' : ''">{{ $t("views.register.register") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as validate from '../../composables/validation'

export default {
  name: 'RegisterPage',
  data () {
    return {
      legalAccept: false,
      newUser: {
        orgName: {
          content: '',
          error: '',
          maxLength: 100,
          validate: ['length', 'empty']
        },
        firstName: {
          content: '',
          error: '',
          maxLength: 150,
          validate: ['length', 'empty']
        },
        lastName: {
          content: '',
          error: '',
          maxLength: 150,
          validate: ['length', 'empty']
        },
        email: {
          content: '',
          error: '',
          validate: ['email', 'empty']
        },
        emailConfirmation: {
          content: '',
          error: '',
          validate: ['emailConfirmation']
        },
        settings: {
          content: '',
          error: '',
          validate: []
        }
      }
    }
  },
  methods: {
    async register () {
      this.$store.commit('SET_LOADING_STATUS', true)

      let valid = 0

      Object.keys(this.newUser).forEach(field => {
        this.newUser[field].validate.forEach(validation => {
          const validationResult = validate.useValidation(this.newUser, field, validation)
          if (!validationResult.status) {
            this.newUser[field].error = validationResult.content
            valid++
          } else {
            this.newUser[field].error = ''
          }
        })
      })

      if (valid === 0) {
        const newUserData = Object.assign(
          ...Object.entries(this.newUser).map(([key, value]) => ({ [key]: value.content }))
        )
        await this.$store.dispatch('register', newUserData)
      }

      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  created () {
    this.newUser.settings.content = { language: this.$i18n.locale }
  }
}
</script>
