<template>
  <div class="project-tree">
    <div class="py-2 border-bottom border-dark" :class="!depth ? 'd-none' : ''">
      <div :style="indent">
        <div class="d-inline-block" role="button">
          <div v-if="node.nodes.length && depth > 1" @click="toggleNodes">
            <svg class="bi" width="22" height="22" fill="currentColor">
              <use v-if="showNodes" xlink:href="@/assets/icons/icons.svg#dash"/>
              <use v-else xlink:href="@/assets/icons/icons.svg#plus"/>
            </svg>
          </div>
          <div v-else-if="depth > 1" class="tree-node-collapse-placeholder"></div>
        </div>
        <div class="cont-icon-project d-inline-block me-1">
          <svg class="bi" width="20" height="20" fill="currentColor">
            <use v-if="node.folder" xlink:href="@/assets/icons/icons.svg#clipboard-plus"/>
            <use v-else xlink:href="@/assets/icons/icons.svg#clipboard-data"/>
          </svg>
        </div>
        <div class="d-inline-block tree-node-name">
          <span @click="selectProject(node)" class="project-name" :class="selectedProject.id === node.id ? 'fw-bold' : ''" role="button">{{ node.name.replace(/- /gm, '').trim() }}</span>
        </div>
      </div>
    </div>
    <div v-if="showNodes || depth < 2">
      <ProjectTree v-for="node in node.nodes" :key="node.id" :node="node" :depth="depth + 1"></ProjectTree>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectTree',
  props: ['node', 'depth'],
  data () {
    return {
      showNodes: false
    }
  },
  computed: {
    indent () {
      if (this.node.nodes && this.depth) {
        return { paddingLeft: `${(this.depth - 1) * 22}px` }
      } else {
        return { paddingLeft: `${this.depth * 22}px` }
      }
    },
    selectedProject () {
      return this.$store.state.selectedProject
    }
  },
  methods: {
    async selectProject (node) {
      this.$store.commit('SET_LOADING_STATUS', true)

      await this.$store.dispatch('getProject', node.id).then(() => {
        this.$parent.projectModalElementId = node.id
      })

      this.$store.commit('SET_LOADING_STATUS', false)
      window.scrollTo(0, 0)
    },
    toggleNodes () {
      this.showNodes = !this.showNodes
    }
  }
}
</script>

<style lang="scss" scoped>
.project-tree {
  .cont-icon-project svg {
    margin-top: -3px;
  }
  .project-name:hover {
    font-weight: bold;
  }
}
</style>
