<template>
  <div class="container">
    <div class="row py-5 justify-content-center">
      <div class="col-12 col-lg-3 py-5 text-center">
        <div class="d-flex justify-content-center mb-5">
          <img v-if="isSingleTenant" src="@/assets/img/dirak_logo.webp" id="logo-tenant" class="rounded-3 my-auto" width="70" height="70">
          <div v-if="isSingleTenant" id="logo-divider" class="mx-4 my-auto"></div>
          <svg id="logo-focusmt" class="bi" width="80" height="80" fill="currentColor">
            <use xlink:href="@/assets/icons/icons.svg#nut-fill"/>
          </svg>
        </div>
        <div v-if="otpSent" class="mt-3">
          {{ $t("views.login.mailSent") }}
        </div>
        <div v-else>
          <div class="form-floating mb-3">
            <input v-model="email" @keyup.enter="getOTP()" type="email" class="form-control" id="inputEmail" :placeholder='$t("views.login.placeholderEmail")'>
            <label for="inputEmail">{{ $t("views.login.email") }}</label>
          </div>
          <div class="row">
            <div class="col-6 col-lg-12 offset-3 offset-lg-0">
              <button @click="getOTP()" class="btn btn-primary w-100">{{ $t("views.login.login") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  data () {
    return {
      email: '',
      otp: '',
      otpSent: false
    }
  },
  computed: {
    isSingleTenant () {
      return !!(this.$store.state.tenantMode === 'Single')
    }
  },
  methods: {
    async getOTP () {
      this.$store.commit('SET_LOADING_STATUS', true)

      if (this.email) {
        await this.$store.dispatch('getOTP', { email: this.email }).then((response) => {
          if (response) {
            this.otpSent = true
          }
        })
      }

      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async login () {
      this.$store.commit('SET_LOADING_STATUS', true)

      if (this.email && this.otp) {
        await this.$store.dispatch('login', { email: this.email, password: this.otp }).then((response) => {
          if (response !== undefined) {
            this.$i18n.locale = response
          }
        })
      }

      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  beforeMount () {
    const urlParams = new URLSearchParams(window.location.search)
    if (localStorage.getItem('focusLoginEmail') && urlParams.get('otp')) {
      this.email = localStorage.getItem('focusLoginEmail')
      this.otp = urlParams.get('otp')
      this.login()
    }
  }
}
</script>

<style scoped>
#logo-divider {
  border-left: 2px solid rgba(0,0,0,.4);
  height: 70px;
}
</style>
