<template>
  <div class="objective-modal">
    <div ref="objectiveIntroModal" class="modal fade" data-bs-backdrop="static" id="objectiveIntroModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">{{ $t("guide.intro.objective.modal.heading") }}</div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div>
              <div class="h3">Ihr erstes Ziel</div>
              <div class="mb-2">
                Die einfachste Möglichkeit, mit focusMT zu starten, ist das Erstellen Ihres ersten Ziels:
              </div>
              <ol>
                <li>Geben Sie zunächst grundlegende Infos zum Ziel an</li>
                <li>Vervollständigen Sie das Ziel mit dem Hinzufügen von Key Results</li>
                <li>Speichern Sie Ihr erstes Ziel</li>
                <li>Sehen Sie sich das Ziel im Strukturbaum an</li>
                <li>Werfen Sie einen Blick auf die Detailseite Ihres neuen Ziels</li>
              </ol>
              <div class="h5">Alternative zum Schnellstart</div>
              <div class="mb-2">
                Falls Sie nicht mit dem Erstellen Ihres ersten Ziels in focusMT einsteigen möchten, können Sie sich zunächst über das Menü mit den weiteren Modulen vertraut machen. Fügen Sie zum Beispiel in der Organisation weitere Abteilungen hinzu oder laden Sie Ihre Mitarbeiter zu focusMT ein.
              </div>
            </div>
          </div>
          <div class="modal-footer bg-light">
            <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal">{{ $t("guide.intro.objective.modal.btnStart") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import { ref } from 'vue'

export default {
  name: 'IntroObjective',
  created () {
    this.openModal()
  },
  setup () {
    const objectiveIntroModal = ref(null)
    const openModal = () => Modal.getInstance(objectiveIntroModal.value)?.show()
    return { objectiveIntroModal, openModal }
  }
}
</script>
