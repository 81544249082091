<template>
  <QuillEditor theme="snow" :toolbar="[{ 'header': [1, 2, false] }, 'bold', 'italic', 'underline',
                                       { 'color': [] }, 'link', { 'list': 'ordered' }, { 'list': 'bullet' }, 'clean']"
  />
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
  name: 'RTEditor',
  components: {
    QuillEditor
  }
}
</script>

<style>
.ql-toolbar {
  border-radius: 0.375rem 0.375rem 0rem 0rem;
}

.ql-container {
  height: 120px;
  background-color: #ffffff;
  border-radius: 0rem 0rem 0.375rem 0.375rem;
}

.quill-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
</style>
