<template>
  <div v-if="!isLoading && isTenantNotActivated" class="text-center border-bottom border-2 border-danger py-1 small">
    Bitte aktivieren Sie Ihre Organisation in den
    <router-link to="/settings/tenant" class=" text-decoration-underline">Einstellungen</router-link>,
    um focusMT dauerhaft zu nutzen.
  </div>
</template>

<script>
export default {
  name: 'TenantNotActivated',
  computed: {
    isLoading () {
      return this.$store.state.loadingStatus
    },
    isTenantNotActivated () {
      return this.$store.state.loginStatus && this.$store.state.user.role === 10387 && !this.$store.state.tenant.activated ? 1 : 0
    }
  }
}
</script>
