<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-3 sidebar-org bg-custom-blue pb-5">
        <div class="row mt-3">
          <div class="col-6">
            <button @click="openModal(false, 'add')" type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#employeeEditModal">+ {{ $t("general.add") }}</button>
          </div>
          <div class="col-6 my-auto">
            <div class="form-check float-end my-auto">
              <input @change="toggleActiveCheckbox()" v-model="activeOnly" class="form-check-input" type="checkbox" value="activeOnly" id="activeOnly">
              <label class="form-check-label" for="activeOnly">
                {{ $t("views.org.employee.onlyActive") }}
              </label>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div v-for="employee in employees" :key="employee.id" class="employee">
              <div class="py-2 border-bottom border-dark">
                <div class="cont-icon-employee d-inline-block me-1">
                  <div>
                    <svg v-if="employee.role === 10387" class="bi" width="20" height="20" fill="currentColor">
                      <use xlink:href="@/assets/icons/icons.svg#balloon-fill"/>
                    </svg>
                    <svg v-else-if="employee.role === 15972" class="bi" width="20" height="20" fill="#6c80bb">
                      <use xlink:href="@/assets/icons/icons.svg#balloon-fill"/>
                    </svg>
                    <svg v-else class="bi" width="20" height="20" fill="currentColor">
                      <use xlink:href="@/assets/icons/icons.svg#balloon"/>
                    </svg>
                  </div>
                </div>
                <div class="d-inline-block">
                  <span @click="selectEmployee(employee.id)" class="employee-name" :class="selectedEmployee.id === employee.id ? 'fw-bold' : ''"
                        role="button">{{ employee.lastName }}, {{ employee.firstName }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 px-5">
        <div v-if="Object.keys(selectedEmployee).length" class="row">
          <div class="col-12">
            <div class="row mt-3">
              <div class="col">
                <button @click="openModal(selectedEmployee.id, 'update')" type="button" class="btn btn-sm btn-outline-dark" data-bs-toggle="modal" data-bs-target="#employeeEditModal">&bull; {{ $t("general.update") }}</button>
              </div>
              <div class="col text-end">
                <DocsInfoLink color="dark" target="manageEmployees"></DocsInfoLink>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="h3">{{ selectedEmployee.lastName }}, {{ selectedEmployee.firstName }}</div>
                <div>
                  {{ selectedEmployee.email }} &bull;
                  <span v-if="selectedEmployee.role === 10387">{{ $t("views.org.employee.admin") }}</span>
                  <span v-else-if="selectedEmployee.role === 15972">{{ $t("views.org.employee.standard") }}</span>
                  <span v-else>{{ $t("views.org.employee.noAccount") }}</span>
                  <span v-if="!selectedEmployee.validFrom.includes('2000-01-01') && selectedEmployee.validTo.includes('2099-12-31')">
                    &bull;
                    {{ $t("views.org.employee.activeFrom") }}
                    {{ new Date(selectedEmployee.validFrom).toLocaleDateString({dateStyle: 'short'}) }}
                  </span>
                  <span v-else-if="selectedEmployee.validFrom.includes('2000-01-01') && !selectedEmployee.validTo.includes('2099-12-31')">
                    &bull;
                    {{ $t("views.org.employee.activeTo") }}
                    {{ new Date(selectedEmployee.validTo).toLocaleDateString({dateStyle: 'short'}) }}
                  </span>
                  <span v-else-if="!(selectedEmployee.validFrom.includes('2000-01-01') && selectedEmployee.validTo.includes('2099-12-31'))">
                    &bull;
                    {{ new Date(selectedEmployee.validFrom).toLocaleDateString({dateStyle: 'short'}) }}
                    -
                    {{ new Date(selectedEmployee.validTo).toLocaleDateString({dateStyle: 'short'}) }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <div class="card">
                  <div class="card-header fw-bold">{{ $t("views.org.employee.headingCard") }}</div>
                  <div class="card-body">
                    <ul class="nav nav-tabs border-bottom" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark active" id="orgunits-tab" data-bs-toggle="tab" data-bs-target="#orgunits" type="button" role="tab" aria-controls="orgunits" aria-selected="false">{{ $t("views.org.detail.orgUnits") }} <span class="badge bg-dark">{{ selectedEmployee.orgUnits.length }}</span></button>
                      </li>
                    </ul>
                    <div class="tab-content mt-2">
                      <div class="tab-pane fade show active" id="orgunits" role="tabpanel">
                        <div v-if="selectedEmployee.orgUnits.length">
                          <table class="table">
                            <thead>
                              <tr class="border-dark">
                                <th scope="col">{{ $t("views.org.edit.name") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="orgUnit in selectedEmployee.orgUnits" :key="'orgunit' + orgUnit.id">
                                <th @click="selectOrgUnit(orgUnit.id)" role="button" scope="row" class="fw-normal">{{ orgUnit.name }}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div v-else class="mt-3 h6">{{ $t("views.org.employee.noOrgUnits") }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center mt-5">
          <img src="@/assets/img/no_data.svg" class="img-fluid" width="250">
          <p class="h5 mt-3">{{ $t("views.org.employee.helpChooseEmployee") }}</p>
        </div>
      </div>
    </div>
    <div ref="employeeEditModal" class="modal fade" data-bs-backdrop="static" id="employeeEditModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">{{ $t("views.org.employee.employees") }} {{ vocabulary[mode] }}</div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div class="row mb-3">
              <div class="col">
                <label for="role" class="form-label">{{ $t("views.org.employee.role") }}<span class="text-danger">*</span></label>
                <select v-model="form.employee.role.content" id="role" class="form-select">
                  <option value="13512">{{ $t("views.org.employee.noAccount") }}</option>
                  <option value="15972">{{ $t("views.org.employee.standard") }}</option>
                  <option value="10387">{{ $t("views.org.employee.admin") }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label for="firstName" class="form-label">{{ $t("views.org.employee.firstName") }}<span class="text-danger">*</span></label>
                <input v-model="form.employee.firstName.content" id="firstName" :class="form.employee.firstName.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.employee.firstName.error }}</div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label for="lastName" class="form-label">{{ $t("views.org.employee.lastName") }}<span class="text-danger">*</span></label>
                <input v-model="form.employee.lastName.content" id="lastName" :class="form.employee.lastName.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.employee.lastName.error }}</div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label for="email" class="form-label">{{ $t("views.org.employee.email") }}<span class="text-danger">*</span></label>
                <input v-model="form.employee.email.content" type="email" id="email" :class="form.employee.email.error === '' ? 'form-control' : 'form-control is-invalid'"  :disabled="selectedEmployee.role !== 13512 && mode === 'update' ? true : false">
                <div class="invalid-feedback">{{ form.employee.email.error }}</div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <label for="employeeValidFrom" class="form-label">{{ $t("views.org.employee.activeFrom") }}</label>
                <input v-model="form.employee.validFrom.content" type="date" id="employeeValidFrom" :class="form.employee.validFrom.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.employee.validFrom.error }}</div>
              </div>
              <div class="col-6">
                <label for="employeeValidTo" class="form-label">{{ $t("views.org.employee.activeTo") }}</label>
                <input v-model="form.employee.validTo.content" type="date" id="employeeValidTo" :class="form.employee.validTo.error === '' ? 'form-control' : 'form-control is-invalid'">
                <div class="invalid-feedback">{{ form.employee.validTo.error }}</div>
              </div>
            </div>
          </div>
          <div class="modal-footer bg-light justify-content-between">
            <div>
              <button v-if="mode != 'add'" type="button" class="btn btn-sm btn-outline-danger" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#employeeDeleteModal">{{ $t("general.delete") }}</button>
            </div>
            <div>
              <button type="button" class="btn btn-sm btn-outline-secondary me-2" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
              <button @click="save()" type="button" class="btn btn-sm btn-primary">{{ $t("general.save") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" data-bs-backdrop="static" id="employeeDeleteModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">{{ $t("views.org.employee.headingDeleteEmployee") }}</div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div class="text-center my-3">
              <p class="fw-bold">{{ this.form.employee.lastName.content }}, {{ this.form.employee.firstName.content }}</p>
              <p>{{ $t("views.org.employee.confirmDeleteEmployee") }}</p>
            </div>
          </div>
          <div class="modal-footer bg-light">
            <button type="button" class="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
            <button @click="deleteEmployee()" type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal">{{ $t("general.delete") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as validate from '../../composables/validation'
import DocsInfoLink from '../../components/DocsInfoLink.vue'
import { Modal } from 'bootstrap'
import { ref } from 'vue'

export default {
  name: 'OrgManageEmployee',
  data () {
    return {
      mode: 'add',
      activeOnly: true,
      form: {
        employee: {
          id: {
            content: '',
            error: '',
            validate: []
          },
          role: {
            content: '',
            error: '',
            validate: []
          },
          firstName: {
            content: '',
            error: '',
            maxLength: 150,
            validate: ['length', 'empty']
          },
          lastName: {
            content: '',
            error: '',
            maxLength: 150,
            validate: ['length', 'empty']
          },
          email: {
            content: '',
            error: '',
            validate: ['email', 'empty']
          },
          validFrom: {
            content: '',
            error: '',
            validate: []
          },
          validTo: {
            content: '',
            error: '',
            validate: []
          }
        }
      },
      vocabulary: {
        add: 'hinzufügen',
        update: 'bearbeiten'
      }
    }
  },
  components: {
    DocsInfoLink
  },
  computed: {
    employees () {
      return this.$store.state.employees
    },
    selectedEmployee () {
      return this.$store.state.selectedEmployee
    }
  },
  methods: {
    openModal (id, mode) {
      if (id) {
        Object.keys(this.form.employee).forEach(field => {
          if (field in this.selectedEmployee) {
            this.form.employee[field].content = this.selectedEmployee[field]
            this.form.employee[field].error = ''
          }
        })
      }
      this.mode = mode
    },
    async selectEmployee (employeeId) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getEmployee', employeeId)
      this.$store.commit('SET_LOADING_STATUS', false)
      window.scrollTo(0, 0)
    },
    async selectOrgUnit (orgUnitId) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getOrgUnit', orgUnitId).then(() => {
        this.$router.push({ name: 'OrgManageOrgUnit' })
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async save () {
      this.$store.commit('SET_LOADING_STATUS', true)

      let valid = 0
      const employee = {}

      Object.keys(this.form.employee).forEach(field => {
        this.form.employee[field].validate.forEach(validation => {
          const validationResult = validate.useValidation(this.form.employee, field, validation)
          if (!validationResult.status) {
            this.form.employee[field].error = validationResult.content
            valid++
          }
        })
        employee[field] = this.form.employee[field].content
      })

      if (valid === 0) {
        const dispatchFunc = this.mode + 'Employee'
        await this.$store.dispatch(dispatchFunc, employee).then(() => {
          this.closeModal()
          Object.keys(this.form.employee).forEach(field => {
            this.form.employee[field].content = this.form.employee[field].error = ''
          })
        })
      }

      this.$store.commit('SET_LOADING_STATUS', false)

      await this.$store.dispatch('getEmployees', { mode: this.activeOnly ? 'default' : 'all' })
    },
    async deleteEmployee () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('deleteEmployee', this.form.employee.id.content).then(() => {
        this.$store.commit('SET_SELECTED_EMPLOYEE', {})
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async toggleActiveCheckbox () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getEmployees', { mode: this.activeOnly ? 'default' : 'all' })
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  async created () {
    this.$store.commit('SET_LOADING_STATUS', true)
    await this.$store.dispatch('getEmployees', { mode: this.activeOnly ? 'default' : 'all' })
    this.$store.commit('SET_LOADING_STATUS', false)
    if (Object.keys(this.selectedEmployee).length) {
      await this.$store.dispatch('getEmployee', this.selectedEmployee.id)
    }
  },
  watch: {
    mode (newMode) {
      if (newMode === 'add') {
        Object.keys(this.form.employee).forEach(field => {
          this.form.employee[field].content = this.form.employee[field].error = ''
        })
      }
    }
  },
  setup () {
    const employeeEditModal = ref(null)
    const closeModal = () => Modal.getInstance(employeeEditModal.value)?.hide()
    return { employeeEditModal, closeModal }
  }
}
</script>

<style lang="scss" scoped>
.employee {
  .cont-icon-employee span {
    font-size: 14px;
  }
  .employee-name:hover {
    font-weight: bold;
  }
}
</style>
